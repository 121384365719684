import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.iterator";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.to-string";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esSymbol;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isSelectOptionValue = isSelectOptionValue;
  exports.optionValueToString = optionValueToString;
  exports.stringToOptionValue = stringToOptionValue;
  exports.validateOptions = exports.selectOptionValuePropType = void 0;
  var _propTypes = _interopRequireDefault(_propTypes2);

  /** @module @airtable/blocks/ui: Select */

  /** */

  /** @internal */
  function isSelectOptionValue(value) {
    return value === null || value === undefined || typeof value === "string" || typeof value === "number" || typeof value === "boolean";
  }
  /**
   * A select option for {@link Select}, {@link TablePicker}, {@link ViewPicker}, {@link FieldPicker}, and their `Synced` counterparts.
   */

  // We cast the following prop type because we allow `null` as a valid option.
  var selectOptionValuePropType = _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.number, _propTypes.default.bool]);
  exports.selectOptionValuePropType = selectOptionValuePropType;
  var validateOptions = options => {
    if (options) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;
      try {
        for (var _iterator = options[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var option = _step.value;

          // These are hard errors because we can't guarantee that other values
          // will be JSON-encodable. And undefined gets coerced to the string
          // "undefined" which is confusing.
          if (typeof option.value === "object" && option.value !== null) {
            return {
              isValid: false,
              reason: "option value must be a string, number, boolean, null, or undefined. Got an object."
            };
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }
    return {
      isValid: true
    };
  };
  /** @internal */

  exports.validateOptions = validateOptions;
  function optionValueToString(value) {
    var valueJson = JSON.stringify(value === undefined ? {
      isUndefined: true
    } : {
      notUndefinedValue: value
    });
    return valueJson;
  }
  /** @internal */

  function stringToOptionValue(valueJson) {
    var parsed = JSON.parse(valueJson);
    var value = parsed.isUndefined ? null : parsed.notUndefinedValue;
    return value;
  }
  return exports;
}