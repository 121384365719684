import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _create_style_prop_types2Dew } from "../utils/create_style_prop_types";
import { dew as _height2Dew } from "./height";
import { dew as _max_height2Dew } from "./max_height";
import { dew as _max_width2Dew } from "./max_width";
import { dew as _min_height2Dew } from "./min_height";
import { dew as _min_width2Dew } from "./min_width";
import { dew as _width2Dew } from "./width";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dimensionsSetPropTypes = exports.dimensionsSet = void 0;
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _core = _core2;
  var _create_style_prop_types = _interopRequireDefault(_create_style_prop_types2Dew());
  var _height = _height2Dew();
  var _max_height = _max_height2Dew();
  var _max_width = _max_width2Dew();
  var _min_height = _min_height2Dew();
  var _min_width = _min_width2Dew();
  var _width = _width2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var dimensionsSet = (0, _core.system)(_objectSpread({}, _height.config, {}, _max_width.config, {}, _max_height.config, {}, _min_height.config, {}, _min_width.config, {}, _width.config));
  exports.dimensionsSet = dimensionsSet;
  var dimensionsSetPropTypes = (0, _create_style_prop_types.default)(dimensionsSet.propNames);
  exports.dimensionsSetPropTypes = dimensionsSetPropTypes;
  return exports;
}