import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _use_theme2Dew } from "./theme/use_theme";
import { dew as _get_style_props_for_responsive_prop2Dew } from "./system/utils/get_style_props_for_responsive_prop";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _system2Dew } from "./system/";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.useButtonSize = useButtonSize;
  exports.useSelectSize = useSelectSize;
  exports.useInputSize = useInputSize;
  exports.useSelectButtonsSize = useSelectButtonsSize;
  exports.useSwitchSize = useSwitchSize;
  exports.controlSizePropType = exports.ControlSize = void 0;
  var _core = _core2;
  var _private_utils = _private_utils2Dew();
  var _use_theme = _interopRequireDefault(_use_theme2Dew());
  var _get_style_props_for_responsive_prop = _interopRequireDefault(_get_style_props_for_responsive_prop2Dew());
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _system = _system2Dew();

  /** @module @airtable/blocks/ui/system: Control sizes */

  /** */
  var ControlSize = (0, _private_utils.createEnum)("small", "default", "large");
  /**
   * Size prop for the {@link Button}, {@link Input}, {@link Select}, {@link SelectButtons}, and {@link Switch} components.
   */

  exports.ControlSize = ControlSize;
  var controlSizePropType = (0, _private_utils.createResponsivePropTypeFromEnum)(ControlSize);
  /** @internal */

  exports.controlSizePropType = controlSizePropType;
  function useButtonSize(controlSizeProp) {
    var _useTheme = (0, _use_theme.default)(),
      buttonSizes = _useTheme.buttonSizes;
    var styleProps;
    if (typeof controlSizeProp === "string") {
      styleProps = buttonSizes[controlSizeProp];
    } else {
      styleProps = (0, _get_style_props_for_responsive_prop.default)(controlSizeProp, buttonSizes);
    }
    return (0, _use_styled_system.default)(styleProps);
  } // For selects we create a custom style parser that includes `backgroundPosition`.

  var selectSizeStyleParser = (0, _core.compose)(_system.allStylesParser, (0, _core.system)({
    backgroundPosition: true
  }));
  /** @internal */

  function useSelectSize(controlSizeProp) {
    var _useTheme2 = (0, _use_theme.default)(),
      selectSizes = _useTheme2.selectSizes;
    var styleProps;
    if (typeof controlSizeProp === "string") {
      styleProps = selectSizes[controlSizeProp];
    } else {
      styleProps = (0, _get_style_props_for_responsive_prop.default)(controlSizeProp, selectSizes);
    }
    return (0, _use_styled_system.default)(styleProps, selectSizeStyleParser);
  }
  /** @internal */

  function useInputSize(controlSizeProp) {
    var _useTheme3 = (0, _use_theme.default)(),
      inputSizes = _useTheme3.inputSizes;
    var styleProps;
    if (typeof controlSizeProp === "string") {
      styleProps = inputSizes[controlSizeProp];
    } else {
      styleProps = (0, _get_style_props_for_responsive_prop.default)(controlSizeProp, inputSizes);
    }
    return (0, _use_styled_system.default)(styleProps);
  }
  /** @internal */

  function useSelectButtonsSize(controlSizeProp) {
    var _useTheme4 = (0, _use_theme.default)(),
      selectButtonsSizes = _useTheme4.selectButtonsSizes;
    var styleProps;
    if (typeof controlSizeProp === "string") {
      styleProps = selectButtonsSizes[controlSizeProp];
    } else {
      styleProps = (0, _get_style_props_for_responsive_prop.default)(controlSizeProp, selectButtonsSizes);
    }
    return (0, _use_styled_system.default)(styleProps);
  }
  /** @internal */

  function useSwitchSize(controlSizeProp) {
    var _useTheme5 = (0, _use_theme.default)(),
      switchSizes = _useTheme5.switchSizes;
    var styleProps;
    if (typeof controlSizeProp === "string") {
      styleProps = switchSizes[controlSizeProp];
    } else {
      styleProps = (0, _get_style_props_for_responsive_prop.default)(controlSizeProp, switchSizes);
    }
    return (0, _use_styled_system.default)(styleProps);
  }
  return exports;
}