import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _colors2Dew } from "../colors";
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _baymax_utils2Dew } from "./baymax_utils";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _system2Dew } from "./system";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
import { dew as _box2Dew } from "./box";
import { dew as _default_theme2Dew } from "./theme/default_theme";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.progressBarStylePropTypes = void 0;
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var React = _interopRequireWildcard(_react);
  var _core = _core2;
  var _colors = _interopRequireDefault(_colors2Dew());
  var _private_utils = _private_utils2Dew();
  var _baymax_utils = _baymax_utils2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _system = _system2Dew();
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  var _box = _interopRequireDefault(_box2Dew());
  var _default_theme = _interopRequireDefault(_default_theme2Dew());
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var styleParser = (0, _core.compose)(_system.backgroundColor, _system.maxWidth, _system.minWidth, _system.width, _system.height, _system.flexItemSet, _system.positionSet, _system.margin, _system.display);
  var progressBarStylePropTypes = _objectSpread({}, _system.backgroundColorPropTypes, {}, _system.maxWidthPropTypes, {}, _system.minWidthPropTypes, {}, _system.widthPropTypes, {}, _system.heightPropTypes, {}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.marginPropTypes, {}, _system.displayPropTypes);
  /**
   * Props for the {@link ProgressBar} component. Also accepts:
   * * {@link ProgressBarStyleProps}
   *
   * @docsPath UI/components/ProgressBar
   * @noInheritDoc
   */

  exports.progressBarStylePropTypes = progressBarStylePropTypes;
  // TODO (jay): We can't use Box yet, because `aria-valuenow` is not yet available on `AriaProps`.
  var progressBarClassName = (0, _emotion.css)({
    position: "relative",
    borderRadius: _default_theme.default.radii.circle,
    overflow: "hidden"
  });
  /**
   * A progress bar.
   *
   * @example
   * ```js
   * import {ProgressBar} from '@airtable/blocks/ui';
   *
   * function MyComponent() {
   *     return (
   *         <ProgressBar
   *             progress={0.6}
   *             barColor='#ff9900'
   *         />
   *     );
   * }
   * ```
   * @docsPath UI/components/ProgressBar
   * @component
   */

  var ProgressBar = props => {
    var _props$barColor = props.barColor,
      barColor = _props$barColor === void 0 ? _default_theme.default.colors.blueBright : _props$barColor,
      progress = props.progress,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onClick = props.onClick,
      hasOnClick = props.hasOnClick,
      className = props.className,
      style = props.style,
      styleProps = (0, _objectWithoutProperties2.default)(props, ["barColor", "progress", "onMouseEnter", "onMouseLeave", "onClick", "hasOnClick", "className", "style"]);
    var clampedProgressValue = (0, _private_utils.clamp)(progress, 0, 1) * 100;
    var classNameForStyleProps = (0, _use_styled_system.default)(styleProps, styleParser);
    return React.createElement("div", {
      role: "progressbar",
      "aria-valuenow": clampedProgressValue // TODO (stephen): remove tooltip anchor props
      ,

      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onClick: onClick,
      className: (0, _emotion.cx)(progressBarClassName, classNameForStyleProps, className),
      style: style
    }, React.createElement(_box.default, {
      className: (0, _baymax_utils.baymax)("animate"),
      width: "".concat(clampedProgressValue, "%"),
      height: "100%",
      backgroundColor: barColor
    }));
  };
  ProgressBar.propTypes = _objectSpread({
    progress: _propTypes.default.number.isRequired,
    barColor: _propTypes.default.string,
    className: _propTypes.default.string,
    style: _propTypes.default.object
  }, _tooltip_anchor_props.tooltipAnchorPropTypes, {}, progressBarStylePropTypes);
  ProgressBar.defaultProps = {
    barColor: _colors.default.BLUE_BRIGHT,
    backgroundColor: _colors.default.GRAY_LIGHT_1,
    width: "100%",
    height: 4
  };
  var _default = ProgressBar;
  exports.default = _default;
  return exports;
}