import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = withHooks;
  var _extends2 = _interopRequireDefault(_extends);
  var React = _interopRequireWildcard(_react);
  var _error_utils = _error_utils2Dew();

  /** @module @airtable/blocks/ui: withHooks */

  /** */

  /**
   * @hidden
   * A higher-order component for working with React hooks in class-based components. It takes a React
   * component and wraps it, injecting values from hooks as additional props. `withHooks` uses
   * [`React.forwardRef`](https://reactjs.org/docs/forwarding-refs.html) to make sure that you can
   * use refs with your wrapped component in exactly the same way you would if you weren't using
   * `withHooks`.
   *
   * If you need an reference to the actual component instance, you can return a `ref` prop.
   *
   * @param Component The React component you want to inject hooks into.
   * @param getAdditionalPropsToInject A function that takes props and returns more props to be injected into the wrapped component.
   * @example
   * ```js
   * import React from 'react';
   * import {useRecords, withHooks} from '@airtable/blocks/ui';
   *
   * // RecordList takes a list of records and renders it
   * class RecordList extends React.Component {
   *      render() {
   *          const records = this.props.records.map(record => {
   *              return <li key={record.id}>{record.name}</li>
   *          });
   *
   *          return <ul>{records}</ul>;
   *      }
   * }
   *
   * // using withHooks, we wrap up RecordList. It takes a table prop, and injects a records
   * // prop from useRecords
   * const WrappedRecordList = withHooks(RecordList, ({table}) => {
   *      const records = useRecords(table);
   *
   *      const instanceRef = React.useRef();
   *      useEffect(() => {
   *          console.log('RecordList instance:', instanceRef.current);
   *      });
   *
   *      return {
   *          records: records,
   *          ref: instanceRef,
   *      };
   * });
   *
   * // when we use WrappedRecordList, we only need to pass in table:
   * <WrappedRecordList table={someTable} />
   * ```
   *
   * @example
   * ```js
   * import React from 'react';
   * import {Record, Table} from '@airtable/blocks/models';
   * import {withHooks, useRecords} from '@airtable/blocks/ui';
   * // with typescript, things are a little more complex: we need to provide some type annotations to
   * // indicate which props are injected:
   *
   * type RequiredProps = {
   *      table: Table,
   * };
   *
   * type InjectedProps = {
   *      records: Array<Record>,
   * };
   *
   * type RecordListProps = RequiredProps & InjectedProps;
   *
   * class RecordList extends React.Component<RecordListProps> {
   *      // implementation is the same as the example above
   * }
   *
   * // you need to annotate the call to withHooks. This takes three type args:
   * //   - The injected props
   * //   - The full resulting props passed to the element
   * //   - the instance type (what you get out of a ref) of the resulting component
   * const WrappedRecordList = withHooks<InjectedProps, RecordListProps, RecordList>(
   *      RecordList,
   *      ({table}) => {
   *          const records = useRecords(table);
   *          return {
   *              records
   *          };
   *      },
   * );
   *
   * // when using a ref to the component, you can't refer to it as WrappedRecordList like a normal
   * // class component. Instead, you need to wrap it in React.ElementRef:
   * const ref: React.ElementRef<typeof WrappedRecordList> = getTheRefSomehow();
   * ```
   */
  function withHooks(Component, getAdditionalPropsToInject) {
    if (!getAdditionalPropsToInject) {
      throw (0, _error_utils.spawnError)("withHooks: getAdditionalPropsToInject is required");
    }
    return React.forwardRef((props, forwardedRef) => {
      var propsToInject = getAdditionalPropsToInject(props);
      if (propsToInject === null || typeof propsToInject !== "object") {
        throw (0, _error_utils.spawnError)("withHooks: getAdditionalPropsToInject must return an object");
      } // getAdditionalPropsToInject may return a ref. We want to make sure this ref gets populated
      // with the component instance, so we need to pass it down to the component we're wrapping.

      var injectedRef = propsToInject.ref; // we might have a second ref passed in from the parent though - a consumer trying to use
      // the wrapped component who also wants a reference to the component instance. in order to
      // support both these use-cases, we need to set both refs. to do this, we create a function
      // ref that sets both the injectedRef and forwardedRef. Each ref can be either a function
      // ref or object ref, so we handle both these cases.

      var mergedRef = React.useMemo(() => {
        if (!injectedRef && !forwardedRef) {
          return undefined;
        }
        return instance => {
          if (injectedRef && typeof injectedRef === "object") {
            injectedRef.current = instance;
          } else if (typeof injectedRef === "function") {
            injectedRef(instance);
          }
          if (forwardedRef && typeof forwardedRef === "object") {
            forwardedRef.current = instance;
          } else if (typeof forwardedRef === "function") {
            forwardedRef(instance);
          }
        };
      }, [injectedRef, forwardedRef]); // It's important that the ref prop comes at the end, so it overrides
      // any 'ref's in props or propsToInject.

      return React.createElement(Component, (0, _extends2.default)({}, props, propsToInject, {
        ref: mergedRef
      }));
    });
  }
  return exports;
}