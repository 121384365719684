import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _create_style_prop_types2Dew } from "../utils/create_style_prop_types";
import { dew as _ensure_numbers_are_within_scale2Dew } from "../utils/ensure_numbers_are_within_scale";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.bottomPropTypes = exports.bottom = exports.config = void 0;
  var _core = _core2;
  var _create_style_prop_types = _interopRequireDefault(_create_style_prop_types2Dew());
  var _ensure_numbers_are_within_scale = _interopRequireDefault(_ensure_numbers_are_within_scale2Dew());

  /** @module @airtable/blocks/ui/system: Position */

  /** */
  var config = {
    bottom: {
      property: "bottom",
      scale: "space",
      transform: (0, _ensure_numbers_are_within_scale.default)({
        propertyName: "bottom",
        shouldAllowNegativeNumbers: true
      })
    }
  };
  exports.config = config;
  var bottom = (0, _core.system)(config);
  exports.bottom = bottom;
  var bottomPropTypes = (0, _create_style_prop_types.default)(bottom.propNames);
  exports.bottomPropTypes = bottomPropTypes;
  return exports;
}