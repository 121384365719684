import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _create_style_prop_types2Dew } from "../utils/create_style_prop_types";
import { dew as _font_family2Dew } from "./font_family";
import { dew as _font_size2Dew } from "./font_size";
import { dew as _font_style2Dew } from "./font_style";
import { dew as _font_weight2Dew } from "./font_weight";
import { dew as _letter_spacing2Dew } from "./letter_spacing";
import { dew as _line_height2Dew } from "./line_height";
import { dew as _text_align2Dew } from "./text_align";
import { dew as _text_color2Dew } from "./text_color";
import { dew as _text_decoration2Dew } from "./text_decoration";
import { dew as _text_transform2Dew } from "./text_transform";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.typographySetPropTypes = exports.typographySet = void 0;
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _core = _core2;
  var _create_style_prop_types = _interopRequireDefault(_create_style_prop_types2Dew());
  var _font_family = _font_family2Dew();
  var _font_size = _font_size2Dew();
  var _font_style = _font_style2Dew();
  var _font_weight = _font_weight2Dew();
  var _letter_spacing = _letter_spacing2Dew();
  var _line_height = _line_height2Dew();
  var _text_align = _text_align2Dew();
  var _text_color = _text_color2Dew();
  var _text_decoration = _text_decoration2Dew();
  var _text_transform = _text_transform2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var typographySet = (0, _core.system)(_objectSpread({}, _font_family.config, {}, _font_size.config, {}, _font_style.config, {}, _font_weight.config, {}, _letter_spacing.config, {}, _line_height.config, {}, _text_align.config, {}, _text_color.config, {}, _text_decoration.config, {}, _text_transform.config));
  exports.typographySet = typographySet;
  var typographySetPropTypes = (0, _create_style_prop_types.default)(typographySet.propNames);
  exports.typographySetPropTypes = typographySetPropTypes;
  return exports;
}