import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _create_style_prop_types2Dew } from "../utils/create_style_prop_types";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.borderPropTypes = exports.border = exports.config = void 0;
  var _core = _core2;
  var _create_style_prop_types = _interopRequireDefault(_create_style_prop_types2Dew());

  /** @module @airtable/blocks/ui/system: Appearance */

  /** */
  var config = {
    border: {
      property: "border",
      scale: "borders"
    },
    borderWidth: {
      property: "borderWidth",
      scale: "borderWidths"
    },
    borderStyle: {
      property: "borderStyle",
      scale: "borderStyles"
    },
    borderColor: {
      property: "borderColor",
      scale: "colors"
    },
    borderTop: {
      property: "borderTop",
      scale: "borders"
    },
    borderRight: {
      property: "borderRight",
      scale: "borders"
    },
    borderBottom: {
      property: "borderBottom",
      scale: "borders"
    },
    borderLeft: {
      property: "borderLeft",
      scale: "borders"
    },
    borderX: {
      properties: ["borderLeft", "borderRight"],
      scale: "borders"
    },
    borderY: {
      properties: ["borderTop", "borderBottom"],
      scale: "borders"
    }
  };
  exports.config = config;
  var border = (0, _core.system)(config);
  exports.border = border;
  var borderPropTypes = (0, _create_style_prop_types.default)(border.propNames);
  exports.borderPropTypes = borderPropTypes;
  return exports;
}