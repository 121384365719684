import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = useViewport;
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _sdk_context = _sdk_context2Dew();

  /** @module @airtable/blocks/ui: useViewport */

  /** */

  /**
   * Returns the current {@link Viewport} object and updates whenever the viewport size, constraints,
   * or fullscreen status changes.
   *
   * @example
   * ```js
   * import {useViewport} from '@airtable/blocks/ui';
   *
   * function ViewportSize() {
   *      const viewport = useViewport();
   *
   *      const toggleFullscreen = () => {
   *          if (viewport.isFullscreen) {
   *              viewport.exitFullscreen();
   *          } else {
   *              viewport.enterFullscreenIfPossible();
   *          }
   *      };
   *
   *      return (
   *          <Fragment>
   *              <button onClick={toggleFullscreen}>Toggle fullscreen</button>
   *
   *              viewport size: {viewport.size.width}x{viewport.size.height}
   *          </Fragment>
   *      );
   * }
   * ```
   * @docsPath UI/hooks/useViewport
   * @hook
   */
  function useViewport() {
    var viewport = (0, _sdk_context.useSdk)().viewport;
    (0, _use_watchable.default)(viewport, ["isFullscreen", "size", "minSize", "maxFullscreenSize"]);
    return viewport;
  }
  return exports;
}