import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _use_theme2Dew } from "./theme/use_theme";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _use_form_field2Dew } from "./use_form_field";
import { dew as _system2Dew } from "./system";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
import { dew as _control_sizes2Dew } from "./control_sizes";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.sharedInputPropTypes = exports.SupportedInputType = exports.inputStylePropTypes = exports.ValidInputType = void 0;
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var React = _interopRequireWildcard(_react);
  var _core = _core2;
  var _private_utils = _private_utils2Dew();
  var _use_theme = _interopRequireDefault(_use_theme2Dew());
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _use_form_field = _interopRequireDefault(_use_form_field2Dew());
  var _system = _system2Dew();
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  var _control_sizes = _control_sizes2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var InputVariant = (0, _private_utils.createEnum)("default");
  /** @internal */

  function useInputVariant() {
    var variant = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InputVariant.default;
    var _useTheme = (0, _use_theme.default)(),
      inputVariants = _useTheme.inputVariants;
    return inputVariants[variant];
  }
  var ValidInputType = (0, _private_utils.createEnum)("date", "datetime-local", "email", "month", "number", "password", "search", "tel", "text", "time", "url", "week");
  /** */

  exports.ValidInputType = ValidInputType;
  var styleParser = (0, _core.compose)(_system.maxWidth, _system.minWidth, _system.width, _system.flexItemSet, _system.positionSet, _system.margin);
  var inputStylePropTypes = _objectSpread({}, _system.maxWidthPropTypes, {}, _system.minWidthPropTypes, {}, _system.widthPropTypes, {}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.marginPropTypes);
  /**
   * Props shared between the {@link Input} and {@link InputSynced} components.
   *
   * @noInheritDoc
   */

  exports.inputStylePropTypes = inputStylePropTypes;
  var SupportedInputType = (0, _private_utils.createEnum)("date", "datetime-local", "email", "month", "number", "password", "search", "tel", "text", "time", "url", "week");
  /**
   * Supported types for the {@link Input} component. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#%3Cinput%3E_types) for more information.
   */

  exports.SupportedInputType = SupportedInputType;

  // Shared with `Input` and `InputSynced`.
  var sharedInputPropTypes = _objectSpread({
    size: _control_sizes.controlSizePropType,
    type: (0, _private_utils.createPropTypeFromEnum)(SupportedInputType),
    placeholder: _propTypes.default.string,
    disabled: _propTypes.default.bool,
    required: _propTypes.default.bool,
    spellCheck: _propTypes.default.bool,
    tabIndex: _propTypes.default.oneOfType([_propTypes.default.number]),
    autoFocus: _propTypes.default.bool,
    max: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string]),
    maxLength: _propTypes.default.number,
    min: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string]),
    minLength: _propTypes.default.number,
    step: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string]),
    pattern: _propTypes.default.string,
    readOnly: _propTypes.default.bool,
    autoComplete: _propTypes.default.string,
    onChange: _propTypes.default.func,
    onBlur: _propTypes.default.func,
    onFocus: _propTypes.default.func,
    style: _propTypes.default.object,
    className: _propTypes.default.string,
    "aria-labelledby": _propTypes.default.string,
    "aria-describedby": _propTypes.default.string
  }, inputStylePropTypes, {}, _tooltip_anchor_props.tooltipAnchorPropTypes);
  /**
   * Props for the {@link Input} component. Also accepts:
   * * {@link InputStyleProps}
   *
   * @docsPath UI/components/Input
   */

  exports.sharedInputPropTypes = sharedInputPropTypes;

  /**
   * An input component. A wrapper around `<input>` that fits in with Airtable's user interface.
   *
   * [[ Story id="input--example" title="Input example" ]]
   *
   * @docsPath UI/components/Input
   * @component
   */
  var Input = (props, ref) => {
    var _props$size = props.size,
      size = _props$size === void 0 ? _control_sizes.ControlSize.default : _props$size,
      _props$type = props.type,
      type = _props$type === void 0 ? SupportedInputType.text : _props$type,
      value = props.value,
      placeholder = props.placeholder,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onClick = props.onClick,
      hasOnClick = props.hasOnClick,
      onChange = props.onChange,
      onBlur = props.onBlur,
      onFocus = props.onFocus,
      style = props.style,
      className = props.className,
      disabled = props.disabled,
      required = props.required,
      spellCheck = props.spellCheck,
      tabIndex = props.tabIndex,
      id = props.id,
      name = props.name,
      autoFocus = props.autoFocus,
      max = props.max,
      maxLength = props.maxLength,
      min = props.min,
      minLength = props.minLength,
      step = props.step,
      pattern = props.pattern,
      readOnly = props.readOnly,
      autoComplete = props.autoComplete,
      ariaLabelledBy = props["aria-labelledby"],
      ariaDescribedByProp = props["aria-describedby"],
      styleProps = (0, _objectWithoutProperties2.default)(props, ["size", "type", "value", "placeholder", "onMouseEnter", "onMouseLeave", "onClick", "hasOnClick", "onChange", "onBlur", "onFocus", "style", "className", "disabled", "required", "spellCheck", "tabIndex", "id", "name", "autoFocus", "max", "maxLength", "min", "minLength", "step", "pattern", "readOnly", "autoComplete", "aria-labelledby", "aria-describedby"]);
    var formFieldContextValue = (0, _use_form_field.default)();
    var controlId = formFieldContextValue ? formFieldContextValue.controlId : undefined;
    var descriptionId = formFieldContextValue ? formFieldContextValue.descriptionId : undefined;
    var ariaDescribedBy = [ariaDescribedByProp, descriptionId].filter(Boolean).join(" ") || undefined; // There is only a single default variant.

    var classNameForInputVariant = useInputVariant();
    var classNameForInputSize = (0, _control_sizes.useInputSize)(size);
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({
      width: "100%"
    }, styleProps), styleParser);
    return React.createElement("input", {
      ref: ref,
      value: value,
      type: type,
      placeholder: placeholder,
      disabled: disabled,
      required: required,
      spellCheck: spellCheck,
      tabIndex: tabIndex,
      id: id || controlId,
      name: name,
      autoFocus: autoFocus,
      max: max,
      maxLength: maxLength,
      min: min,
      minLength: minLength,
      step: step,
      pattern: pattern,
      readOnly: readOnly,
      autoComplete: autoComplete,
      onChange: onChange,
      onBlur: onBlur,
      onFocus: onFocus // TODO (stephen): remove tooltip anchor props
      ,

      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onClick: onClick,
      style: style,
      className: (0, _emotion.cx)(classNameForInputSize, classNameForInputVariant, classNameForStyleProps, className),
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy
    });
  };
  var ForwardedRefInput = React.forwardRef(Input);
  ForwardedRefInput.propTypes = _objectSpread({
    value: _propTypes.default.string.isRequired
  }, sharedInputPropTypes);
  ForwardedRefInput.displayName = "Input";
  var _default = ForwardedRefInput;
  exports.default = _default;
  return exports;
}