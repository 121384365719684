import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import { dew as _airtable_interface2Dew } from "../types/airtable_interface";
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _airtable_interface = _airtable_interface2Dew();
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _sdk_context = _sdk_context2Dew();

  /** @hidden */
  var globalConfigSyncedComponentHelpers = {
    globalConfigKeyPropType: _propTypes.default.oneOfType([_propTypes.default.string.isRequired, _propTypes.default.arrayOf(_propTypes.default.string.isRequired).isRequired]).isRequired,
    useDefaultWatchesForSyncedComponent(globalConfigKey) {
      var sdk = (0, _sdk_context.useSdk)();
      var globalConfig = sdk.globalConfig,
        session = sdk.session;
      var runContext = sdk.getBlockRunContext();
      (0, _use_watchable.default)(globalConfig, [globalConfig.__getTopLevelKey(globalConfigKey)]);
      (0, _use_watchable.default)(session, ["permissionLevel"]);
      var viewIfInViewContext = runContext.type === _airtable_interface.BlockRunContextType.VIEW ? sdk.base.getTableById(runContext.tableId).getViewById(runContext.viewId) : null; // We can't conditionally watch, but if it's not a viewContext the model will be null.

      (0, _use_watchable.default)(viewIfInViewContext, ["isLockedView"]);
    }
  };
  var _default = globalConfigSyncedComponentHelpers;
  exports.default = _default;
  return exports;
}