import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import { dew as _table2Dew } from "../models/table";
import { dew as _select2Dew } from "./select";
import { dew as _model_picker_select2Dew } from "./model_picker_select";
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.sharedTablePickerPropTypes = void 0;
  var _extends2 = _interopRequireDefault(_extends);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var React = _interopRequireWildcard(_react);
  var _table = _interopRequireDefault(_table2Dew());
  var _select = _select2Dew();
  var _model_picker_select = _interopRequireDefault(_model_picker_select2Dew());
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _sdk_context = _sdk_context2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }

  // Shared with `TablePicker` and `TablePickerSynced`.
  var sharedTablePickerPropTypes = _objectSpread({
    shouldAllowPickingNone: _propTypes.default.bool,
    placeholder: _propTypes.default.string,
    onChange: _propTypes.default.func
  }, _select.sharedSelectBasePropTypes);
  /**
   * Props for the {@link TablePicker} component. Also accepts:
   * * {@link SelectStyleProps}
   *
   * @docsPath UI/components/TablePicker
   */

  exports.sharedTablePickerPropTypes = sharedTablePickerPropTypes;

  /**
   * Dropdown menu component for selecting tables.
   *
   * [[ Story id="modelpickers--tablepicker-example" title="Table picker example" ]]
   *
   * @docsPath UI/components/TablePicker
   * @component
   */
  var TablePicker = (props, ref) => {
    var table = props.table,
      shouldAllowPickingNone = props.shouldAllowPickingNone,
      placeholder = props.placeholder,
      onChange = props.onChange,
      restOfProps = (0, _objectWithoutProperties2.default)(props, ["table", "shouldAllowPickingNone", "placeholder", "onChange"]);
    var selectedTable = table && !table.isDeleted ? table : null;
    var sdk = (0, _sdk_context.useSdk)();
    (0, _use_watchable.default)(sdk.base, ["tables"]);
    function _onChange(tableId) {
      if (onChange) {
        var newTable = tableId ? sdk.base.getTableByIdIfExists(tableId) : null;
        onChange(newTable);
      }
    }
    var placeholderToUse;
    if (placeholder === undefined) {
      // Let's set a good default value for the placeholder, depending
      // on the shouldAllowPickingNone flag.
      placeholderToUse = shouldAllowPickingNone ? "None" : "Pick a table...";
    } else {
      placeholderToUse = placeholder;
    }
    return React.createElement(_model_picker_select.default, (0, _extends2.default)({}, restOfProps, {
      ref: ref,
      models: sdk.base.tables,
      selectedModelId: selectedTable ? selectedTable.id : null,
      modelKeysToWatch: ["name"],
      shouldAllowPickingNone: shouldAllowPickingNone,
      placeholder: placeholderToUse,
      onChange: _onChange
    }));
  };
  var ForwardedRefTablePicker = React.forwardRef(TablePicker);
  ForwardedRefTablePicker.displayName = "TablePicker";
  ForwardedRefTablePicker.propTypes = _objectSpread({
    table: _propTypes.default.instanceOf(_table.default)
  }, sharedTablePickerPropTypes);
  var _default = ForwardedRefTablePicker;
  exports.default = _default;
  return exports;
}