import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import { dew as _tokens2Dew } from "./tokens";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _emotion = _emotion2;
  var _tokens = _tokens2Dew();
  var baseInputStyles = (0, _emotion.css)({
    borderRadius: _tokens.radii.default,
    boxSizing: "border-box",
    fontFamily: _tokens.fontFamilies.default,
    fontWeight: 400,
    appearance: "none",
    outline: "none",
    border: "none",
    "&:not(:disabled)": {
      "&:hover": {
        opacity: _tokens.opacities.quiet
      },
      "&:active": {
        opacity: 1
      },
      "&:focus": {
        boxShadow: "inset 0 0 0 2px ".concat(_tokens.colors.darken3)
      }
    },
    "&:disabled": {
      opacity: _tokens.opacities.quieter
    }
  });
  var inputVariants = {
    default: (0, _emotion.cx)(baseInputStyles, (0, _emotion.css)({
      color: _tokens.colors.dark,
      backgroundColor: _tokens.colors.lightGray2
    }))
  };
  var _default = inputVariants;
  exports.default = _default;
  return exports;
}