import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import { dew as _tokens2Dew } from "./tokens";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _emotion = _emotion2;
  var _tokens = _tokens2Dew();
  var baseStyles = (0, _emotion.css)({
    fontFamily: _tokens.fontFamilies.default,
    borderRadius: _tokens.radii.default,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 500,
    userSelect: "none",
    outline: "none",
    appearance: "none",
    border: "none",
    "&:not(:disabled)": {
      cursor: "pointer",
      "&:hover": {
        opacity: _tokens.opacities.quiet
      },
      "&:active": {
        opacity: 1
      },
      "&:focus": {
        boxShadow: "inset 0 0 0 2px ".concat(_tokens.colors.darken3)
      }
    },
    "&:disabled": {
      cursor: "default",
      opacity: _tokens.opacities.quieter
    }
  });
  var buttonVariants = {
    default: (0, _emotion.cx)(baseStyles, (0, _emotion.css)({
      color: _tokens.colors.dark,
      backgroundColor: _tokens.colors.lightGray2
    })),
    primary: (0, _emotion.cx)(baseStyles, (0, _emotion.css)({
      color: _tokens.colors.white,
      backgroundColor: _tokens.colors.blueBright
    })),
    secondary: (0, _emotion.cx)(baseStyles, (0, _emotion.css)({
      color: _tokens.colors.dark,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: _tokens.colors.lightGray2
      }
    })),
    danger: (0, _emotion.cx)(baseStyles, (0, _emotion.css)({
      color: _tokens.colors.white,
      backgroundColor: _tokens.colors.redBright
    }))
  };
  var _default = buttonVariants;
  exports.default = _default;
  return exports;
}