import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.switchSizes = exports.selectButtonsSizes = exports.inputSizes = exports.selectSizes = exports.buttonSizes = void 0;
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var sharedControlSizes = {
    small: {
      fontSize: 2,
      // 13px
      height: "28px",
      lineHeight: "19px"
    },
    default: {
      fontSize: 2,
      // 13px
      height: "32px",
      lineHeight: "21px"
    },
    large: {
      fontSize: 3,
      // 15px
      height: "36px",
      lineHeight: "21px"
    }
  };
  var buttonSizes = {
    small: _objectSpread({}, sharedControlSizes.small, {
      paddingX: "10px"
    }),
    default: _objectSpread({}, sharedControlSizes.default, {
      paddingX: "12px"
    }),
    large: _objectSpread({}, sharedControlSizes.large, {
      paddingX: "14px"
    })
  };
  exports.buttonSizes = buttonSizes;
  var selectSizes = {
    small: _objectSpread({}, sharedControlSizes.small, {
      paddingLeft: "8px",
      paddingRight: "24px",
      backgroundPosition: "calc(100% - 8px)"
    }),
    default: _objectSpread({}, sharedControlSizes.default, {
      paddingLeft: "10px",
      paddingRight: "26px",
      backgroundPosition: "calc(100% - 10px)"
    }),
    large: _objectSpread({}, sharedControlSizes.large, {
      paddingLeft: "12px",
      paddingRight: "28px",
      backgroundPosition: "calc(100% - 12px)"
    })
  };
  exports.selectSizes = selectSizes;
  var inputSizes = {
    small: _objectSpread({}, sharedControlSizes.small, {
      paddingX: "8px"
    }),
    default: _objectSpread({}, sharedControlSizes.default, {
      paddingX: "10px"
    }),
    large: _objectSpread({}, sharedControlSizes.large, {
      paddingX: "12px"
    })
  };
  exports.inputSizes = inputSizes;
  var selectButtonsSizes = {
    small: _objectSpread({}, sharedControlSizes.small, {
      padding: 1
    }),
    default: _objectSpread({}, sharedControlSizes.default, {
      padding: 1
    }),
    large: _objectSpread({}, sharedControlSizes.large, {
      padding: 1
    })
  };
  exports.selectButtonsSizes = selectButtonsSizes;
  var switchSizes = {
    small: _objectSpread({}, sharedControlSizes.small, {
      paddingX: "8px"
    }),
    default: _objectSpread({}, sharedControlSizes.default, {
      paddingX: "10px"
    }),
    large: _objectSpread({}, sharedControlSizes.large, {
      paddingX: "12px"
    })
  };
  exports.switchSizes = switchSizes;
  return exports;
}