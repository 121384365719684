var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var textStyles = {
    default: {
      small: {
        fontSize: 1,
        lineHeight: "14px",
        fontWeight: 400,
        textColor: "default",
        fontFamily: "default",
        marginY: 0
      },
      default: {
        fontSize: 2,
        lineHeight: "16px",
        fontWeight: 400,
        textColor: "default",
        fontFamily: "default",
        marginY: 0
      },
      large: {
        fontSize: 3,
        lineHeight: "20px",
        fontWeight: 400,
        textColor: "default",
        fontFamily: "default",
        marginY: 0
      },
      xlarge: {
        fontSize: 4,
        lineHeight: "24px",
        fontWeight: 400,
        textColor: "default",
        fontFamily: "default",
        marginY: 0
      }
    },
    paragraph: {
      small: {
        fontSize: 1,
        lineHeight: "16px",
        fontWeight: 400,
        textColor: "default",
        fontFamily: "default",
        marginTop: 0,
        marginBottom: "1em"
      },
      default: {
        fontSize: 2,
        lineHeight: "20px",
        fontWeight: 400,
        textColor: "default",
        fontFamily: "default",
        marginTop: 0,
        marginBottom: "1em"
      },
      large: {
        fontSize: 3,
        lineHeight: "22px",
        fontWeight: 400,
        textColor: "default",
        fontFamily: "default",
        marginTop: 0,
        marginBottom: "1em"
      },
      xlarge: {
        fontSize: 4,
        lineHeight: "26px",
        fontWeight: 400,
        textColor: "default",
        fontFamily: "default",
        marginTop: 0,
        marginBottom: "1em"
      }
    }
  };
  var _default = textStyles;
  exports.default = _default;
  return exports;
}