import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import { dew as _tokensDew } from "./tokens";
import { dew as _control_sizesDew } from "./control_sizes";
import { dew as _text_styles2Dew } from "./text_styles";
import { dew as _heading_styles2Dew } from "./heading_styles";
import { dew as _button_variants2Dew } from "./button_variants";
import { dew as _link_variants2Dew } from "./link_variants";
import { dew as _input_variants2Dew } from "./input_variants";
import { dew as _switch_variants2Dew } from "./switch_variants";
import { dew as _select_variants2Dew } from "./select_variants";
import { dew as _select_buttons_variants2Dew } from "./select_buttons_variants";
import { dew as _text_button_variants2Dew } from "./text_button_variants";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var tokens = _interopRequireWildcard(_tokensDew());
  var controlSizes = _interopRequireWildcard(_control_sizesDew());
  var _text_styles = _interopRequireDefault(_text_styles2Dew());
  var _heading_styles = _interopRequireDefault(_heading_styles2Dew());
  var _button_variants = _interopRequireDefault(_button_variants2Dew());
  var _link_variants = _interopRequireDefault(_link_variants2Dew());
  var _input_variants = _interopRequireDefault(_input_variants2Dew());
  var _switch_variants = _interopRequireDefault(_switch_variants2Dew());
  var _select_variants = _interopRequireDefault(_select_variants2Dew());
  var _select_buttons_variants = _interopRequireDefault(_select_buttons_variants2Dew());
  var _text_button_variants = _interopRequireDefault(_text_button_variants2Dew());
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var theme = _objectSpread({}, tokens, {}, controlSizes, {
    textStyles: _text_styles.default,
    headingStyles: _heading_styles.default,
    // We create CSS class names for variants when the UI kit gets loaded.
    // This means `textButtonVariants.default` is just a CSS class name.
    // This has the benefit over exporting a style object
    // of not having to create a class name manually in the render function.
    buttonVariants: _button_variants.default,
    linkVariants: _link_variants.default,
    inputVariants: _input_variants.default,
    selectVariants: _select_variants.default,
    selectButtonsVariants: _select_buttons_variants.default,
    switchVariants: _switch_variants.default,
    textButtonVariants: _text_button_variants.default
  });
  var _default = theme;
  exports.default = _default;
  return exports;
}