import * as _esArray2 from "core-js/modules/es.array.index-of";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  _esArray;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isCommandModifierKeyEvent = isCommandModifierKeyEvent;
  exports.KeyCodes = void 0;

  /**
   * @internal
   */
  var KeyCodes = Object.freeze({
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    ALT: 18,
    ESCAPE: 27,
    SPACE: 32,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    DELETE: 46,
    A: 65,
    B: 66,
    C: 67,
    D: 68,
    E: 69,
    F: 70,
    G: 71,
    H: 72,
    I: 73,
    J: 74,
    K: 75,
    L: 76,
    M: 77,
    N: 78,
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    V: 86,
    W: 87,
    X: 88,
    Y: 89,
    Z: 90,
    F2: 113,
    F3: 114,
    COMMA: 188,
    PERIOD: 190,
    FORWARD_SLASH: 191,
    BACKTICK: 192,
    BACK_SLASH: 220
  });
  /**
   * @internal
   * OS-aware check for command/ctrl key.
   */

  exports.KeyCodes = KeyCodes;
  function isCommandModifierKeyEvent(e) {
    var isMac = window.navigator.platform.toLowerCase().indexOf("mac") !== -1;
    if (isMac) {
      return e.metaKey;
    } else {
      return e.ctrlKey;
    }
  }
  return exports;
}