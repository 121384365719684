import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _create_responsive_prop_type2Dew } from "./system/utils/create_responsive_prop_type";
import { dew as _system2Dew } from "./system";
import { dew as _use_theme2Dew } from "./theme/use_theme";
import { dew as _control_sizes2Dew } from "./control_sizes";
import { dew as _aria_props2Dew } from "./types/aria_props";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
import { dew as _icon_config2Dew } from "./icon_config";
import { dew as _icon2Dew } from "./icon";
import { dew as _css_helpers2Dew } from "./css_helpers";
import { dew as _box2Dew } from "./box";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.buttonStylePropTypes = void 0;
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var React = _interopRequireWildcard(_react);
  var _core = _core2;
  var _private_utils = _private_utils2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _create_responsive_prop_type = _interopRequireDefault(_create_responsive_prop_type2Dew());
  var _system = _system2Dew();
  var _use_theme = _interopRequireDefault(_use_theme2Dew());
  var _control_sizes = _control_sizes2Dew();
  var _aria_props = _aria_props2Dew();
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  var _icon_config = _icon_config2Dew();
  var _icon = _interopRequireDefault(_icon2Dew());
  var _css_helpers = _interopRequireDefault(_css_helpers2Dew());
  var _box = _interopRequireDefault(_box2Dew());
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var styleParser = (0, _core.compose)(_system.display, _system.maxWidth, _system.minWidth, _system.width, _system.flexItemSet, _system.positionSet, _system.margin);
  var buttonStylePropTypes = _objectSpread({
    display: (0, _create_responsive_prop_type.default)(_propTypes.default.oneOf(["inline-flex", "flex", "none"]))
  }, _system.maxWidthPropTypes, {}, _system.minWidthPropTypes, {}, _system.widthPropTypes, {}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.marginPropTypes);
  /**
   * Variants for the {@link Button} component:
   *
   * • **default**
   *
   * Gray button for toolbars and other generic actions.
   *
   * • **primary**
   *
   * Blue button used for primary actions and CTAs. There should only be one primary button present at a time. Often used in {@link Dialog} and bottom bars.
   *
   * • **secondary**
   *
   * Transparent button that pairs with the primary button. This is typically used for cancel or back buttons.
   *
   * • **danger**
   *
   * Red button that replaces primary buttons for dangerous or otherwise difficult-to-reverse actions like record deletion.
   */

  exports.buttonStylePropTypes = buttonStylePropTypes;
  var ButtonVariant = (0, _private_utils.createEnum)("default", "primary", "secondary", "danger");
  var buttonVariantPropType = (0, _private_utils.createPropTypeFromEnum)(ButtonVariant);
  /** @internal */

  function useButtonVariant() {
    var variant = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ButtonVariant.default;
    var _useTheme = (0, _use_theme.default)(),
      buttonVariants = _useTheme.buttonVariants;
    return buttonVariants[variant];
  }
  /**
   * Props for the {@link Button} component. Also accepts:
   * * {@link AriaProps}
   * * {@link ButtonStyleProps}
   *
   * @noInheritDoc
   * @docsPath UI/components/Button
   */

  /**
   * Clickable button component.
   *
   * [[ Story id="button--example" title="Box example" ]]
   *
   * @component
   * @docsPath UI/components/Button
   */
  var Button = (props, ref) => {
    var _props$size = props.size,
      size = _props$size === void 0 ? _control_sizes.ControlSize.default : _props$size,
      _props$variant = props.variant,
      variant = _props$variant === void 0 ? ButtonVariant.default : _props$variant,
      icon = props.icon,
      id = props.id,
      className = props.className,
      style = props.style,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onClick = props.onClick,
      hasOnClick = props.hasOnClick,
      _props$type = props.type,
      type = _props$type === void 0 ? "button" : _props$type,
      disabled = props.disabled,
      tabIndex = props.tabIndex,
      children = props.children,
      ariaLabel = props["aria-label"],
      ariaSelected = props["aria-selected"],
      styleProps = (0, _objectWithoutProperties2.default)(props, ["size", "variant", "icon", "id", "className", "style", "onMouseEnter", "onMouseLeave", "onClick", "hasOnClick", "type", "disabled", "tabIndex", "children", "aria-label", "aria-selected"]);
    var classNameForButtonSize = (0, _control_sizes.useButtonSize)(size);
    var classNameForButtonVariant = useButtonVariant(variant);
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({
      display: "inline-flex"
    }, styleProps), styleParser);
    var hasIcon = icon !== undefined;
    var hasChildren = !!children;
    if (!hasChildren && !ariaLabel) {
      // eslint-disable-next-line no-console
      console.error("<Button> without a text label should include an explicit aria-label prop.");
    }
    return React.createElement("button", {
      ref: ref,
      id: id,
      className: (0, _emotion.cx)(classNameForButtonVariant, classNameForButtonSize, classNameForStyleProps, className),
      style: style // TODO (stephen): remove tooltip anchor props
      ,

      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onClick: onClick,
      type: type,
      disabled: disabled,
      tabIndex: tabIndex,
      "aria-label": ariaLabel,
      "aria-selected": ariaSelected
    }, typeof icon === "string" ? React.createElement(_icon.default, {
      name: icon,
      size: "1em",
      fillColor: "currentColor",
      flex: "none"
    }) : icon, hasChildren && React.createElement(_box.default, {
      as: "span" // The margin is on the span, and not on the icon because it would mean that when using a custom icon
      // the consumer would manually need to figure out what the margin is supposed to be.
      ,

      marginLeft: hasIcon ? "0.5em" : undefined,
      className: _css_helpers.default.TRUNCATE
    }, children));
  };
  var ForwardedRefButton = React.forwardRef(Button);
  ForwardedRefButton.propTypes = _objectSpread({
    size: _control_sizes.controlSizePropType,
    variant: buttonVariantPropType,
    icon: _propTypes.default.oneOfType([_icon_config.iconNamePropType, _propTypes.default.element]),
    id: _propTypes.default.string,
    className: _propTypes.default.string,
    style: _propTypes.default.object,
    // `onClick` is already defined in `tooltipAnchorPropTypes`, for clarity we list it again.
    onClick: _propTypes.default.func,
    type: _propTypes.default.oneOf(["button", "submit", "reset"]),
    disabled: _propTypes.default.bool,
    tabIndex: _propTypes.default.number,
    // TODO: This is optional in order to support buttons with only icons.
    // In the future, if we add IconButton or similar, it can be made non-optional.
    children: _propTypes.default.node
  }, buttonStylePropTypes, {}, _tooltip_anchor_props.tooltipAnchorPropTypes, {}, _aria_props.ariaPropTypes);
  ForwardedRefButton.displayName = "Button";
  var _default = ForwardedRefButton;
  exports.default = _default;
  return exports;
}