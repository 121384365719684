import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.concat";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.filter";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esArray6 from "core-js/modules/es.array.map";
var _esArray5 = _esArray6;
try {
  if ("default" in _esArray6) _esArray5 = _esArray6.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _slicedToArray3 from "@babel/runtime/helpers/slicedToArray";
var _slicedToArray = _slicedToArray3;
try {
  if ("default" in _slicedToArray3) _slicedToArray = _slicedToArray3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _react3 from "react";
var _react2 = _react3;
try {
  if ("default" in _react3) _react2 = _react3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _select_and_select_buttons_helpers2Dew } from "./select_and_select_buttons_helpers";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _use_theme2Dew } from "./theme/use_theme";
import { dew as _css_helpers2Dew } from "./css_helpers";
import { dew as _system2Dew } from "./system";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
import { dew as _control_sizes2Dew } from "./control_sizes";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esArray3;
  _esArray5;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.sharedSelectButtonsPropTypes = exports.selectButtonsStylePropTypes = void 0;
  var _slicedToArray2 = _interopRequireDefault(_slicedToArray);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _emotion = _emotion2;
  var _react = _interopRequireWildcard(_react2);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _core = _core2;
  var _error_utils = _error_utils2Dew();
  var _private_utils = _private_utils2Dew();
  var _select_and_select_buttons_helpers = _select_and_select_buttons_helpers2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _use_theme = _interopRequireDefault(_use_theme2Dew());
  var _css_helpers = _interopRequireDefault(_css_helpers2Dew());
  var _system = _system2Dew();
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  var _control_sizes = _control_sizes2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var SelectButtonsVariant = (0, _private_utils.createEnum)("default");
  /** @internal */

  function useSelectButtonsVariant() {
    var variant = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : SelectButtonsVariant.default;
    var _useTheme = (0, _use_theme.default)(),
      selectButtonsVariants = _useTheme.selectButtonsVariants;
    return selectButtonsVariants[variant];
  }
  /**
   * Style props shared between the {@link SelectButtons} and {@link SelectButtonsSynced} components. Accepts:
   * * {@link FlexItemSetProps}
   * * {@link MarginProps}
   * * {@link MaxWidthProps}
   * * {@link MinWidthProps}
   * * {@link PositionSetProps}
   * * {@link WidthProps}
   *
   * @noInheritDoc
   */

  var styleParser = (0, _core.compose)(_system.maxWidth, _system.minWidth, _system.width, _system.flexItemSet, _system.positionSet, _system.margin);
  var selectButtonsStylePropTypes = _objectSpread({}, _system.maxWidthPropTypes, {}, _system.minWidthPropTypes, {}, _system.widthPropTypes, {}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.marginPropTypes);
  /**
   * Props shared between the {@link SelectButtons} and {@link SelectButtonsSynced} components.
   *
   * @noInheritDoc
   */

  exports.selectButtonsStylePropTypes = selectButtonsStylePropTypes;
  var sharedSelectButtonsPropTypes = _objectSpread({
    // We do more strict checks in render.
    options: _propTypes.default.arrayOf(_propTypes.default.shape({
      value: _select_and_select_buttons_helpers.selectOptionValuePropType,
      label: _propTypes.default.node.isRequired,
      disabled: _propTypes.default.bool
    }).isRequired).isRequired,
    onChange: _propTypes.default.func,
    disabled: _propTypes.default.bool,
    size: _control_sizes.controlSizePropType,
    className: _propTypes.default.string,
    style: _propTypes.default.object,
    "aria-labelledby": _propTypes.default.string,
    "aria-describedby": _propTypes.default.string
  }, selectButtonsStylePropTypes, {}, _tooltip_anchor_props.tooltipAnchorPropTypes, {}, selectButtonsStylePropTypes);
  /**
   * Props for the {@link SelectButtons} component. Also accepts:
   * * {@link SelectButtonsStyleProps}
   *
   * @docsPath UI/components/SelectButtons
   */

  exports.sharedSelectButtonsPropTypes = sharedSelectButtonsPropTypes;

  /**
   * A segmented control for selecting one value from a set of options.
   *
   * [[ Story id="selectbuttons--example" title="Select buttons example" ]]
   *
   * @docsPath UI/components/SelectButtons
   * @component
   */
  var SelectButtons = (props, ref) => {
    var className = props.className,
      style = props.style,
      options = props.options,
      disabled = props.disabled,
      value = props.value,
      _props$size = props.size,
      size = _props$size === void 0 ? _control_sizes.ControlSize.default : _props$size,
      onChange = props.onChange,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onClick = props.onClick,
      hasOnClick = props.hasOnClick,
      ariaDescribedBy = props["aria-describedby"],
      ariaLabelledBy = props["aria-labelledby"],
      styleProps = (0, _objectWithoutProperties2.default)(props, ["className", "style", "options", "disabled", "value", "size", "onChange", "onMouseEnter", "onMouseLeave", "onClick", "hasOnClick", "aria-describedby", "aria-labelledby"]); // There is only a single default variant.

    var _useSelectButtonsVari = useSelectButtonsVariant(),
      containerClassNameForVariant = _useSelectButtonsVari.containerClassNameForVariant,
      optionClassNameForVariant = _useSelectButtonsVari.optionClassNameForVariant;
    var containerClassNameForSize = (0, _control_sizes.useSelectButtonsSize)(size);
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({
      width: "100%"
    }, styleProps), styleParser);
    var _useState = (0, _react.useState)((0, _private_utils.getLocallyUniqueId)("sb-")),
      _useState2 = (0, _slicedToArray2.default)(_useState, 1),
      generatedId = _useState2[0];
    var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      isFocused = _useState4[0],
      setIsFocused = _useState4[1];
    (0, _react.useEffect)(() => {
      // Check options here for a cleaner stack trace.
      // Also, even though options are required, still check if it's set because
      // the error is really ugly and covers up the prop type check.
      var validationResult = (0, _select_and_select_buttons_helpers.validateOptions)(options);
      if (!validationResult.isValid) {
        throw (0, _error_utils.spawnError)("<SelectButtons> %s", validationResult.reason);
      }
    }, [options]);
    function _onChange(newValue) {
      if (onChange) {
        if (newValue !== value) {
          onChange(newValue);
        }
      }
    }
    return _react.default.createElement("div", {
      // TODO (stephen): remove tooltip anchor props
      ref: ref,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onClick: onClick,
      onFocus: () => setIsFocused(true),
      onBlur: () => setIsFocused(false),
      className: (0, _emotion.cx)(containerClassNameForSize, containerClassNameForVariant, classNameForStyleProps, className),
      style: style,
      "data-disabled": disabled,
      "data-focused": isFocused,
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy
    }, options.map((option, index) => {
      var isSelected = option.value === value;
      var isOptionDisabled = disabled || option.disabled;
      var radioId = "".concat(generatedId, "-").concat(index);
      var valueJson = (0, _select_and_select_buttons_helpers.optionValueToString)(option.value);
      return _react.default.createElement("div", {
        key: "".concat(valueJson, "-").concat(option.label, "-").concat(index),
        className: optionClassNameForVariant
      }, _react.default.createElement("input", {
        type: "radio",
        onChange: isOptionDisabled ? undefined : () => _onChange(option.value),
        disabled: isOptionDisabled,
        checked: isSelected,
        className: _css_helpers.default.VISUALLY_HIDDEN,
        id: radioId,
        name: generatedId
      }), _react.default.createElement("label", {
        htmlFor: radioId
      }, _react.default.createElement("span", {
        className: _css_helpers.default.TRUNCATE
      }, option.label)));
    }));
  };
  var ForwardedRefSelectButtons = _react.default.forwardRef(SelectButtons);
  ForwardedRefSelectButtons.propTypes = _objectSpread({
    value: _select_and_select_buttons_helpers.selectOptionValuePropType
  }, sharedSelectButtonsPropTypes);
  ForwardedRefSelectButtons.displayName = "SelectButtons";
  var _default = ForwardedRefSelectButtons;
  exports.default = _default;
  return exports;
}