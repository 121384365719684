import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _index2Dew } from "./system/index";
import { dew as _get_style_props_for_responsive_prop2Dew } from "./system/utils/get_style_props_for_responsive_prop";
import { dew as _use_theme2Dew } from "./theme/use_theme";
import { dew as _aria_props2Dew } from "./types/aria_props";
import { dew as _data_attributes_prop2Dew } from "./types/data_attributes_prop";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.useTextStyle = useTextStyle;
  exports.default = exports.textSizePropType = exports.TextSize = exports.textVariantPropType = exports.TextVariant = void 0;
  var _extends2 = _interopRequireDefault(_extends);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var React = _interopRequireWildcard(_react);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var _private_utils = _private_utils2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _index = _index2Dew();
  var _get_style_props_for_responsive_prop = _interopRequireDefault(_get_style_props_for_responsive_prop2Dew());
  var _use_theme = _interopRequireDefault(_use_theme2Dew());
  var _aria_props = _aria_props2Dew();
  var _data_attributes_prop = _data_attributes_prop2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var TextVariant = (0, _private_utils.createEnum)("default", "paragraph");
  exports.TextVariant = TextVariant;
  var textVariantPropType = (0, _private_utils.createPropTypeFromEnum)(TextVariant);
  /**
   * Sizes for the {@link Text} component.
   */

  exports.textVariantPropType = textVariantPropType;
  var TextSize = (0, _private_utils.createEnum)("small", "default", "large", "xlarge");
  /** */

  exports.TextSize = TextSize;
  var textSizePropType = (0, _private_utils.createResponsivePropTypeFromEnum)(TextSize);
  /** @internal */

  exports.textSizePropType = textSizePropType;
  function useTextStyle(textSizeProp) {
    var variant = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : TextVariant.default;
    var _useTheme = (0, _use_theme.default)(),
      textStyles = _useTheme.textStyles;
    var textSizesForVariant = textStyles[variant];
    var styleProps;
    if (typeof textSizeProp === "string") {
      styleProps = textSizesForVariant[textSizeProp];
    } else {
      styleProps = (0, _get_style_props_for_responsive_prop.default)(textSizeProp, textSizesForVariant);
    }
    return (0, _use_styled_system.default)(styleProps);
  }
  /**
   * Props for the {@link Text} component. Also supports:
   * * {@link AriaProps}
   * * {@link AllStylesProps}
   *
   * @noInheritDoc
   * @docsPath UI/components/Text
   */

  /**
   * A text component with sizes and variants.
   *
   * [[ Story id="text--example" title="Text example" ]]
   *
   * @docsPath UI/components/Text
   * @component
   */
  var Text = (props, ref) => {
    var _props$as = props.as,
      Component = _props$as === void 0 ? "p" : _props$as,
      _props$size = props.size,
      size = _props$size === void 0 ? TextSize.default : _props$size,
      _props$variant = props.variant,
      variant = _props$variant === void 0 ? TextVariant.default : _props$variant,
      children = props.children,
      id = props.id,
      role = props.role,
      dataAttributes = props.dataAttributes,
      className = props.className,
      style = props.style,
      ariaLabel = props["aria-label"],
      ariaLabelledBy = props["aria-labelledby"],
      ariaDescribedBy = props["aria-describedby"],
      ariaControls = props["aria-controls"],
      ariaExpanded = props["aria-expanded"],
      ariaHasPopup = props["aria-haspopup"],
      ariaHidden = props["aria-hidden"],
      ariaLive = props["aria-live"],
      styleProps = (0, _objectWithoutProperties2.default)(props, ["as", "size", "variant", "children", "id", "role", "dataAttributes", "className", "style", "aria-label", "aria-labelledby", "aria-describedby", "aria-controls", "aria-expanded", "aria-haspopup", "aria-hidden", "aria-live"]);
    var classNameForTextStyle = useTextStyle(size, variant);
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({
      textColor: "default",
      fontFamily: "default"
    }, styleProps));
    return React.createElement(Component, (0, _extends2.default)({
      ref: ref,
      id: id,
      className: (0, _emotion.cx)(classNameForTextStyle, classNameForStyleProps, className),
      style: style,
      role: role,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy,
      "aria-controls": ariaControls,
      "aria-expanded": ariaExpanded,
      "aria-haspopup": ariaHasPopup,
      "aria-hidden": ariaHidden,
      "aria-live": ariaLive
    }, dataAttributes), children);
  };
  var ForwardedRefText = React.forwardRef(Text);
  ForwardedRefText.propTypes = _objectSpread({
    as: _propTypes.default.oneOf(["p", "h1", "h2", "h3", "h4", "h5", "h6", "span", "li", "em", "strong", "kbd", "mark", "q", "s", "samp", "small", "sub", "sup", "time", "var", "blockquote"]),
    size: textSizePropType,
    variant: textVariantPropType,
    children: _propTypes.default.node,
    id: _propTypes.default.string,
    role: _propTypes.default.string,
    dataAttributes: _data_attributes_prop.dataAttributesPropType,
    className: _propTypes.default.string,
    style: _propTypes.default.object
  }, _index.allStylesPropTypes, {}, _aria_props.ariaPropTypes);
  ForwardedRefText.displayName = "Text";
  var _default = ForwardedRefText;
  exports.default = _default;
  return exports;
}