import * as _react3 from "react";
var _react2 = _react3;
try {
  if ("default" in _react3) _react2 = _react3.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = useFormField;
  exports.FormFieldContext = void 0;
  var _react = _react2;
  var FormFieldContext = (0, _react.createContext)(null);
  /**
   * Returns the ID for a control (eg input, select, etc.) inside of a `FormField`.
   *
   * @internal
   */

  exports.FormFieldContext = FormFieldContext;
  function useFormField() {
    return (0, _react.useContext)(FormFieldContext);
  }
  return exports;
}