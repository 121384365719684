import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import { dew as _tokens2Dew } from "./tokens";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _emotion = _emotion2;
  var _tokens = _tokens2Dew();
  var baseContainerStyles = (0, _emotion.css)({
    borderRadius: _tokens.radii.default,
    backgroundColor: _tokens.colors.lightGray2,
    boxSizing: "border-box",
    fontFamily: _tokens.fontFamilies.default,
    overflow: "hidden",
    display: "flex",
    userSelect: "none",
    fontWeight: 400,
    "&:active, &[data-focused=\"true\"]": {
      boxShadow: "inset 0 0 0 2px ".concat(_tokens.colors.darken3)
    },
    "&[data-disabled=\"true\"]": {
      opacity: _tokens.opacities.quieter,
      cursor: "default"
    }
  });
  var baseOptionStyles = (0, _emotion.css)({
    position: "relative",
    display: "flex",
    flex: "auto",
    flexBasis: 0,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "> label": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      borderRadius: _tokens.radii.default,
      outline: 0,
      paddingRight: "4px",
      paddingLeft: "4px",
      "&:active": {
        opacity: 1
      }
    },
    "> input:disabled + label": {
      cursor: "default",
      opacity: _tokens.opacities.quieter
    },
    "> input:not(:disabled) + label": {
      cursor: "pointer"
    },
    "> input:checked + label": {
      backgroundColor: _tokens.colors.darken4,
      color: _tokens.colors.white
    },
    "> input:not(:checked) + label": {
      color: _tokens.colors.dark
    },
    "> input:not(:checked):not(:disabled) + label:hover": {
      opacity: _tokens.opacities.quiet
    }
  });
  var selectButtonsVariants = {
    default: {
      containerClassNameForVariant: baseContainerStyles,
      optionClassNameForVariant: baseOptionStyles
    }
  };
  var _default = selectButtonsVariants;
  exports.default = _default;
  return exports;
}