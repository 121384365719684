import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _emotion = _emotion2;
  var cssHelpers = {
    TRUNCATE: (0, _emotion.css)({
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }),
    VISUALLY_HIDDEN: (0, _emotion.css)({
      position: "absolute",
      height: "1px",
      width: "1px",
      overflow: "hidden",
      clip: ["rect(1px, 1px, 1px, 1px)", "rect(1px 1px 1px 1px)"]
      /* fallback for IE6, IE7 */,

      whiteSpace: "nowrap"
      /* added line */
    })
  };

  var _default = cssHelpers;
  exports.default = _default;
  return exports;
}