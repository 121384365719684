import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _global_alert2Dew } from "./global_alert";
import { dew as _Dew } from "../";
import { dew as _base_provider2Dew } from "./base_provider";
import { dew as _colors2Dew } from "../colors";
import { dew as _color_utils2Dew } from "../color_utils";
import { dew as _synced2Dew } from "./synced";
import { dew as _table_picker2Dew } from "./table_picker";
import { dew as _table_picker_synced2Dew } from "./table_picker_synced";
import { dew as _field_picker2Dew } from "./field_picker";
import { dew as _field_picker_synced2Dew } from "./field_picker_synced";
import { dew as _view_picker2Dew } from "./view_picker";
import { dew as _view_picker_synced2Dew } from "./view_picker_synced";
import { dew as _input_synced2Dew } from "./input_synced";
import { dew as _cell_renderer2Dew } from "./cell_renderer";
import { dew as _expand_record2Dew } from "./expand_record";
import { dew as _expand_record_list2Dew } from "./expand_record_list";
import { dew as _expand_record_picker_async2Dew } from "./expand_record_picker_async";
import { dew as _field_icon2Dew } from "./field_icon";
import { dew as _color_palette_synced2Dew } from "./color_palette_synced";
import { dew as _progress_bar2Dew } from "./progress_bar";
import { dew as _record_card2Dew } from "./record_card";
import { dew as _record_card_list2Dew } from "./record_card_list";
import { dew as _select_synced2Dew } from "./select_synced";
import { dew as _select_buttons_synced2Dew } from "./select_buttons_synced";
import { dew as _switch_synced2Dew } from "./switch_synced";
import { dew as _viewport_constraint2Dew } from "./viewport_constraint";
import { dew as _initialize_block2Dew } from "./initialize_block";
import { dew as _with_hooks2Dew } from "./with_hooks";
import { dew as _use_loadable2Dew } from "./use_loadable";
import { dew as _use_records2Dew } from "./use_records";
import { dew as _use_base2Dew } from "./use_base";
import { dew as _use_cursor2Dew } from "./use_cursor";
import { dew as _use_session2Dew } from "./use_session";
import { dew as _use_settings_button2Dew } from "./use_settings_button";
import { dew as _use_synced2Dew } from "./use_synced";
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _use_viewport2Dew } from "./use_viewport";
import { dew as _use_global_config2Dew } from "./use_global_config";
import { dew as _use_view_metadata2Dew } from "./use_view_metadata";
import { dew as _use_record_action_data2Dew } from "./use_record_action_data";
import { dew as _perform_record_action2Dew } from "../perform_record_action";
import { dew as _unstable_standalone_ui2Dew } from "./unstable_standalone_ui";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    globalAlert: true,
    GlobalAlert: true,
    BaseProvider: true,
    colors: true,
    colorUtils: true,
    Synced: true,
    TablePicker: true,
    TablePickerSynced: true,
    FieldPicker: true,
    FieldPickerSynced: true,
    ViewPicker: true,
    ViewPickerSynced: true,
    InputSynced: true,
    CellRenderer: true,
    expandRecord: true,
    expandRecordList: true,
    expandRecordPickerAsync: true,
    FieldIcon: true,
    ColorPaletteSynced: true,
    ProgressBar: true,
    RecordCard: true,
    RecordCardList: true,
    SelectSynced: true,
    SelectButtonsSynced: true,
    SwitchSynced: true,
    ViewportConstraint: true,
    initializeBlock: true,
    withHooks: true,
    useLoadable: true,
    useRecordIds: true,
    useRecords: true,
    useRecordById: true,
    useRecordQueryResult: true,
    useBase: true,
    useCursor: true,
    useSession: true,
    useSettingsButton: true,
    useSynced: true,
    useWatchable: true,
    useViewport: true,
    useGlobalConfig: true,
    useViewMetadata: true,
    useRecordActionData: true,
    registerRecordActionDataCallback: true
  };
  Object.defineProperty(exports, "GlobalAlert", {
    enumerable: true,
    get: function get() {
      return _global_alert.default;
    }
  });
  Object.defineProperty(exports, "BaseProvider", {
    enumerable: true,
    get: function get() {
      return _base_provider.default;
    }
  });
  Object.defineProperty(exports, "colors", {
    enumerable: true,
    get: function get() {
      return _colors.default;
    }
  });
  Object.defineProperty(exports, "colorUtils", {
    enumerable: true,
    get: function get() {
      return _color_utils.default;
    }
  });
  Object.defineProperty(exports, "Synced", {
    enumerable: true,
    get: function get() {
      return _synced.default;
    }
  });
  Object.defineProperty(exports, "TablePicker", {
    enumerable: true,
    get: function get() {
      return _table_picker.default;
    }
  });
  Object.defineProperty(exports, "TablePickerSynced", {
    enumerable: true,
    get: function get() {
      return _table_picker_synced.default;
    }
  });
  Object.defineProperty(exports, "FieldPicker", {
    enumerable: true,
    get: function get() {
      return _field_picker.default;
    }
  });
  Object.defineProperty(exports, "FieldPickerSynced", {
    enumerable: true,
    get: function get() {
      return _field_picker_synced.default;
    }
  });
  Object.defineProperty(exports, "ViewPicker", {
    enumerable: true,
    get: function get() {
      return _view_picker.default;
    }
  });
  Object.defineProperty(exports, "ViewPickerSynced", {
    enumerable: true,
    get: function get() {
      return _view_picker_synced.default;
    }
  });
  Object.defineProperty(exports, "InputSynced", {
    enumerable: true,
    get: function get() {
      return _input_synced.default;
    }
  });
  Object.defineProperty(exports, "CellRenderer", {
    enumerable: true,
    get: function get() {
      return _cell_renderer.default;
    }
  });
  Object.defineProperty(exports, "expandRecord", {
    enumerable: true,
    get: function get() {
      return _expand_record.default;
    }
  });
  Object.defineProperty(exports, "expandRecordList", {
    enumerable: true,
    get: function get() {
      return _expand_record_list.default;
    }
  });
  Object.defineProperty(exports, "expandRecordPickerAsync", {
    enumerable: true,
    get: function get() {
      return _expand_record_picker_async.default;
    }
  });
  Object.defineProperty(exports, "FieldIcon", {
    enumerable: true,
    get: function get() {
      return _field_icon.default;
    }
  });
  Object.defineProperty(exports, "ColorPaletteSynced", {
    enumerable: true,
    get: function get() {
      return _color_palette_synced.default;
    }
  });
  Object.defineProperty(exports, "ProgressBar", {
    enumerable: true,
    get: function get() {
      return _progress_bar.default;
    }
  });
  Object.defineProperty(exports, "RecordCard", {
    enumerable: true,
    get: function get() {
      return _record_card.default;
    }
  });
  Object.defineProperty(exports, "RecordCardList", {
    enumerable: true,
    get: function get() {
      return _record_card_list.default;
    }
  });
  Object.defineProperty(exports, "SelectSynced", {
    enumerable: true,
    get: function get() {
      return _select_synced.default;
    }
  });
  Object.defineProperty(exports, "SelectButtonsSynced", {
    enumerable: true,
    get: function get() {
      return _select_buttons_synced.default;
    }
  });
  Object.defineProperty(exports, "SwitchSynced", {
    enumerable: true,
    get: function get() {
      return _switch_synced.default;
    }
  });
  Object.defineProperty(exports, "ViewportConstraint", {
    enumerable: true,
    get: function get() {
      return _viewport_constraint.default;
    }
  });
  Object.defineProperty(exports, "initializeBlock", {
    enumerable: true,
    get: function get() {
      return _initialize_block.initializeBlock;
    }
  });
  Object.defineProperty(exports, "withHooks", {
    enumerable: true,
    get: function get() {
      return _with_hooks.default;
    }
  });
  Object.defineProperty(exports, "useLoadable", {
    enumerable: true,
    get: function get() {
      return _use_loadable.default;
    }
  });
  Object.defineProperty(exports, "useRecordIds", {
    enumerable: true,
    get: function get() {
      return _use_records.useRecordIds;
    }
  });
  Object.defineProperty(exports, "useRecords", {
    enumerable: true,
    get: function get() {
      return _use_records.useRecords;
    }
  });
  Object.defineProperty(exports, "useRecordById", {
    enumerable: true,
    get: function get() {
      return _use_records.useRecordById;
    }
  });
  Object.defineProperty(exports, "useRecordQueryResult", {
    enumerable: true,
    get: function get() {
      return _use_records.useRecordQueryResult;
    }
  });
  Object.defineProperty(exports, "useBase", {
    enumerable: true,
    get: function get() {
      return _use_base.default;
    }
  });
  Object.defineProperty(exports, "useCursor", {
    enumerable: true,
    get: function get() {
      return _use_cursor.default;
    }
  });
  Object.defineProperty(exports, "useSession", {
    enumerable: true,
    get: function get() {
      return _use_session.default;
    }
  });
  Object.defineProperty(exports, "useSettingsButton", {
    enumerable: true,
    get: function get() {
      return _use_settings_button.default;
    }
  });
  Object.defineProperty(exports, "useSynced", {
    enumerable: true,
    get: function get() {
      return _use_synced.default;
    }
  });
  Object.defineProperty(exports, "useWatchable", {
    enumerable: true,
    get: function get() {
      return _use_watchable.default;
    }
  });
  Object.defineProperty(exports, "useViewport", {
    enumerable: true,
    get: function get() {
      return _use_viewport.default;
    }
  });
  Object.defineProperty(exports, "useGlobalConfig", {
    enumerable: true,
    get: function get() {
      return _use_global_config.default;
    }
  });
  Object.defineProperty(exports, "useViewMetadata", {
    enumerable: true,
    get: function get() {
      return _use_view_metadata.default;
    }
  });
  Object.defineProperty(exports, "useRecordActionData", {
    enumerable: true,
    get: function get() {
      return _use_record_action_data.default;
    }
  });
  Object.defineProperty(exports, "registerRecordActionDataCallback", {
    enumerable: true,
    get: function get() {
      return _perform_record_action.registerRecordActionDataCallback;
    }
  });
  exports.globalAlert = void 0;
  var _global_alert = _interopRequireDefault(_global_alert2Dew());
  _Dew();
  var _base_provider = _interopRequireDefault(_base_provider2Dew());
  var _colors = _interopRequireDefault(_colors2Dew());
  var _color_utils = _interopRequireDefault(_color_utils2Dew());
  var _synced = _interopRequireDefault(_synced2Dew());
  var _table_picker = _interopRequireDefault(_table_picker2Dew());
  var _table_picker_synced = _interopRequireDefault(_table_picker_synced2Dew());
  var _field_picker = _interopRequireDefault(_field_picker2Dew());
  var _field_picker_synced = _interopRequireDefault(_field_picker_synced2Dew());
  var _view_picker = _interopRequireDefault(_view_picker2Dew());
  var _view_picker_synced = _interopRequireDefault(_view_picker_synced2Dew());
  var _input_synced = _interopRequireDefault(_input_synced2Dew());
  var _cell_renderer = _interopRequireDefault(_cell_renderer2Dew());
  var _expand_record = _interopRequireDefault(_expand_record2Dew());
  var _expand_record_list = _interopRequireDefault(_expand_record_list2Dew());
  var _expand_record_picker_async = _interopRequireDefault(_expand_record_picker_async2Dew());
  var _field_icon = _interopRequireDefault(_field_icon2Dew());
  var _color_palette_synced = _interopRequireDefault(_color_palette_synced2Dew());
  var _progress_bar = _interopRequireDefault(_progress_bar2Dew());
  var _record_card = _interopRequireDefault(_record_card2Dew());
  var _record_card_list = _interopRequireDefault(_record_card_list2Dew());
  var _select_synced = _interopRequireDefault(_select_synced2Dew());
  var _select_buttons_synced = _interopRequireDefault(_select_buttons_synced2Dew());
  var _switch_synced = _interopRequireDefault(_switch_synced2Dew());
  var _viewport_constraint = _interopRequireDefault(_viewport_constraint2Dew());
  var _initialize_block = _initialize_block2Dew();
  var _with_hooks = _interopRequireDefault(_with_hooks2Dew());
  var _use_loadable = _interopRequireDefault(_use_loadable2Dew());
  var _use_records = _use_records2Dew();
  var _use_base = _interopRequireDefault(_use_base2Dew());
  var _use_cursor = _interopRequireDefault(_use_cursor2Dew());
  var _use_session = _interopRequireDefault(_use_session2Dew());
  var _use_settings_button = _interopRequireDefault(_use_settings_button2Dew());
  var _use_synced = _interopRequireDefault(_use_synced2Dew());
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _use_viewport = _interopRequireDefault(_use_viewport2Dew());
  var _use_global_config = _interopRequireDefault(_use_global_config2Dew());
  var _use_view_metadata = _interopRequireDefault(_use_view_metadata2Dew());
  var _use_record_action_data = _interopRequireDefault(_use_record_action_data2Dew());
  var _perform_record_action = _perform_record_action2Dew();
  var _unstable_standalone_ui = _unstable_standalone_ui2Dew();
  Object.keys(_unstable_standalone_ui).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    Object.defineProperty(exports, key, {
      enumerable: true,
      get: function get() {
        return _unstable_standalone_ui[key];
      }
    });
  });

  /** @ignore */
  // Import the top-level entry point so that an Sdk instance is created from the
  // globally-available AirtableInterface instance. This is a requirement of
  // third-party App code. Consumers who cannot provide an implementation of
  // AirtableInterface may access a subset of the UI Components available here by
  // instead importing `unstable_standalone_ui`.
  var globalAlert = new _global_alert.default();
  exports.globalAlert = globalAlert;
  return exports;
}