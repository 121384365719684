import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = useGlobalConfig;
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _sdk_context = _sdk_context2Dew();

  /** @module @airtable/blocks/ui: useGlobalConfig */

  /** */

  /**
   * Returns the extension's {@link GlobalConfig} and updates whenever any key in {@link GlobalConfig}
   * changes.
   *
   * @example
   * ```js
   * import {Button, useGlobalConfig} from '@airtable/blocks/ui';
   *
   * function SyncedCounter() {
   *     const globalConfig = useGlobalConfig();
   *     const count = globalConfig.get('count');
   *
   *     const increment = () => globalConfig.setAsync('count', count + 1);
   *     const decrement = () => globalConfig.setAsync('count', count - 1);
   *     const isEnabled = globalConfig.hasPermissionToSet('count');
   *
   *     return (
   *         <React.Fragment>
   *             <Button icon="minus" onClick={decrement} disabled={!isEnabled} ariaLabel="decrease"/>
   *             {count}
   *             <Button icon="plus" onClick={increment} disabled={!isEnabled} ariaLabel="increase"/>
   *         </React.Fragment>
   *     );
   * }
   * ```
   * @docsPath UI/hooks/useGlobalConfig
   * @hook
   */
  function useGlobalConfig() {
    var _useSdk = (0, _sdk_context.useSdk)(),
      globalConfig = _useSdk.globalConfig,
      session = _useSdk.session;
    (0, _use_watchable.default)(session, ["permissionLevel"]);
    (0, _use_watchable.default)(globalConfig, ["*"]);
    return globalConfig;
  }
  return exports;
}