import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.includes";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _use_theme2Dew } from "./theme/use_theme";
import { dew as _aria_props2Dew } from "./types/aria_props";
import { dew as _create_responsive_prop_type2Dew } from "./system/utils/create_responsive_prop_type";
import { dew as _system2Dew } from "./system";
import { dew as _text2Dew } from "./text";
import { dew as _icon_config2Dew } from "./icon_config";
import { dew as _icon2Dew } from "./icon";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
import { dew as _css_helpers2Dew } from "./css_helpers";
import { dew as _box2Dew } from "./box";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esArray3;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.textButtonStylePropTypes = void 0;
  var _extends2 = _interopRequireDefault(_extends);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var React = _interopRequireWildcard(_react);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var _core = _core2;
  var _private_utils = _private_utils2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _use_theme = _interopRequireDefault(_use_theme2Dew());
  var _aria_props = _aria_props2Dew();
  var _create_responsive_prop_type = _interopRequireDefault(_create_responsive_prop_type2Dew());
  var _system = _system2Dew();
  var _text = _text2Dew();
  var _icon_config = _icon_config2Dew();
  var _icon = _interopRequireDefault(_icon2Dew());
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  var _css_helpers = _interopRequireDefault(_css_helpers2Dew());
  var _box = _interopRequireDefault(_box2Dew());
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var styleParser = (0, _core.compose)(_system.display, _system.maxWidth, _system.minWidth, _system.width, _system.flexItemSet, _system.positionSet, _system.spacingSet);
  var textButtonStylePropTypes = _objectSpread({
    display: (0, _create_responsive_prop_type.default)(_propTypes.default.oneOf(["inline-flex", "flex", "none"]))
  }, _system.maxWidthPropTypes, {}, _system.minWidthPropTypes, {}, _system.widthPropTypes, {}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.spacingSetPropTypes);
  /**
   * Variants for the {@link TextButton} component:
   *
   * • **default**
   *
   * Blue text.
   *
   * • **dark**
   *
   * Dark gray text.
   *
   * • **light**
   *
   * Light gray text.
   */

  exports.textButtonStylePropTypes = textButtonStylePropTypes;
  var TextButtonVariant = (0, _private_utils.createEnum)("default", "dark", "light");
  var textButtonVariantPropType = (0, _private_utils.createPropTypeFromEnum)(TextButtonVariant);
  /** @internal */

  function useTextButtonVariant() {
    var variant = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : TextButtonVariant.default;
    var _useTheme = (0, _use_theme.default)(),
      textButtonVariants = _useTheme.textButtonVariants;
    return textButtonVariants[variant];
  }
  /**
   * Props for the {@link TextButton} component. Also supports:
   * * {@link AriaProps}
   * * {@link TextButtonStyleProps}
   *
   * @noInheritDoc
   * @docsPath UI/components/TextButton
   */

  /**
   * A text button component with sizes and variants.
   *
   * [[ Story id="textbutton--example" title="Text button example" ]]
   *
   * @docsPath UI/components/TextButton
   * @component
   */
  var TextButton = (props, ref) => {
    var _props$size = props.size,
      size = _props$size === void 0 ? _text.TextSize.default : _props$size,
      _props$variant = props.variant,
      variant = _props$variant === void 0 ? TextButtonVariant.default : _props$variant,
      icon = props.icon,
      children = props.children,
      disabled = props.disabled,
      id = props.id,
      _props$tabIndex = props.tabIndex,
      tabIndex = _props$tabIndex === void 0 ? 0 : _props$tabIndex,
      dataAttributes = props.dataAttributes,
      onClick = props.onClick,
      className = props.className,
      style = props.style,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      hasOnClick = props.hasOnClick,
      ariaSelected = props["aria-selected"],
      ariaLabel = props["aria-label"],
      ariaLabelledBy = props["aria-labelledby"],
      ariaDescribedBy = props["aria-describedby"],
      ariaControls = props["aria-controls"],
      ariaExpanded = props["aria-expanded"],
      ariaHasPopup = props["aria-haspopup"],
      ariaHidden = props["aria-hidden"],
      ariaLive = props["aria-live"],
      styleProps = (0, _objectWithoutProperties2.default)(props, ["size", "variant", "icon", "children", "disabled", "id", "tabIndex", "dataAttributes", "onClick", "className", "style", "onMouseEnter", "onMouseLeave", "hasOnClick", "aria-selected", "aria-label", "aria-labelledby", "aria-describedby", "aria-controls", "aria-expanded", "aria-haspopup", "aria-hidden", "aria-live"]);
    var classNameForTextStyle = (0, _text.useTextStyle)(size);
    var classNameForTextButtonVariant = useTextButtonVariant(variant);
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({
      display: "inline-flex",
      // Use a negative margin to undo the padding.
      padding: "0 0.1em",
      margin: "0 -0.1em",
      maxWidth: "100%"
    }, styleProps), styleParser);
    var hasIcon = icon !== undefined;
    var hasChildren = !!children;
    if (!hasChildren && !ariaLabel) {
      // eslint-disable-next-line no-console
      console.error("<TextButton> without a text label should include an explicit aria-label prop.");
    }
    /** @internal */

    function _onKeyDown(e) {
      if (e.ctrlKey || e.metaKey || e.shiftKey) {
        return;
      } //  Use `Spacebar` to support FF <= 37, IE 9-11.

      if ([" ", "Spacebar", "Enter"].includes(e.key)) {
        // Prevent scrolling when pressing `Spacebar`.
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      }
    }
    return React.createElement("span", (0, _extends2.default)({
      ref: ref,
      id: id // Don't set `tabIndex` if `disabled`. We do set it though even if
      // `onClick` is not provided so that it mimics the behavior of a native
      // `button`. We also prevent the user from passing in their own
      // `tabIndex` in the case that it is disabled. This is better than a
      // `-1` because `-1` will make the element focusable but not reachable
      // via keyboard navigation.
      ,

      tabIndex: !disabled ? tabIndex : undefined // Only fire these events if the `disabled` prop is not true.
      ,

      onClick: !disabled ? onClick : undefined,
      onKeyDown: !disabled ? _onKeyDown : undefined,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      className: (0, _emotion.cx)(classNameForTextStyle,
      // TextButton goes 2nd because it overrides `fontWeight`.
      classNameForTextButtonVariant, classNameForStyleProps, className),
      style: style // Always have `role="button"`, even if it is disabled. Combined with
      // `aria-disabled`, screen readers will announce this the same as
      // a native `button` element.
      ,

      role: "button" // Announce to screen readers that the `TextButton` is disabled.
      ,

      "aria-disabled": disabled ? "true" : undefined,
      "aria-selected": ariaSelected,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy,
      "aria-controls": ariaControls,
      "aria-expanded": ariaExpanded,
      "aria-haspopup": ariaHasPopup,
      "aria-hidden": ariaHidden,
      "aria-live": ariaLive
    }, dataAttributes), typeof icon === "string" ? React.createElement(_icon.default, {
      name: icon,
      flex: "none",
      size: "1em"
    }) : icon, hasChildren && React.createElement(_box.default, {
      as: "span" // The margin is on the span, and not on the icon because it would mean that when using a custom icon
      // the consumer would manually need to figure out what the margin is supposed to be.
      ,

      marginLeft: hasIcon ? "0.5em" : undefined,
      className: _css_helpers.default.TRUNCATE
    }, children));
  };
  var ForwardedRefTextButton = React.forwardRef(TextButton);
  ForwardedRefTextButton.propTypes = _objectSpread({
    size: _text.textSizePropType,
    variant: textButtonVariantPropType,
    icon: _propTypes.default.oneOfType([_icon_config.iconNamePropType, _propTypes.default.element]),
    disabled: _propTypes.default.bool,
    children: _propTypes.default.node,
    id: _propTypes.default.string,
    tabIndex: _propTypes.default.number,
    // `onClick` is already defined in `tooltipAnchorPropTypes`, for clarity we list it again.
    onClick: _propTypes.default.func,
    dataAttributes: _propTypes.default.any,
    className: _propTypes.default.string,
    style: _propTypes.default.object,
    "aria-selected": _propTypes.default.bool
  }, textButtonStylePropTypes, {}, _aria_props.ariaPropTypes, {}, _tooltip_anchor_props.tooltipAnchorPropTypes);
  ForwardedRefTextButton.displayName = "TextButton";
  var _default = ForwardedRefTextButton;
  exports.default = _default;
  return exports;
}