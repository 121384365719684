import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.iterator";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.to-string";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../../../error_utils";
import { dew as _private_utils2Dew } from "../../../private_utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  _es;
  _esSymbol;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getStylePropsForResponsiveProp;
  var _error_utils = _error_utils2Dew();
  var _private_utils = _private_utils2Dew();

  /**
   * Given a styled system scale, convert a responsive prop object into a style props object.
   * Typically used for variant APIs that compose multiple style props.
   *
   * @internal
   */
  function getStylePropsForResponsiveProp(responsivePropObject, scale) {
    var responsiveStyleProps = {};
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;
    try {
      for (var _iterator = (0, _private_utils.keys)(responsivePropObject)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var viewportKey = _step.value;
        var scaleValueForViewport = responsivePropObject[viewportKey];
        (0, _error_utils.invariant)(scaleValueForViewport, "scaleValueForViewport");
        var propsForViewport = scale[scaleValueForViewport];
        (0, _error_utils.invariant)(propsForViewport !== undefined && propsForViewport !== null, "propsForViewport");
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;
        try {
          for (var _iterator2 = (0, _private_utils.keys)(propsForViewport)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var propForViewportKey = _step2.value;
            if (!(0, _private_utils.has)(responsiveStyleProps, propForViewportKey)) {
              responsiveStyleProps[propForViewportKey] = {};
            }
            responsiveStyleProps[propForViewportKey][viewportKey] = propsForViewport[propForViewportKey];
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }
      /**
       * @internal
       * If multiple values are the same for each viewport, just use a string value.
       *
       * For example turn the following object:
       *
       * ```
       *  "fontSize": {
       *      "smallViewport": "13px",
       *      "mediumViewport": "15px"
       *  },
       *  "fontFamily": {
       *      "smallViewport": "default",
       *      "mediumViewport": "default"
       *  },
       * ```
       *
       * Into:
       *
       * ```
       *  "fontSize": {
       *      "smallViewport": "13px",
       *      "mediumViewport": "15px"
       *  },
       *  "fontFamily": "default"
       * ```
       */
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
    for (var _i = 0, _Object$keys = Object.keys(responsiveStyleProps); _i < _Object$keys.length; _i++) {
      var stylePropKey = _Object$keys[_i];
      var shouldConsolidateValue = true;
      var prevValue = void 0;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;
      try {
        for (var _iterator3 = (0, _private_utils.values)(responsiveStyleProps[stylePropKey])[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var value = _step3.value;
          if (prevValue === undefined) {
            prevValue = value;
          } else if (prevValue !== value) {
            shouldConsolidateValue = false;
            break;
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }
      if (shouldConsolidateValue) {
        responsiveStyleProps[stylePropKey] = prevValue;
      }
    }
    return responsiveStyleProps;
  }
  return exports;
}