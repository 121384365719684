import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
import { dew as _table2Dew } from "../models/table";
import { dew as _record_query_result2Dew } from "../models/record_query_result";
import { dew as _record_coloringDew } from "../models/record_coloring";
import { dew as _view2Dew } from "../models/view";
import { dew as _use_loadable2Dew } from "./use_loadable";
import { dew as _use_watchable2Dew } from "./use_watchable";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.useRecordIds = useRecordIds;
  exports.useRecords = useRecords;
  exports.useRecordById = useRecordById;
  exports.useRecordQueryResult = useRecordQueryResult;
  var _error_utils = _error_utils2Dew();
  var _table = _interopRequireDefault(_table2Dew());
  var _record_query_result = _interopRequireDefault(_record_query_result2Dew());
  var RecordColoring = _interopRequireWildcard(_record_coloringDew());
  var _view = _interopRequireDefault(_view2Dew());
  var _use_loadable = _interopRequireDefault(_use_loadable2Dew());
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());

  /** @module @airtable/blocks/ui: useRecords */

  /** */

  /**
   * Common code to all useRecordQueryResults, returns a RecordQueryResult and loads it - but does
   * not watch any properties on it.
   *
   * @param tableOrViewOrQueryResult
   * @param functionNameForErrors
   * @param opts
   * @internal
   */
  function _useUnwatchedRecordQueryResult(tableOrViewOrQueryResult, functionNameForErrors, opts) {
    var queryResult;
    if (tableOrViewOrQueryResult instanceof _table.default || tableOrViewOrQueryResult instanceof _view.default) {
      queryResult = tableOrViewOrQueryResult.selectRecords(opts);
    } else {
      if (tableOrViewOrQueryResult instanceof _record_query_result.default && opts !== undefined) {
        throw (0, _error_utils.spawnError)("%s does not support passing both a queryResult and opts.", functionNameForErrors);
      }
      queryResult = tableOrViewOrQueryResult;
    }
    (0, _use_loadable.default)(queryResult);
    return queryResult;
  }
  /** */

  /**
   * A hook for working with a set of record IDs from a table, view or query result. Automatically
   * handles loading data and updating your component when the underlying data changes.
   *
   * This hook doesn't re-render when the data _inside_ the records changes - only when records are
   * added, removed, or re-ordered. Use this over {@link useRecords} when you want to avoid watching
   * cell data for all records. Use with {@link useRecordById} to only watch cell data for certain
   * records.
   *
   * Under the hood, this hook creates a {@link TableOrViewQueryResult} if passed a table or view.
   * Pass a query result if you want direct access to it (e.g. for `queryResult.getRecordById`).
   *
   * Returns an array of record IDs, or null if no model was passed in.
   *
   * @param tableOrViewOrQueryResult The {@link Table}, {@link View} or {@link RecordQueryResult} you want the record ids from.
   * @param opts? If passing a Table or View, optional {@link RecordIdsQueryResultOpts} to control the results.
   * @example
   * ```js
   *  import {useRecordIds, useBase} from '@airtable/blocks/ui';
   *
   *  function RecordCount() {
   *      const base = useBase();
   *      const table = base.tables[0];
   *
   *      // grab all the record ids from that table
   *      const recordIds = useRecordIds(table);
   *
   *      // return the count
   *      return <span>record count in {table.name}: {recordIds.length}</span>;
   *  }
   * ```
   * @docsPath UI/hooks/useRecordIds
   * @hook
   */
  function useRecordIds(tableOrViewOrQueryResult, opts) {
    var generatedOpts = tableOrViewOrQueryResult instanceof _table.default || tableOrViewOrQueryResult instanceof _view.default ? {
      // Don't load/watch any cell value data, since it's not accessible anyway
      fields: [],
      // Similarly, don't load/watch record color mode data
      recordColorMode: RecordColoring.modes.none(),
      sorts: opts ? opts.sorts : undefined
    } : opts;
    var queryResult = _useUnwatchedRecordQueryResult(tableOrViewOrQueryResult, "useRecordIds", generatedOpts);
    (0, _use_watchable.default)(queryResult, ["recordIds"]);
    return queryResult ? queryResult.recordIds : null;
  }
  /** */

  /**
   * A hook for working with all of the records (including their colors and cell values) in a
   * particular table, view or query result. Automatically handles loading data and updating
   * your component when the underlying data changes.
   *
   * This hook re-renders when data concerning the records changes (specifically, when cell values
   * change, the record color changes, and when records are added or removed) - that's
   * useful, but can cause re-renders quite often, meaning {@link useRecordIds} or
   * {@link useRecordById} could be more appropriate depending on your use case.
   *
   * Under the hood, this hook creates a {@link TableOrViewQueryResult} if passed a table or view.
   * Pass a query result if you want direct access to it (e.g. for `queryResult.getRecordById`).
   *
   * Returns a list of records, or null if no model was passed in.
   *
   * @param tableOrViewOrQueryResult The {@link Table}, {@link View} or {@link RecordQueryResult} you want the records from.
   * @param opts? If passing a Table or View, optional {@link RecordQueryResultOpts} to control the results.
   * @example
   * ```js
   *  import {useRecords, useBase} from '@airtable/blocks/ui';
   *
   *  function GetRecords() {
   *      const base = useBase();
   *      const table = base.tables[0];
   *      const view = table.views[0];
   *      let records;
   *
   *      // Returns all records in the table
   *      records = useRecords(table);
   *
   *      // Equivalent to the above - useful if you want to reuse the queryResult elsewhere
   *      const queryResult = table.selectRecords();
   *      records = useRecords(queryResult);
   *
   *      // Returns all records for a view
   *      records = useRecords(view)
   *
   *      // Returns all records in a table, only loading data for the specified fields
   *      records = useRecords(table, {fields: ['My field']});
   *
   *      // Returns all records in a table, sorting the records by values in the specified fields
   *      records = useRecords(table, {sorts: [
   *         // sort by 'My field' in ascending order...
   *         {field: 'My field'},
   *         // then by 'My other field' in descending order
   *         {field: 'My other field', direction: 'desc'},
   *      ]});
   *  }
   * ```
   *
   * @example
   * ```js
   *  import {useRecords, useBase} from '@airtable/blocks/ui';
   *
   *  function RecordList() {
   *      const base = useBase();
   *      const table = base.tables[0];
   *
   *      // grab all the records from that table
   *      const records = useRecords(table);
   *
   *      // render a list of records:
   *      return (
   *          <ul>
   *              {records.map(record => {
   *                  return <li key={record.id}>{record.name}</li>
   *              })}
   *          </ul>
   *      );
   *  }
   * ```
   * @docsPath UI/hooks/useRecords
   * @hook
   */
  function useRecords(tableOrViewOrQueryResult, opts) {
    var queryResult = _useUnwatchedRecordQueryResult(tableOrViewOrQueryResult, "useRecords", opts); // Note this differs from useRecordQueryResult in that it does not watch groups

    (0, _use_watchable.default)(queryResult, ["records", "cellValues", "recordColors"]);
    return queryResult ? queryResult.records : null;
  }
  /** */

  /**
   * A hook for working with a single record. Automatically handles loading data and updating your
   * component when the record's cell values or color changes.
   *
   * Often used with {@link useRecordIds} to render a list of records where each list item only
   * updates when the specific record it concerns changes.
   *
   * Under the hood, this hook creates a {@link TableOrViewQueryResult} if passed a table or view.
   * Pass a query result if you want direct access to it (e.g. for `queryResult.getRecordById`).
   *
   * Returns the specified record, or null if no model was passed in, or no record with that ID exists
   * in the model.
   *
   * @param tableOrViewOrQueryResult The {@link Table}, {@link View} or {@link RecordQueryResult} you want a record from.
   * @param recordId The ID of the record you want.
   * @param opts? If passing a Table or View, optional {@link SingleRecordQueryResultOpts} to control the results.
   * @example
   * ```js
   *  import {useRecordById, useRecordIds, useBase} from '@airtable/blocks/ui';
   *
   *  // this component concerns a single record - it only updates when that specific record updates
   *  function RecordListItem({table, recordId}) {
   *      const record = useRecordById(table, recordId);
   *      return <li>{record.name}</li>;
   *  }
   *
   *  // this component renders a list of records, but doesn't update when their cell values change -
   *  // that's left up to RecordListItem
   *  function RecordList() {
   *      const base = useBase();
   *      const table = base.tables[0];
   *
   *      // grab all the record ids from the table
   *      const recordIds = useRecordIds(table);
   *
   *      // render a list of records:
   *      return (
   *          <ul>
   *              {recordIds.map(recordId => {
   *                  return <RecordListItem key={recordId} recordId={recordId} table={table} />
   *              })}
   *          </ul>
   *      );
   *  }
   * ```
   * @docsPath UI/hooks/useRecordById
   * @hook
   */
  function useRecordById(tableOrViewOrQueryResult, recordId, opts) {
    var queryResult = _useUnwatchedRecordQueryResult(tableOrViewOrQueryResult, "useRecordById", opts);
    (0, _use_watchable.default)(queryResult, ["records", "recordColors"]);
    var record = queryResult ? queryResult.getRecordByIdIfExists(recordId) : null;
    (0, _use_watchable.default)(record, ["cellValues"]);
    return record;
  }
  /**
   * Docs: TODO(SeanKeenan)
   *
   * @docsPath UI/hooks/useRecordQueryResult
   * @hidden
   * @hook
   */

  function useRecordQueryResult(tableOrViewOrQueryResult, opts) {
    var queryResult = _useUnwatchedRecordQueryResult(tableOrViewOrQueryResult, "useRecordQueryResult", opts);
    (0, _use_watchable.default)(queryResult, ["records", "cellValues", "recordColors", "groups"]);
    return queryResult;
  }
  return exports;
}