import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.includes";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _react3 from "react";
var _react2 = _react3;
try {
  if ("default" in _react3) _react2 = _react3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _system2Dew } from "./system";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
import { dew as _use_theme2Dew } from "./theme/use_theme";
import { dew as _control_sizes2Dew } from "./control_sizes";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esArray3;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.sharedSwitchPropTypes = exports.switchStylePropTypes = void 0;
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var _react = _interopRequireDefault(_react2);
  var _core = _core2;
  var _private_utils = _private_utils2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _system = _system2Dew();
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  var _use_theme = _interopRequireDefault(_use_theme2Dew());
  var _control_sizes = _control_sizes2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var SwitchVariant = (0, _private_utils.createEnum)("default", "danger");
  var switchVariantPropType = (0, _private_utils.createPropTypeFromEnum)(SwitchVariant);
  /** @internal */

  function useSwitchVariant() {
    var variant = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : SwitchVariant.default;
    var _useTheme = (0, _use_theme.default)(),
      switchVariants = _useTheme.switchVariants;
    return switchVariants[variant];
  }
  /**
   * Props shared between the {@link Switch} and {@link SwitchSynced} components.
   *
   * @noInheritDoc
   */

  var switchStylePropTypes = _objectSpread({}, _system.maxWidthPropTypes, {}, _system.minWidthPropTypes, {}, _system.widthPropTypes, {}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.spacingSetPropTypes, {}, _system.backgroundColorPropTypes, {}, _system.displayPropTypes);
  exports.switchStylePropTypes = switchStylePropTypes;
  var sharedSwitchPropTypes = _objectSpread({
    disabled: _propTypes.default.bool,
    id: _propTypes.default.string,
    label: _propTypes.default.node,
    onChange: _propTypes.default.func,
    tabIndex: _propTypes.default.number,
    variant: switchVariantPropType,
    className: _propTypes.default.string,
    style: _propTypes.default.object,
    "aria-label": _propTypes.default.string,
    "aria-labelledby": _propTypes.default.string,
    "aria-describedby": _propTypes.default.string
  }, _tooltip_anchor_props.tooltipAnchorPropTypes, {}, switchStylePropTypes);
  /**
   * Style props for the {@link Switch} component. Also accepts:
   * * {@link BackgroundColorProps}
   * * {@link FlexItemSetProps}
   * * {@link MinWidthProps}
   * * {@link MaxWidthProps}
   * * {@link PositionSetProps}
   * * {@link SpacingSetProps}
   * * {@link WidthProps}
   *
   * @noInheritDoc
   */

  exports.sharedSwitchPropTypes = sharedSwitchPropTypes;
  var styleParser = (0, _core.compose)(_system.maxWidth, _system.minWidth, _system.width, _system.flexItemSet, _system.positionSet, _system.spacingSet, _system.display, _system.backgroundColor);
  /**
   * A toggle switch for controlling boolean values. Similar to a checkbox.
   *
   * [[ Story id="switch--example" title="Switch example" ]]
   *
   * By default, the Switch component is styled to be full-width (`width="100%"`) with a gray
   * background to match other controls like Select, SelectButtons, Input, etc. This behavior can be
   * overridden using style props. For example, you can set `backgroundColor="transparent"` on the
   * Switch for a transparent background.
   *
   * @docsPath UI/components/Switch
   * @component
   */

  var Switch = (props, ref) => {
    var disabled = props.disabled,
      id = props.id,
      label = props.label,
      _props$tabIndex = props.tabIndex,
      tabIndex = _props$tabIndex === void 0 ? 0 : _props$tabIndex,
      _props$variant = props.variant,
      variant = _props$variant === void 0 ? SwitchVariant.default : _props$variant,
      _props$size = props.size,
      size = _props$size === void 0 ? _control_sizes.ControlSize.default : _props$size,
      value = props.value,
      onClick = props.onClick,
      onChange = props.onChange,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      className = props.className,
      style = props.style,
      ariaLabel = props["aria-label"],
      ariaDescribedBy = props["aria-describedby"],
      ariaLabelledBy = props["aria-labelledby"],
      styleProps = (0, _objectWithoutProperties2.default)(props, ["disabled", "id", "label", "tabIndex", "variant", "size", "value", "onClick", "onChange", "onMouseEnter", "onMouseLeave", "className", "style", "aria-label", "aria-describedby", "aria-labelledby"]);
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({
      display: "flex",
      width: "100%"
    }, styleProps), styleParser);
    var _useSwitchVariant = useSwitchVariant(variant),
      classNameForVariant = _useSwitchVariant.baseClassName,
      switchContainerClassName = _useSwitchVariant.switchContainerClassName,
      switchClassName = _useSwitchVariant.switchClassName,
      switchLabelClassName = _useSwitchVariant.switchLabelClassName;
    var classNameForSize = (0, _control_sizes.useSwitchSize)(size);
    if (!label && !ariaLabelledBy && !ariaLabel) {
      // eslint-disable-next-line no-console
      console.warn("<Switch> should be labeled using either the `label`, `ariaLabel`, or `ariaLabelledBy` prop");
    }
    function _onClick(e) {
      // onClick should only be defined in the case of a tooltip
      if (onClick) {
        onClick(e);
      }
      _toggleValue();
    }
    function _onKeyDown(e) {
      if (e.ctrlKey || e.altKey || e.metaKey) {
        return;
      }
      if (["Enter", " "].includes(e.key)) {
        e.preventDefault();
        _toggleValue();
      }
    }
    function _toggleValue() {
      if (onChange) {
        onChange(!value);
      }
    }
    return _react.default.createElement("div", {
      ref: ref // TODO (stephen): remove tooltip anchor props
      ,

      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onClick: disabled ? undefined : _onClick,
      onKeyDown: disabled ? undefined : _onKeyDown,
      id: id,
      className: (0, _emotion.cx)(classNameForVariant, classNameForSize, classNameForStyleProps, className),
      style: style,
      tabIndex: disabled ? undefined : tabIndex,
      role: "checkbox",
      "aria-disabled": disabled,
      "aria-checked": !!value,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy
    }, _react.default.createElement("div", {
      className: switchContainerClassName
    }, _react.default.createElement("div", {
      className: switchClassName
    })), label && _react.default.createElement("label", {
      className: switchLabelClassName
    }, label));
  };
  var ForwardedRefSwitch = _react.default.forwardRef(Switch);
  ForwardedRefSwitch.propTypes = _objectSpread({
    value: _propTypes.default.bool.isRequired
  }, sharedSwitchPropTypes);
  ForwardedRefSwitch.displayName = "Switch";
  var _default = ForwardedRefSwitch;
  exports.default = _default;
  return exports;
}