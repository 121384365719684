import * as _esNumber2 from "core-js/modules/es.number.constructor";
var _esNumber = _esNumber2;
try {
  if ("default" in _esNumber2) _esNumber = _esNumber2.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../../../error_utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  _esNumber;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = ensureNumbersAreWithinScale;
  var _core = _core2;
  var _error_utils = _error_utils2Dew();

  /** @internal */
  function isNumber(n) {
    return typeof n === "number" && !isNaN(n);
  }
  /** @internal */

  function ensureNumbersAreWithinScale(params) {
    return (value, scale) => {
      if (!Array.isArray(scale)) {
        throw (0, _error_utils.spawnError)("The scale for \"%s\" should be an Array", params.propertyName);
      }
      if (!isNumber(value)) {
        // Not a number, so simply return the value.
        // We still use `get` if we are dealing with responsive values.
        return (0, _core.get)(scale, value, value);
      }
      if (!Number.isInteger(value)) {
        throw (0, _error_utils.spawnError)("The number you passed for \"%s\" is not supported. To use a pixel value, pass the string \"%spx\" instead.", params.propertyName, value);
      }
      var isValueNegative = value < 0;
      if (isValueNegative && !params.shouldAllowNegativeNumbers) {
        throw (0, _error_utils.spawnError)("The scale for the \"%s\" property does not support negative values. To use a pixel value, pass the string \"%spx\" instead.", params.propertyName, value);
      }
      var absoluteValue = Math.abs(value);
      if (absoluteValue >= scale.length) {
        throw (0, _error_utils.spawnError)("The number you passed for \"%s\" falls out the scale. To use a pixel value, pass the string \"%spx\" instead.", params.propertyName, value);
      }
      var valueFromScale = Number(scale[absoluteValue]);
      return valueFromScale * (isValueNegative ? -1 : 1);
    };
  }
  return exports;
}