import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.map";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.to-string";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.promise";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _regenerator3 from "@babel/runtime/regenerator";
var _regenerator2 = _regenerator3;
try {
  if ("default" in _regenerator3) _regenerator2 = _regenerator3.default;
} catch (e) {}
import * as _runtime2 from "regenerator-runtime/runtime";
var _runtime = _runtime2;
try {
  if ("default" in _runtime2) _runtime = _runtime2.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _esArray;
  _esObject;
  _es;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _regenerator = _interopRequireDefault(_regenerator2);
  _runtime;
  var _error_utils = _error_utils2Dew();

  /** @module @airtable/blocks/ui: expandRecordPickerAsync */

  /** */

  /**
   * Expands a list of records in the Airtable UI, and prompts the user to pick
   * one. The selected record is returned to the extension, and the modal is
   * automatically closed.
   *
   * If the user dismisses the modal, or another one is opened before this one
   * has been closed, it will return null.
   *
   * Returns a promise that resolves to the record chosen by the user, or null.
   *
   * @param records the records the user can pick from. Duplicate records will be removed.
   * @param opts An optional options object.
   * @example
   * ```js
   * import {expandRecordPickerAsync} from '@airtable/blocks/ui';
   *
   * async function pickRecordsAsync() {
   *     const recordA = await expandRecordPickerAsync([record1, record2, record3]);
   *     if (recordA !== null) {
   *         alert(recordA.name);
   *     } else {
   *         alert('no record picked');
   *     }
   *
   *     const recordB = await expandRecordPickerAsync([record1, record2], {
   *         fields: [field1, field2],
   *     });
   * }
   * ```
   * @docsPath UI/utils/expandRecordPickerAsync
   */
  function expandRecordPickerAsync(records, opts) {
    var tableId, sdk, recordIds, fieldIds, shouldAllowCreatingRecord, chosenRecordId, table;
    return _regenerator.default.async(function expandRecordPickerAsync$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            if (!(records.length === 0)) {
              _context.next = 2;
              break;
            }
            return _context.abrupt("return", null);
          case 2:
            tableId = records[0].parentTable.id;
            sdk = records[0].parentTable.parentBase.__sdk;
            recordIds = records.map(record => {
              (0, _error_utils.invariant)(record.parentTable.id === tableId, "all records must belong to the same table");
              return record.id;
            });
            fieldIds = opts && opts.fields ? opts.fields.map(field => {
              (0, _error_utils.invariant)(field.parentTable.id === tableId, "all fields must belong to the same table");
              return field.id;
            }) : null;
            shouldAllowCreatingRecord = !!opts && !!opts.shouldAllowCreatingRecord;
            _context.next = 9;
            return _regenerator.default.awrap(sdk.__airtableInterface.expandRecordPickerAsync(tableId, recordIds, fieldIds, shouldAllowCreatingRecord));
          case 9:
            chosenRecordId = _context.sent;
            if (chosenRecordId) {
              _context.next = 12;
              break;
            }
            return _context.abrupt("return", null);
          case 12:
            table = sdk.base.getTableByIdIfExists(tableId);
            if (table) {
              _context.next = 15;
              break;
            }
            return _context.abrupt("return", null);
          case 15:
            return _context.abrupt("return", sdk.base.__getRecordStore(table.id).getRecordByIdIfExists(chosenRecordId));
          case 16:
          case "end":
            return _context.stop();
        }
      }
    });
  }
  var _default = expandRecordPickerAsync;
  exports.default = _default;
  return exports;
}