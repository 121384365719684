import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _sdk_context = _sdk_context2Dew();

  /** @module @airtable/blocks/ui: useBase */

  /** */

  /**
   * A hook for connecting a React component to your base's schema. This returns a {@link Base}
   * instance and will re-render your component whenever the base's schema changes. That means any
   * change to your base like tables being added or removed, fields getting renamed, etc. It excludes
   * any change to the actual records in the base.
   *
   * `useBase` should meet most of your needs for working with base schema. If you need more granular
   * control of when your component updates or want to do anything other than re-render, the lower
   * level {@link useWatchable} hook might help.
   *
   * Returns the current base.
   *
   * @example
   * ```js
   * import {useBase} from '@airtable/blocks/ui';
   *
   * // renders a list of tables and automatically updates
   * function TableList() {
   *      const base = useBase();
   *
   *      const tables = base.tables.map(table => {
   *          return <li key={table.id}>{table.name}</li>;
   *      });
   *
   *      return <ul>{tables}</ul>;
   * }
   * ```
   * @docsPath UI/hooks/useBase
   * @hook
   */
  var useBase = () => {
    var _useSdk = (0, _sdk_context.useSdk)(),
      base = _useSdk.base,
      session = _useSdk.session;
    (0, _use_watchable.default)(base, ["schema"]);
    (0, _use_watchable.default)(session, ["permissionLevel"]);
    return base;
  };
  var _default = useBase;
  exports.default = _default;
  return exports;
}