var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.__injectSdkIntoWarning = __injectSdkIntoWarning;
  exports.default = void 0;
  var usedWarnings = {};
  var _default = msgLines => {
    var msg = typeof msgLines === "string" ? msgLines : msgLines.join("\n");
    var mayUseConsole = !sdk || sdk.runInfo.isDevelopmentMode;
    if (mayUseConsole && usedWarnings[msg] !== true) {
      usedWarnings[msg] = true; // eslint-disable-next-line no-console

      console.warn("[@airtable/blocks] ".concat(msg));
    }
  };
  exports.default = _default;
  var sdk; // The application-level Sdk instance must be injected dynamically to avoid
  // circular dependencies at the time of module resolution.

  function __injectSdkIntoWarning(_sdk) {
    sdk = _sdk;
  }
  return exports;
}