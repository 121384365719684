import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.concat";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.filter";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _react3 from "react";
var _react2 = _react3;
try {
  if ("default" in _react3) _react2 = _react3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import { dew as _warning2Dew } from "../warning";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _system2Dew } from "./system";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
import { dew as _icon_config2Dew } from "./icon_config";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esArray3;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.sharedIconPropTypes = exports.iconStylePropTypes = void 0;
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _react = _interopRequireDefault(_react2);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _core = _core2;
  var _emotion = _emotion2;
  var _warning = _interopRequireDefault(_warning2Dew());
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _system = _system2Dew();
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  var _icon_config = _icon_config2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var styleParser = (0, _core.compose)(_system.flexItemSet, _system.positionSet, _system.margin, _system.width, _system.height);
  var iconStylePropTypes = _objectSpread({}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.marginPropTypes);
  /**
   * Props shared between the {@link Icon} and {@link FieldIcon} components.
   *
   * @noInheritDoc
   */
  // TODO (stephen): inherit shared props without inheriting style props

  exports.iconStylePropTypes = iconStylePropTypes;
  var sharedIconPropTypes = _objectSpread({
    size: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string]),
    fillColor: _propTypes.default.string,
    className: _propTypes.default.string,
    style: _propTypes.default.object,
    pathClassName: _propTypes.default.string,
    pathStyle: _propTypes.default.object
  }, _tooltip_anchor_props.tooltipAnchorPropTypes, {}, iconStylePropTypes);
  /**
   * Props for the {@link Icon} component. Also accepts:
   * * {@link IconStyleProps}
   */

  exports.sharedIconPropTypes = sharedIconPropTypes;

  /**
   * A vector icon from the Airtable icon set.
   *
   * [[ Story id="icon--example" title="Icon example" height="576px"]]
   *
   * @component
   * @docsPath UI/components/Icon
   */
  var Icon = (props, ref) => {
    var name = props.name,
      _props$size = props.size,
      size = _props$size === void 0 ? 16 : _props$size,
      _props$fillColor = props.fillColor,
      fillColor = _props$fillColor === void 0 ? "currentColor" : _props$fillColor,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onClick = props.onClick,
      hasOnClick = props.hasOnClick,
      className = props.className,
      style = props.style,
      pathClassName = props.pathClassName,
      pathStyle = props.pathStyle,
      _props$suppressWarnin = props.suppressWarning,
      suppressWarning = _props$suppressWarnin === void 0 ? false : _props$suppressWarnin,
      styleProps = (0, _objectWithoutProperties2.default)(props, ["name", "size", "fillColor", "onMouseEnter", "onMouseLeave", "onClick", "hasOnClick", "className", "style", "pathClassName", "pathStyle", "suppressWarning"]);
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({}, styleProps, {
      width: size,
      height: size
    }), styleParser);
    var phosphorIconName = _icon_config.legacyIconNameToPhosphorIconName[name];
    var pathData = phosphorIconName ? _icon_config.phosphorIconConfig[phosphorIconName] : null;
    if (!pathData) {
      return null;
    }
    if (_icon_config.deprecatedIconNameToReplacementName.has(name) && !suppressWarning) {
      var alternative = _icon_config.deprecatedIconNameToReplacementName.get(name);
      var alternativeText = "";
      if (alternative) {
        alternativeText = "Use <Icon name='".concat(alternative, "' .../> instead.");
      }
      (0, _warning.default)("'".concat(name, "' as an icon name is deprecated. ").concat(alternativeText));
    }
    return _react.default.createElement("svg", {
      ref: ref,
      viewBox: "0 0 16 16" // TODO (stephen): remove tooltip anchor props
      ,

      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onClick: onClick,
      className: (0, _emotion.cx)(classNameForStyleProps, className),
      style: _objectSpread({
        shapeRendering: "geometricPrecision"
      }, style)
    }, _react.default.createElement("path", {
      fillRule: "evenodd",
      className: pathClassName,
      style: pathStyle,
      fill: fillColor,
      d: pathData
    }));
  };
  var ForwardedRefIcon = _react.default.forwardRef(Icon);
  ForwardedRefIcon.propTypes = _objectSpread({
    name: _icon_config.iconNamePropType.isRequired,
    suppressWarning: _propTypes.default.bool
  }, sharedIconPropTypes);
  ForwardedRefIcon.displayName = "Icon";
  var _default = ForwardedRefIcon;
  exports.default = _default;
  return exports;
}