import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _slicedToArray3 from "@babel/runtime/helpers/slicedToArray";
var _slicedToArray = _slicedToArray3;
try {
  if ("default" in _slicedToArray3) _slicedToArray = _slicedToArray3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import { dew as _switch2Dew } from "./switch";
import { dew as _use_synced2Dew } from "./use_synced";
import { dew as _global_config_synced_component_helpers2Dew } from "./global_config_synced_component_helpers";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _extends2 = _interopRequireDefault(_extends);
  var _slicedToArray2 = _interopRequireDefault(_slicedToArray);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var React = _interopRequireWildcard(_react);
  var _switch = _interopRequireWildcard(_switch2Dew());
  var _use_synced = _interopRequireDefault(_use_synced2Dew());
  var _global_config_synced_component_helpers = _interopRequireDefault(_global_config_synced_component_helpers2Dew());
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }

  /**
   * A toggleable switch for controlling boolean values, synced with {@link GlobalConfig}. Similar to a checkbox.
   *
   * [[ Story id="switch--example-synced" title="Synced switch example" ]]
   *
   * @docsPath UI/components/SwitchSynced
   * @groupPath UI/components/Switch
   * @component
   */
  var SwitchSynced = (props, ref) => {
    var disabled = props.disabled,
      globalConfigKey = props.globalConfigKey,
      _onChange = props.onChange,
      restOfProps = (0, _objectWithoutProperties2.default)(props, ["disabled", "globalConfigKey", "onChange"]);
    var _useSynced = (0, _use_synced.default)(globalConfigKey),
      _useSynced2 = (0, _slicedToArray2.default)(_useSynced, 3),
      value = _useSynced2[0],
      setValue = _useSynced2[1],
      canSetValue = _useSynced2[2];
    return React.createElement(_switch.default, (0, _extends2.default)({}, restOfProps, {
      ref: ref,
      value: !!value,
      onChange: newValue => {
        setValue(newValue);
        if (_onChange) {
          _onChange(newValue);
        }
      },
      disabled: disabled || !canSetValue
    }));
  };
  var ForwardedRefSwitchSynced = React.forwardRef(SwitchSynced);
  ForwardedRefSwitchSynced.propTypes = _objectSpread({
    globalConfigKey: _global_config_synced_component_helpers.default.globalConfigKeyPropType
  }, _switch.sharedSwitchPropTypes);
  ForwardedRefSwitchSynced.displayName = "SwitchSynced";
  var _default = ForwardedRefSwitchSynced;
  exports.default = _default;
  return exports;
}