import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _use_theme2Dew } from "./theme/use_theme";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = useTextColorForBackgroundColor;
  var _use_theme = _interopRequireDefault(_use_theme2Dew());

  /** @internal */
  function useTextColorForBackgroundColor(backgroundColor) {
    var _useTheme = (0, _use_theme.default)(),
      textColorsByBackgroundColor = _useTheme.textColorsByBackgroundColor;
    return textColorsByBackgroundColor[backgroundColor];
  }
  return exports;
}