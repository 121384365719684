import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.concat";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.filter";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esArray6 from "core-js/modules/es.array.iterator";
var _esArray5 = _esArray6;
try {
  if ("default" in _esArray6) _esArray5 = _esArray6.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _esObject4 from "core-js/modules/es.object.to-string";
var _esObject3 = _esObject4;
try {
  if ("default" in _esObject4) _esObject3 = _esObject4.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _webDomCollections4 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections3 = _webDomCollections4;
try {
  if ("default" in _webDomCollections4) _webDomCollections3 = _webDomCollections4.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _index2Dew } from "./system/index";
import { dew as _get_style_props_for_responsive_prop2Dew } from "./system/utils/get_style_props_for_responsive_prop";
import { dew as _use_theme2Dew } from "./theme/use_theme";
import { dew as _aria_props2Dew } from "./types/aria_props";
import { dew as _data_attributes_prop2Dew } from "./types/data_attributes_prop";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esSymbol;
  _esArray;
  _esArray3;
  _esArray5;
  _esObject;
  _esObject3;
  _webDomCollections;
  _webDomCollections3;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _extends2 = _interopRequireDefault(_extends);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var React = _interopRequireWildcard(_react);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var _error_utils = _error_utils2Dew();
  var _private_utils = _private_utils2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _index = _index2Dew();
  var _get_style_props_for_responsive_prop = _interopRequireDefault(_get_style_props_for_responsive_prop2Dew());
  var _use_theme = _interopRequireDefault(_use_theme2Dew());
  var _aria_props = _aria_props2Dew();
  var _data_attributes_prop = _data_attributes_prop2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var HeadingSize = (0, _private_utils.createEnum)("xsmall", "small", "default", "large", "xlarge", "xxlarge");
  /**
   * Size prop for the {@link Heading} component.
   */

  var headingSizePropType = (0, _private_utils.createResponsivePropTypeFromEnum)(HeadingSize);
  /**
   * Variant prop for the {@link Heading} component.
   * • **default** - Headings typically used for titles.
   * • **caps** - All-caps headings typically used for field names and smaller section headings.
   */

  var HeadingVariant = (0, _private_utils.createEnum)("default", "caps");
  var headingVariantPropType = (0, _private_utils.createPropTypeFromEnum)(HeadingVariant);
  /** @internal */

  function warnIfHeadingSizeOutOfRangeForVariant(size, variant, headingSizesForVariant) {}
  /** @internal */

  function useHeadingStyle(headingSizeProp, variant) {
    var _useTheme = (0, _use_theme.default)(),
      headingStyles = _useTheme.headingStyles;
    var headingSizesForVariant = headingStyles[variant];
    var styleProps;
    if (typeof headingSizeProp === "string") {
      warnIfHeadingSizeOutOfRangeForVariant(headingSizeProp, variant, headingSizesForVariant);
      styleProps = headingSizesForVariant[headingSizeProp] || headingSizesForVariant[HeadingSize.default];
    } else {
      var responsiveSizePropObject = {};
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;
      try {
        for (var _iterator = (0, _private_utils.keys)(headingSizeProp)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var sizeKey = _step.value;
          var sizeProp = headingSizeProp[sizeKey];
          (0, _error_utils.invariant)(sizeProp, "sizeProp");
          warnIfHeadingSizeOutOfRangeForVariant(sizeProp, variant, headingSizesForVariant);
          responsiveSizePropObject[sizeKey] = sizeProp || HeadingSize.default;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
      styleProps = (0, _get_style_props_for_responsive_prop.default)(responsiveSizePropObject,
      // Cast to any to because `caps` don't all have the same sizes as `default`.
      // This in turn requires each size to be typed as optional.
      headingSizesForVariant);
    }
    return (0, _use_styled_system.default)(styleProps);
  }
  /**
   * Props for the {@link Heading} component. Also supports:
   * * {@link AllStylesProps}
   * * {@link AriaProps}
   *
   * @docsPath UI/components/Heading
   * @noInheritDoc
   */

  /**
   * A heading component with sizes and variants.
   *
   * [[ Story id="heading--example" title="Heading example" ]]
   *
   * @docsPath UI/components/Heading
   * @component
   */
  var Heading = (props, ref) => {
    var _props$as = props.as,
      Component = _props$as === void 0 ? "h3" : _props$as,
      _props$size = props.size,
      size = _props$size === void 0 ? HeadingSize.default : _props$size,
      _props$variant = props.variant,
      variant = _props$variant === void 0 ? HeadingVariant.default : _props$variant,
      children = props.children,
      id = props.id,
      role = props.role,
      dataAttributes = props.dataAttributes,
      className = props.className,
      style = props.style,
      ariaLabel = props["aria-label"],
      ariaLabelledBy = props["aria-labelledby"],
      ariaDescribedBy = props["aria-describedby"],
      ariaControls = props["aria-controls"],
      ariaExpanded = props["aria-expanded"],
      ariaHasPopup = props["aria-haspopup"],
      ariaHidden = props["aria-hidden"],
      ariaLive = props["aria-live"],
      styleProps = (0, _objectWithoutProperties2.default)(props, ["as", "size", "variant", "children", "id", "role", "dataAttributes", "className", "style", "aria-label", "aria-labelledby", "aria-describedby", "aria-controls", "aria-expanded", "aria-haspopup", "aria-hidden", "aria-live"]);
    var classNameForHeadingSize = useHeadingStyle(size, variant);
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({
      fontFamily: "default",
      textColor: "default"
    }, styleProps));
    return React.createElement(Component, (0, _extends2.default)({
      ref: ref,
      id: id,
      className: (0, _emotion.cx)(classNameForHeadingSize, classNameForStyleProps, className),
      style: style,
      role: role,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy,
      "aria-controls": ariaControls,
      "aria-expanded": ariaExpanded,
      "aria-haspopup": ariaHasPopup,
      "aria-hidden": ariaHidden,
      "aria-live": ariaLive
    }, dataAttributes), children);
  };
  var ForwardedRefHeading = React.forwardRef(Heading);
  ForwardedRefHeading.propTypes = _objectSpread({
    as: _propTypes.default.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
    size: headingSizePropType,
    variant: headingVariantPropType,
    children: _propTypes.default.node,
    id: _propTypes.default.string,
    role: _propTypes.default.string,
    dataAttributes: _data_attributes_prop.dataAttributesPropType,
    className: _propTypes.default.string,
    style: _propTypes.default.object
  }, _index.allStylesPropTypes, {}, _aria_props.ariaPropTypes);
  ForwardedRefHeading.displayName = "Heading";
  var _default = ForwardedRefHeading;
  exports.default = _default;
  return exports;
}