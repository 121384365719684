import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _box2Dew } from "./box";
import { dew as _text2Dew } from "./text";
import { dew as _baymax_utils2Dew } from "./baymax_utils";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _system2Dew } from "./system";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
import { dew as _use_base2Dew } from "./use_base";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.collaboratorTokenStylePropTypes = void 0;
  var _extends2 = _interopRequireDefault(_extends);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var React = _interopRequireWildcard(_react);
  var _core = _core2;
  var _box = _interopRequireDefault(_box2Dew());
  var _text = _interopRequireDefault(_text2Dew());
  var _baymax_utils = _baymax_utils2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _system = _system2Dew();
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  var _use_base = _interopRequireDefault(_use_base2Dew());
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var UNKNOWN_PROFILE_PIC_URL = "https://static.airtable.com/images/userIcons/user_icon_unknown.png";
  /**
   * Style props for the {@link CollaboratorToken} component. Accepts:
   * * {@link FlexItemSetProps}
   * * {@link MarginProps}
   * * {@link PositionSetProps}
   *
   * @noInheritDoc
   */

  var styleParser = (0, _core.compose)(_system.flexItemSet, _system.positionSet, _system.margin);
  var collaboratorTokenStylePropTypes = _objectSpread({}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.marginPropTypes);
  /**
   * Props for the {@link CollaboratorToken} component. Also accepts:
   * * {@link CollaboratorTokenStyleProps}
   *
   * @noInheritDoc
   * @docsPath UI/components/CollaboratorToken
   */

  exports.collaboratorTokenStylePropTypes = collaboratorTokenStylePropTypes;

  /**
   * A version of `Collaborator` that doesn't connect to base. Not part of the public API.
   *
   * @hidden
   */
  var StaticCollaboratorToken = props => {
    var collaborator = props.collaborator,
      _props$isActive = props.isActive,
      isActive = _props$isActive === void 0 ? true : _props$isActive,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onClick = props.onClick,
      hasOnClick = props.hasOnClick,
      className = props.className,
      style = props.style,
      styleProps = (0, _objectWithoutProperties2.default)(props, ["collaborator", "isActive", "onMouseEnter", "onMouseLeave", "onClick", "hasOnClick", "className", "style"]);
    var classNameForStyledProps = (0, _use_styled_system.default)(styleProps, styleParser);
    var userName = collaborator.name || collaborator.email || "Unknown";
    var profilePicUrl = collaborator.profilePicUrl || UNKNOWN_PROFILE_PIC_URL;
    return React.createElement(_box.default // TODO (stephen): remove tooltip anchor props
    , {
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onClick: onClick,
      className: (0, _emotion.cx)((0, _baymax_utils.baymax)("truncate print-color-exact"), classNameForStyledProps, className),
      style: style,
      alignItems: "center",
      display: "inline-flex",
      opacity: isActive ? 1 : 0.5
    }, profilePicUrl && React.createElement(_box.default, {
      className: (0, _baymax_utils.baymax)("background-cover background-center"),
      style: {
        backgroundImage: "url(\"".concat(profilePicUrl, "\")")
      },
      border: "1px solid #eee",
      width: "22px",
      height: "22px",
      borderRadius: "circle",
      zIndex: 1,
      flex: "none",
      backgroundColor: "grayLight2"
    }), React.createElement(_text.default, {
      className: (0, _baymax_utils.baymax)("truncate"),
      paddingRight: 2,
      paddingLeft: 3,
      textColor: "dark",
      display: "inline-flex",
      borderRadius: "circle",
      alignItems: "center",
      flex: "none",
      backgroundColor: "grayLight2",
      marginLeft: "-12px",
      lineHeight: 1.4
    }, userName));
  };
  /**
   * A component that shows a single collaborator in a small token, to be displayed inline or in a list of choices.
   *
   * [[ Story id="collaboratortoken--example" title="Collaborator token example" ]]
   *
   * @component
   * @docsPath UI/components/CollaboratorToken
   */

  var CollaboratorToken = props => {
    // Re-render when collaborator info updates. This is to ensure isActive is accurate.
    var base = (0, _use_base.default)();
    var activeCollaborators = base.activeCollaborators;
    var isActive = activeCollaborators.some(activeCollaborator => {
      return activeCollaborator.id === props.collaborator.id;
    });
    return React.createElement(StaticCollaboratorToken, (0, _extends2.default)({}, props, {
      isActive: isActive
    }));
  };
  CollaboratorToken.propTypes = _objectSpread({
    collaborator: _propTypes.default.shape({
      id: _propTypes.default.string,
      email: _propTypes.default.string,
      name: _propTypes.default.string,
      profilePicUrl: _propTypes.default.string,
      status: _propTypes.default.string
    }).isRequired,
    className: _propTypes.default.string,
    style: _propTypes.default.object
  }, _tooltip_anchor_props.tooltipAnchorPropTypes, {}, collaboratorTokenStylePropTypes);
  CollaboratorToken.Static = StaticCollaboratorToken;
  var _default = CollaboratorToken;
  exports.default = _default;
  return exports;
}