import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _baymax_utils2Dew } from "./baymax_utils";
import { dew as _box2Dew } from "./box";
import { dew as _text2Dew } from "./text";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _use_text_color_for_background_color2Dew } from "./use_text_color_for_background_color";
import { dew as _system2Dew } from "./system";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.choiceTokenStylePropTypes = void 0;
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var React = _interopRequireWildcard(_react);
  var _core = _core2;
  var _baymax_utils = _baymax_utils2Dew();
  var _box = _interopRequireDefault(_box2Dew());
  var _text = _interopRequireDefault(_text2Dew());
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _use_text_color_for_background_color = _interopRequireDefault(_use_text_color_for_background_color2Dew());
  var _system = _system2Dew();
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var styleParser = (0, _core.compose)(_system.flexItemSet, _system.positionSet, _system.margin);
  var choiceTokenStylePropTypes = _objectSpread({}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.marginPropTypes);
  exports.choiceTokenStylePropTypes = choiceTokenStylePropTypes;
  var DEFAULT_CHOICE_COLOR = "gray";
  /** An option from a select field. You should not create these objects from scratch, but should instead grab them from base data. */

  /**
   * A component that shows a single choice in a small token, to be displayed inline or in a list of choices.
   *
   * [[ Story id="choicetoken--example" title="Choice token example" ]]
   *
   * @component
   * @docsPath UI/components/ChoiceToken
   */
  var ChoiceToken = props => {
    var choice = props.choice,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onClick = props.onClick,
      hasOnClick = props.hasOnClick,
      className = props.className,
      style = props.style,
      styleProps = (0, _objectWithoutProperties2.default)(props, ["choice", "onMouseEnter", "onMouseLeave", "onClick", "hasOnClick", "className", "style"]);
    var classNameForStyleProps = (0, _use_styled_system.default)(styleProps, styleParser);
    var color = choice.color || DEFAULT_CHOICE_COLOR;
    var textColor = (0, _use_text_color_for_background_color.default)(color);
    return React.createElement(_box.default, {
      className: (0, _emotion.cx)(className, classNameForStyleProps),
      style: style,
      display: "inline-block"
    }, React.createElement(_box.default // TODO (stephen): remove tooltip anchor props
    , {
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onClick: onClick,
      className: (0, _baymax_utils.baymax)("print-color-exact align-top border-box"),
      backgroundColor: color,
      minWidth: "18px",
      height: "18px",
      borderRadius: "circle",
      paddingX: 2
    }, React.createElement(_text.default, {
      className: (0, _baymax_utils.baymax)("truncate"),
      textColor: textColor,
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: 1.5
    }, choice.name)));
  };
  ChoiceToken.propTypes = _objectSpread({
    choice: _propTypes.default.shape({
      id: _propTypes.default.string,
      name: _propTypes.default.string.isRequired,
      color: _propTypes.default.string
    }).isRequired,
    style: _propTypes.default.object,
    className: _propTypes.default.string
  }, _tooltip_anchor_props.tooltipAnchorPropTypes, {}, choiceTokenStylePropTypes);
  var _default = ChoiceToken;
  exports.default = _default;
  return exports;
}