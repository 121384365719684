import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.map";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esString2 from "core-js/modules/es.string.split";
var _esString = _esString2;
try {
  if ("default" in _esString2) _esString = _esString2.default;
} catch (e) {}
import * as _taggedTemplateLiteral3 from "@babel/runtime/helpers/taggedTemplateLiteral";
var _taggedTemplateLiteral = _taggedTemplateLiteral3;
try {
  if ("default" in _taggedTemplateLiteral3) _taggedTemplateLiteral = _taggedTemplateLiteral3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _error_utils2Dew } from "../error_utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _esArray;
  _esArray3;
  _esString;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.baymax = baymax;
  var _taggedTemplateLiteral2 = _interopRequireDefault(_taggedTemplateLiteral);
  var _emotion = _emotion2;
  var _private_utils = _private_utils2Dew();
  var _error_utils = _error_utils2Dew();
  function _templateObject99() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: rgb(252, 180, 0);\n    "]);
    _templateObject99 = function _templateObject99() {
      return data;
    };
    return data;
  }
  function _templateObject98() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        width: 100%;\n    "]);
    _templateObject98 = function _templateObject98() {
      return data;
    };
    return data;
  }
  function _templateObject97() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: hsl(0, 0%, 100%);\n    "]);
    _templateObject97 = function _templateObject97() {
      return data;
    };
    return data;
  }
  function _templateObject96() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        padding-bottom: 0.14rem;\n        border-bottom: 2px solid hsla(0, 0%, 0%, 0.1);\n    "]);
    _templateObject96 = function _templateObject96() {
      return data;
    };
    return data;
  }
  function _templateObject95() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        max-width: 100%;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n    "]);
    _templateObject95 = function _templateObject95() {
      return data;
    };
    return data;
  }
  function _templateObject94() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        top: 0;\n    "]);
    _templateObject94 = function _templateObject94() {
      return data;
    };
    return data;
  }
  function _templateObject93() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        color: hsl(0, 0%, 100%);\n        fill: hsl(0, 0%, 100%);\n    "]);
    _templateObject93 = function _templateObject93() {
      return data;
    };
    return data;
  }
  function _templateObject92() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        color: hsl(0, 0%, 20%);\n        fill: hsl(0, 0%, 20%);\n    "]);
    _templateObject92 = function _templateObject92() {
      return data;
    };
    return data;
  }
  function _templateObject91() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        &:focus {\n            color: rgb(45, 127, 249);\n            fill: rgb(45, 127, 249);\n        }\n    "]);
    _templateObject91 = function _templateObject91() {
      return data;
    };
    return data;
  }
  function _templateObject90() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        color: rgb(45, 127, 249);\n        fill: rgb(45, 127, 249);\n    "]);
    _templateObject90 = function _templateObject90() {
      return data;
    };
    return data;
  }
  function _templateObject89() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-appearance: none;\n        -moz-appearance: none;\n        appearance: none;\n        border-style: solid;\n        border-width: 2px;\n        border-color: transparent;\n        outline: none;\n        &:active {\n            border-color: hsla(0, 0%, 0%, 0.25);\n        }\n        &:focus {\n            border-color: hsla(0, 0%, 0%, 0.25);\n        }\n    "]);
    _templateObject89 = function _templateObject89() {
      return data;
    };
    return data;
  }
  function _templateObject88() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        font-weight: 500;\n    "]);
    _templateObject88 = function _templateObject88() {
      return data;
    };
    return data;
  }
  function _templateObject87() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);\n    "]);
    _templateObject87 = function _templateObject87() {
      return data;
    };
    return data;
  }
  function _templateObject86() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        font-size: 11px;\n    "]);
    _templateObject86 = function _templateObject86() {
      return data;
    };
    return data;
  }
  function _templateObject85() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-align-self: flex-end;\n        -ms-flex-item-align: end;\n        align-self: flex-end;\n    "]);
    _templateObject85 = function _templateObject85() {
      return data;
    };
    return data;
  }
  function _templateObject84() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        border-radius: 6px;\n    "]);
    _templateObject84 = function _templateObject84() {
      return data;
    };
    return data;
  }
  function _templateObject83() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        border-radius: 3px;\n    "]);
    _templateObject83 = function _templateObject83() {
      return data;
    };
    return data;
  }
  function _templateObject82() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        right: 0;\n    "]);
    _templateObject82 = function _templateObject82() {
      return data;
    };
    return data;
  }
  function _templateObject81() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        position: relative;\n    "]);
    _templateObject81 = function _templateObject81() {
      return data;
    };
    return data;
  }
  function _templateObject80() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: rgb(248, 43, 96);\n    "]);
    _templateObject80 = function _templateObject80() {
      return data;
    };
    return data;
  }
  function _templateObject79() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        opacity: 0.5;\n    "]);
    _templateObject79 = function _templateObject79() {
      return data;
    };
    return data;
  }
  function _templateObject78() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        opacity: 0.75;\n    "]);
    _templateObject78 = function _templateObject78() {
      return data;
    };
    return data;
  }
  function _templateObject77() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        padding-left: 0.5rem;\n        padding-right: 0.5rem;\n    "]);
    _templateObject77 = function _templateObject77() {
      return data;
    };
    return data;
  }
  function _templateObject76() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-print-color-adjust: exact;\n    "]);
    _templateObject76 = function _templateObject76() {
      return data;
    };
    return data;
  }
  function _templateObject75() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        padding-right: 0.5rem;\n    "]);
    _templateObject75 = function _templateObject75() {
      return data;
    };
    return data;
  }
  function _templateObject74() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        cursor: pointer;\n    "]);
    _templateObject74 = function _templateObject74() {
      return data;
    };
    return data;
  }
  function _templateObject73() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        border-radius: 9999px;\n    "]);
    _templateObject73 = function _templateObject73() {
      return data;
    };
    return data;
  }
  function _templateObject72() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        padding: 1rem;\n    "]);
    _templateObject72 = function _templateObject72() {
      return data;
    };
    return data;
  }
  function _templateObject71() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        padding: 0.5rem;\n    "]);
    _templateObject71 = function _templateObject71() {
      return data;
    };
    return data;
  }
  function _templateObject70() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        padding: 0.25rem;\n    "]);
    _templateObject70 = function _templateObject70() {
      return data;
    };
    return data;
  }
  function _templateObject69() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        overflow: hidden;\n    "]);
    _templateObject69 = function _templateObject69() {
      return data;
    };
    return data;
  }
  function _templateObject68() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        overflow: auto;\n    "]);
    _templateObject68 = function _templateObject68() {
      return data;
    };
    return data;
  }
  function _templateObject67() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        white-space: nowrap;\n    "]);
    _templateObject67 = function _templateObject67() {
      return data;
    };
    return data;
  }
  function _templateObject66() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        font-size: 0.8rem;\n    "]);
    _templateObject66 = function _templateObject66() {
      return data;
    };
    return data;
  }
  function _templateObject65() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        pointer-events: none;\n    "]);
    _templateObject65 = function _templateObject65() {
      return data;
    };
    return data;
  }
  function _templateObject64() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        user-select: none;\n        -webkit-user-select: none;\n    "]);
    _templateObject64 = function _templateObject64() {
      return data;
    };
    return data;
  }
  function _templateObject63() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        outline: 0;\n    "]);
    _templateObject63 = function _templateObject63() {
      return data;
    };
    return data;
  }
  function _templateObject62() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        margin-top: 1rem;\n    "]);
    _templateObject62 = function _templateObject62() {
      return data;
    };
    return data;
  }
  function _templateObject61() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        margin-top: 0.5rem;\n    "]);
    _templateObject61 = function _templateObject61() {
      return data;
    };
    return data;
  }
  function _templateObject60() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        margin-top: 0;\n    "]);
    _templateObject60 = function _templateObject60() {
      return data;
    };
    return data;
  }
  function _templateObject59() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        margin-right: 0.5rem;\n    "]);
    _templateObject59 = function _templateObject59() {
      return data;
    };
    return data;
  }
  function _templateObject58() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        margin-right: 0.25rem;\n    "]);
    _templateObject58 = function _templateObject58() {
      return data;
    };
    return data;
  }
  function _templateObject57() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        margin-left: 0.5rem;\n    "]);
    _templateObject57 = function _templateObject57() {
      return data;
    };
    return data;
  }
  function _templateObject56() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        margin-bottom: 0.5rem;\n    "]);
    _templateObject56 = function _templateObject56() {
      return data;
    };
    return data;
  }
  function _templateObject55() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        margin: 1rem;\n    "]);
    _templateObject55 = function _templateObject55() {
      return data;
    };
    return data;
  }
  function _templateObject54() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        margin: 0;\n    "]);
    _templateObject54 = function _templateObject54() {
      return data;
    };
    return data;
  }
  function _templateObject53() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        &:hover {\n            opacity: 1;\n        }\n        &:focus {\n            opacity: 1;\n        }\n    "]);
    _templateObject53 = function _templateObject53() {
      return data;
    };
    return data;
  }
  function _templateObject52() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        &:hover {\n            opacity: 1;\n        }\n    "]);
    _templateObject52 = function _templateObject52() {
      return data;
    };
    return data;
  }
  function _templateObject51() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        &:hover {\n            opacity: 0.85;\n        }\n    "]);
    _templateObject51 = function _templateObject51() {
      return data;
    };
    return data;
  }
  function _templateObject50() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        line-height: 1.5;\n    "]);
    _templateObject50 = function _templateObject50() {
      return data;
    };
    return data;
  }
  function _templateObject49() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        &::-webkit-scrollbar {\n            width: 12px;\n            height: 12px;\n            position: absolute;\n            top: 0;\n            left: 0;\n            right: 0;\n            bottom: 0;\n        }\n        &::-webkit-scrollbar-button {\n            display: none;\n            height: 0;\n            width: 0;\n        }\n        &::-webkit-scrollbar-thumb {\n            background-color: hsla(0, 0%, 0%, 0.35);\n            background-clip: padding-box;\n            border: 3px solid rgba(0, 0, 0, 0);\n            border-radius: 6px;\n            min-height: 36px;\n        }\n        &::-webkit-scrollbar-thumb:hover {\n            background-color: hsla(0, 0%, 0%, 0.4);\n        }\n    "]);
    _templateObject49 = function _templateObject49() {
      return data;
    };
    return data;
  }
  function _templateObject48() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        left: 0;\n    "]);
    _templateObject48 = function _templateObject48() {
      return data;
    };
    return data;
  }
  function _templateObject47() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-box-pack: start;\n        -webkit-justify-content: flex-start;\n        -ms-flex-pack: start;\n        justify-content: flex-start;\n    "]);
    _templateObject47 = function _templateObject47() {
      return data;
    };
    return data;
  }
  function _templateObject46() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-box-pack: end;\n        -webkit-justify-content: flex-end;\n        -ms-flex-pack: end;\n        justify-content: flex-end;\n    "]);
    _templateObject46 = function _templateObject46() {
      return data;
    };
    return data;
  }
  function _templateObject45() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-box-pack: center;\n        -webkit-justify-content: center;\n        -ms-flex-pack: center;\n        justify-content: center;\n    "]);
    _templateObject45 = function _templateObject45() {
      return data;
    };
    return data;
  }
  function _templateObject44() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-box-align: center;\n        -webkit-align-items: center;\n        -ms-flex-align: center;\n        -ms-grid-row-align: center;\n        align-items: center;\n    "]);
    _templateObject44 = function _templateObject44() {
      return data;
    };
    return data;
  }
  function _templateObject43() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        display: inline-block;\n    "]);
    _templateObject43 = function _templateObject43() {
      return data;
    };
    return data;
  }
  function _templateObject42() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        display: inline;\n    "]);
    _templateObject42 = function _templateObject42() {
      return data;
    };
    return data;
  }
  function _templateObject41() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        height: 100%;\n    "]);
    _templateObject41 = function _templateObject41() {
      return data;
    };
    return data;
  }
  function _templateObject40() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: rgb(32, 201, 51);\n    "]);
    _templateObject40 = function _templateObject40() {
      return data;
    };
    return data;
  }
  function _templateObject39() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: rgb(238, 238, 238);\n    "]);
    _templateObject39 = function _templateObject39() {
      return data;
    };
    return data;
  }
  function _templateObject38() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: rgb(102, 102, 102);\n    "]);
    _templateObject38 = function _templateObject38() {
      return data;
    };
    return data;
  }
  function _templateObject37() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        &:focus {\n            outline: 0;\n            box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);\n        }\n    "]);
    _templateObject37 = function _templateObject37() {
      return data;
    };
    return data;
  }
  function _templateObject36() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-flex-wrap: wrap;\n        -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n    "]);
    _templateObject36 = function _templateObject36() {
      return data;
    };
    return data;
  }
  function _templateObject35() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-flex-direction: row-reverse;\n        flex-direction: row-reverse;\n    "]);
    _templateObject35 = function _templateObject35() {
      return data;
    };
    return data;
  }
  function _templateObject34() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-box-flex: 0;\n        -webkit-flex: none;\n        -ms-flex: none;\n        flex: none;\n    "]);
    _templateObject34 = function _templateObject34() {
      return data;
    };
    return data;
  }
  function _templateObject33() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        display: -webkit-inline-flex;\n        display: -ms-inline-flexbox;\n        display: inline-flex;\n    "]);
    _templateObject33 = function _templateObject33() {
      return data;
    };
    return data;
  }
  function _templateObject32() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        -webkit-box-flex: 1;\n        -webkit-flex: 1 1 auto;\n        -ms-flex: 1 1 auto;\n        flex: 1 1 auto;\n        min-width: 0;\n        min-height: 0;\n    "]);
    _templateObject32 = function _templateObject32() {
      return data;
    };
    return data;
  }
  function _templateObject31() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        display: -webkit-box;\n        display: -webkit-flex;\n        display: -ms-flexbox;\n        display: flex;\n    "]);
    _templateObject31 = function _templateObject31() {
      return data;
    };
    return data;
  }
  function _templateObject30() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        position: fixed;\n    "]);
    _templateObject30 = function _templateObject30() {
      return data;
    };
    return data;
  }
  function _templateObject29() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: hsla(0, 0%, 0%, 0.5);\n    "]);
    _templateObject29 = function _templateObject29() {
      return data;
    };
    return data;
  }
  function _templateObject28() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: hsla(0, 0%, 0%, 0.25);\n    "]);
    _templateObject28 = function _templateObject28() {
      return data;
    };
    return data;
  }
  function _templateObject27() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: hsla(0, 0%, 0%, 0.1);\n    "]);
    _templateObject27 = function _templateObject27() {
      return data;
    };
    return data;
  }
  function _templateObject26() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        &:hover {\n            background-color: hsla(0, 0%, 0%, 0.05);\n        }\n    "]);
    _templateObject26 = function _templateObject26() {
      return data;
    };
    return data;
  }
  function _templateObject25() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        &:focus {\n            background-color: hsla(0, 0%, 0%, 0.05);\n        }\n    "]);
    _templateObject25 = function _templateObject25() {
      return data;
    };
    return data;
  }
  function _templateObject24() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: hsla(0, 0%, 0%, 0.05);\n    "]);
    _templateObject24 = function _templateObject24() {
      return data;
    };
    return data;
  }
  function _templateObject23() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: hsl(0, 0%, 20%);\n    "]);
    _templateObject23 = function _templateObject23() {
      return data;
    };
    return data;
  }
  function _templateObject22() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        border-radius: 50%;\n    "]);
    _templateObject22 = function _templateObject22() {
      return data;
    };
    return data;
  }
  function _templateObject21() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        text-align: center;\n    "]);
    _templateObject21 = function _templateObject21() {
      return data;
    };
    return data;
  }
  function _templateObject20() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.1);\n        transition-property: box-shadow;\n        -webkit-transition-property: box-shadow;\n        -moz-transition-property: box-shadow;\n        transition-duration: 0.15s;\n        -webkit-transition-duration: 0.15s;\n        -moz-transition-duration: 0.15s;\n        transition-timing-function: ease-out;\n        -webkit-transition-timing-function: ease-out;\n        -moz-transition-timing-function: ease-out;\n        &:hover {\n            box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.25);\n        }\n    "]);
    _templateObject20 = function _templateObject20() {
      return data;
    };
    return data;
  }
  function _templateObject19() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        text-transform: uppercase;\n        letter-spacing: 0.1em;\n    "]);
    _templateObject19 = function _templateObject19() {
      return data;
    };
    return data;
  }
  function _templateObject18() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        bottom: 0;\n    "]);
    _templateObject18 = function _templateObject18() {
      return data;
    };
    return data;
  }
  function _templateObject17() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        box-sizing: border-box;\n    "]);
    _templateObject17 = function _templateObject17() {
      return data;
    };
    return data;
  }
  function _templateObject16() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: rgb(45, 127, 249);\n    "]);
    _templateObject16 = function _templateObject16() {
      return data;
    };
    return data;
  }
  function _templateObject15() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        display: block;\n    "]);
    _templateObject15 = function _templateObject15() {
      return data;
    };
    return data;
  }
  function _templateObject14() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        font-size: 0.9rem;\n    "]);
    _templateObject14 = function _templateObject14() {
      return data;
    };
    return data;
  }
  function _templateObject13() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-color: transparent;\n    "]);
    _templateObject13 = function _templateObject13() {
      return data;
    };
    return data;
  }
  function _templateObject12() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-size: cover;\n    "]);
    _templateObject12 = function _templateObject12() {
      return data;
    };
    return data;
  }
  function _templateObject11() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        background-position: center center;\n    "]);
    _templateObject11 = function _templateObject11() {
      return data;
    };
    return data;
  }
  function _templateObject10() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        color: hsl(0, 0%, 33%);\n    "]);
    _templateObject10 = function _templateObject10() {
      return data;
    };
    return data;
  }
  function _templateObject9() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        animation-name: ", ";\n        animation-duration: 1800ms;\n        animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);\n    "]);
    _templateObject9 = function _templateObject9() {
      return data;
    };
    return data;
  }
  function _templateObject8() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        animation-iteration-count: infinite;\n    "]);
    _templateObject8 = function _templateObject8() {
      return data;
    };
    return data;
  }
  function _templateObject7() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        animation-name: ", ";\n        animation-duration: 240ms;\n    "]);
    _templateObject7 = function _templateObject7() {
      return data;
    };
    return data;
  }
  function _templateObject6() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        transition: 0.085s all ease-in;\n    "]);
    _templateObject6 = function _templateObject6() {
      return data;
    };
    return data;
  }
  function _templateObject5() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n    "]);
    _templateObject5 = function _templateObject5() {
      return data;
    };
    return data;
  }
  function _templateObject4() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        vertical-align: top;\n    "]);
    _templateObject4 = function _templateObject4() {
      return data;
    };
    return data;
  }
  function _templateObject3() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n        position: absolute;\n    "]);
    _templateObject3 = function _templateObject3() {
      return data;
    };
    return data;
  }
  function _templateObject2() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n    0% {\n        transform: rotate(0) scale(1);\n    }\n    50% {\n        transform: rotate(360deg) scale(0.9);\n    }\n    100% {\n        transform: rotate(720deg) scale(1);\n    }\n"]);
    _templateObject2 = function _templateObject2() {
      return data;
    };
    return data;
  }
  function _templateObject() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n    from, 50%, to {\n        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);\n        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);\n    }\n    0% {\n        opacity: 0.9;\n        -webkit-transform: scale3d(.98, .98, .98);\n        transform: scale3d(.98, .98, .98);\n    }\n    70% {\n        opacity: 1;\n        -webkit-transform: scale3d(1.01, 1.01, 1.01);\n        transform: scale3d(1.01, 1.01, 1.01);\n    }\n    to {\n        -webkit-transform: scale3d(1, 1, 1);\n        transform: scale3d(1, 1, 1);\n    }\n"]);
    _templateObject = function _templateObject() {
      return data;
    };
    return data;
  }
  var bounceIn = (0, _emotion.keyframes)(_templateObject());
  var spinScale = (0, _emotion.keyframes)(_templateObject2()); // TODO (stephen): import values from theme object?

  var emotionClassNameByBaymaxClassName = {
    absolute: (0, _emotion.css)(_templateObject3()),
    "align-top": (0, _emotion.css)(_templateObject4()),
    "all-0": (0, _emotion.css)(_templateObject5()),
    animate: (0, _emotion.css)(_templateObject6()),
    "animate-bounce-in": (0, _emotion.css)(_templateObject7(), bounceIn),
    "animate-infinite": (0, _emotion.css)(_templateObject8()),
    "animate-spin-scale": (0, _emotion.css)(_templateObject9(), spinScale),
    appFontColorLight: (0, _emotion.css)(_templateObject10()),
    "background-center": (0, _emotion.css)(_templateObject11()),
    "background-cover": (0, _emotion.css)(_templateObject12()),
    "background-transparent": (0, _emotion.css)(_templateObject13()),
    big: (0, _emotion.css)(_templateObject14()),
    block: (0, _emotion.css)(_templateObject15()),
    blue: (0, _emotion.css)(_templateObject16()),
    "border-box": (0, _emotion.css)(_templateObject17()),
    "bottom-0": (0, _emotion.css)(_templateObject18()),
    caps: (0, _emotion.css)(_templateObject19()),
    cardBoxShadow: (0, _emotion.css)(_templateObject20()),
    center: (0, _emotion.css)(_templateObject21()),
    circle: (0, _emotion.css)(_templateObject22()),
    dark: (0, _emotion.css)(_templateObject23()),
    darken1: (0, _emotion.css)(_templateObject24()),
    "darken1-focus": (0, _emotion.css)(_templateObject25()),
    "darken1-hover": (0, _emotion.css)(_templateObject26()),
    darken2: (0, _emotion.css)(_templateObject27()),
    darken3: (0, _emotion.css)(_templateObject28()),
    darken4: (0, _emotion.css)(_templateObject29()),
    fixed: (0, _emotion.css)(_templateObject30()),
    flex: (0, _emotion.css)(_templateObject31()),
    "flex-auto": (0, _emotion.css)(_templateObject32()),
    "flex-inline": (0, _emotion.css)(_templateObject33()),
    "flex-none": (0, _emotion.css)(_templateObject34()),
    "flex-reverse": (0, _emotion.css)(_templateObject35()),
    "flex-wrap": (0, _emotion.css)(_templateObject36()),
    focusable: (0, _emotion.css)(_templateObject37()),
    gray: (0, _emotion.css)(_templateObject38()),
    grayLight2: (0, _emotion.css)(_templateObject39()),
    green: (0, _emotion.css)(_templateObject40()),
    "height-full": (0, _emotion.css)(_templateObject41()),
    inline: (0, _emotion.css)(_templateObject42()),
    "inline-block": (0, _emotion.css)(_templateObject43()),
    "items-center": (0, _emotion.css)(_templateObject44()),
    "justify-center": (0, _emotion.css)(_templateObject45()),
    "justify-end": (0, _emotion.css)(_templateObject46()),
    "justify-start": (0, _emotion.css)(_templateObject47()),
    "left-0": (0, _emotion.css)(_templateObject48()),
    "light-scrollbar": (0, _emotion.css)(_templateObject49()),
    "line-height-4": (0, _emotion.css)(_templateObject50()),
    "link-quiet": (0, _emotion.css)(_templateObject51()),
    "link-unquiet": (0, _emotion.css)(_templateObject52()),
    "link-unquiet-focusable": (0, _emotion.css)(_templateObject53()),
    m0: (0, _emotion.css)(_templateObject54()),
    m2: (0, _emotion.css)(_templateObject55()),
    mb1: (0, _emotion.css)(_templateObject56()),
    ml1: (0, _emotion.css)(_templateObject57()),
    "mr-half": (0, _emotion.css)(_templateObject58()),
    mr1: (0, _emotion.css)(_templateObject59()),
    mt0: (0, _emotion.css)(_templateObject60()),
    mt1: (0, _emotion.css)(_templateObject61()),
    mt2: (0, _emotion.css)(_templateObject62()),
    "no-outline": (0, _emotion.css)(_templateObject63()),
    "no-user-select": (0, _emotion.css)(_templateObject64()),
    noevents: (0, _emotion.css)(_templateObject65()),
    normal: (0, _emotion.css)(_templateObject66()),
    nowrap: (0, _emotion.css)(_templateObject67()),
    "overflow-auto": (0, _emotion.css)(_templateObject68()),
    "overflow-hidden": (0, _emotion.css)(_templateObject69()),
    "p-half": (0, _emotion.css)(_templateObject70()),
    p1: (0, _emotion.css)(_templateObject71()),
    p2: (0, _emotion.css)(_templateObject72()),
    pill: (0, _emotion.css)(_templateObject73()),
    pointer: (0, _emotion.css)(_templateObject74()),
    pr1: (0, _emotion.css)(_templateObject75()),
    "print-color-exact": (0, _emotion.css)(_templateObject76()),
    px1: (0, _emotion.css)(_templateObject77()),
    quiet: (0, _emotion.css)(_templateObject78()),
    quieter: (0, _emotion.css)(_templateObject79()),
    red: (0, _emotion.css)(_templateObject80()),
    relative: (0, _emotion.css)(_templateObject81()),
    "right-0": (0, _emotion.css)(_templateObject82()),
    rounded: (0, _emotion.css)(_templateObject83()),
    "rounded-big": (0, _emotion.css)(_templateObject84()),
    "self-end": (0, _emotion.css)(_templateObject85()),
    small: (0, _emotion.css)(_templateObject86()),
    stroked1: (0, _emotion.css)(_templateObject87()),
    strong: (0, _emotion.css)(_templateObject88()),
    "styled-input": (0, _emotion.css)(_templateObject89()),
    "text-blue": (0, _emotion.css)(_templateObject90()),
    "text-blue-focus": (0, _emotion.css)(_templateObject91()),
    "text-dark": (0, _emotion.css)(_templateObject92()),
    "text-white": (0, _emotion.css)(_templateObject93()),
    "top-0": (0, _emotion.css)(_templateObject94()),
    truncate: (0, _emotion.css)(_templateObject95()),
    understroke: (0, _emotion.css)(_templateObject96()),
    white: (0, _emotion.css)(_templateObject97()),
    "width-full": (0, _emotion.css)(_templateObject98()),
    yellow: (0, _emotion.css)(_templateObject99())
  };
  /**
   * @internal
   */

  function baymax(baymaxClassNames) {
    return baymaxClassNames.split(/\s+/).filter(Boolean).map(baymaxClassName => {
      if ((0, _private_utils.has)(emotionClassNameByBaymaxClassName, baymaxClassName)) {
        return emotionClassNameByBaymaxClassName[baymaxClassName];
      } else {
        throw (0, _error_utils.spawnError)("Baymax class not found: %s. If required, add the definition to baymax_utils.js.`", baymaxClassName);
      }
    }).join(" ");
  }
  return exports;
}