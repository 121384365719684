import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _perform_record_action2Dew } from "../perform_record_action";
import { dew as _use_loadable2Dew } from "./use_loadable";
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = useRecordActionData;
  var _perform_record_action = _perform_record_action2Dew();
  var _use_loadable = _interopRequireDefault(_use_loadable2Dew());
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _sdk_context = _sdk_context2Dew();

  /** @module @airtable/blocks/ui: useRecordActionData */

  /** */

  /**
   * A hook to watch "open extension" / "perform record action" events (from button field). Returns
   * the data corresponding to the latest action, or `null` if no actions have occurred yet. If there
   * was a pending event for the extension (for example, because a button that opens this extension was
   * clicked while the extensions dashboard was closed) it will be returned as the initial value.
   *
   * Like {@link useLoadable}, this hook puts the extension in suspense while subscribing to events and
   * fetching the initial data.
   *
   * Also see {@link registerRecordActionDataCallback}, which subscribes to the same events in an
   * asynchronous (callback based) way. An advantage of using this hook over the callback is that you
   * immediately can handle any pending events when your extension opens - with a callback, your extension
   * will finish it's initial render before handling the event.
   *
   * Like {@link registerRecordActionDataCallback}, your extension won't receive events until this hook is
   * used for the first time. Because of that, we recommend only using this hook once, in the top
   * level component of your extension. Similarly, using both `registerRecordActionDataCallback` and
   * `useRecordActionData` is not supported.
   *
   * You can test your extension in development by sending "perform record action" events to your extension
   * in the "Advanced" panel of the extension developer tools.
   *
   * After releasing your extension, you can use it with a button field by choosing the "Open custom
   * extension" action and selecting your extension.
   *
   * @example
   * ```js
   * import React from 'react';
   * import {useRecordActionData} from '@airtable/blocks/ui';
   *
   * function LatestRecordAction() {
   *     const recordActionData = useRecordActionData();
   *
   *     if (recordActionData === null) {
   *         return <span>No events yet</span>;
   *     }
   *
   *     return (
   *         <ul>
   *             <li>Record id: {recordActionData.recordId}</li>
   *             <li>View id: {recordActionData.viewId}</li>
   *             <li>Table id: {recordActionData.tableId}</li>
   *         </ul>
   *     );
   * }
   * ```
   *
   * @docsPath UI/hooks/useRecordActionData
   * @hook
   */
  function useRecordActionData() {
    var _useSdk = (0, _sdk_context.useSdk)(),
      performRecordAction = _useSdk.performRecordAction; // Puts the block in suspense until the liveapp handler has been registered.

    (0, _use_loadable.default)(performRecordAction); // Note: if we were to pass a callback to useWatchable, it would drop the callback for the
    // initial recordActionData (if it exists) when the component is mounted.
    // We'd have to trigger it manually by comparing recordActionData to it's previous value in an
    // effect, but would need to be careful to not erroneously trigger it when the component
    // unmounts and remounts.
    // So, using a callback with useRecordActionData isn't supported right now for simplicity.

    (0, _use_watchable.default)(performRecordAction, _perform_record_action.WatchablePerformRecordActionKeys.recordActionData);
    return performRecordAction.recordActionData;
  }
  return exports;
}