import { dew as _appearance_set2Dew } from "./appearance/appearance_set";
import { dew as _background_color2Dew } from "./appearance/background_color";
import { dew as _border_radius2Dew } from "./appearance/border_radius";
import { dew as _border2Dew } from "./appearance/border";
import { dew as _box_shadow2Dew } from "./appearance/box_shadow";
import { dew as _opacity2Dew } from "./appearance/opacity";
import { dew as _dimensions_set2Dew } from "./dimensions/dimensions_set";
import { dew as _height2Dew } from "./dimensions/height";
import { dew as _max_height2Dew } from "./dimensions/max_height";
import { dew as _max_width2Dew } from "./dimensions/max_width";
import { dew as _min_height2Dew } from "./dimensions/min_height";
import { dew as _min_width2Dew } from "./dimensions/min_width";
import { dew as _width2Dew } from "./dimensions/width";
import { dew as _flex_container_set2Dew } from "./flex_container/flex_container_set";
import { dew as _align_content2Dew } from "./flex_container/align_content";
import { dew as _align_items2Dew } from "./flex_container/align_items";
import { dew as _flex_direction2Dew } from "./flex_container/flex_direction";
import { dew as _flex_wrap2Dew } from "./flex_container/flex_wrap";
import { dew as _justify_content2Dew } from "./flex_container/justify_content";
import { dew as _justify_items2Dew } from "./flex_container/justify_items";
import { dew as _flex_item_set2Dew } from "./flex_item/flex_item_set";
import { dew as _align_self2Dew } from "./flex_item/align_self";
import { dew as _flex_basis2Dew } from "./flex_item/flex_basis";
import { dew as _flex_grow2Dew } from "./flex_item/flex_grow";
import { dew as _flex_shrink2Dew } from "./flex_item/flex_shrink";
import { dew as _flex2Dew } from "./flex_item/flex";
import { dew as _justify_self2Dew } from "./flex_item/justify_self";
import { dew as _order2Dew } from "./flex_item/order";
import { dew as _position_set2Dew } from "./position/position_set";
import { dew as _bottom2Dew } from "./position/bottom";
import { dew as _left2Dew } from "./position/left";
import { dew as _position2Dew } from "./position/position";
import { dew as _right2Dew } from "./position/right";
import { dew as _top2Dew } from "./position/top";
import { dew as _z_index2Dew } from "./position/z_index";
import { dew as _spacing_set2Dew } from "./spacing/spacing_set";
import { dew as _margin2Dew } from "./spacing/margin";
import { dew as _padding2Dew } from "./spacing/padding";
import { dew as _typography_set2Dew } from "./typography/typography_set";
import { dew as _font_family2Dew } from "./typography/font_family";
import { dew as _font_size2Dew } from "./typography/font_size";
import { dew as _font_style2Dew } from "./typography/font_style";
import { dew as _font_weight2Dew } from "./typography/font_weight";
import { dew as _letter_spacing2Dew } from "./typography/letter_spacing";
import { dew as _line_height2Dew } from "./typography/line_height";
import { dew as _text_align2Dew } from "./typography/text_align";
import { dew as _text_color2Dew } from "./typography/text_color";
import { dew as _text_decoration2Dew } from "./typography/text_decoration";
import { dew as _text_transform2Dew } from "./typography/text_transform";
import { dew as _display2Dew } from "./display";
import { dew as _overflow2Dew } from "./overflow";
import { dew as _all_styles_set2Dew } from "./all_styles_set";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "appearanceSet", {
    enumerable: true,
    get: function get() {
      return _appearance_set.appearanceSet;
    }
  });
  Object.defineProperty(exports, "appearanceSetPropTypes", {
    enumerable: true,
    get: function get() {
      return _appearance_set.appearanceSetPropTypes;
    }
  });
  Object.defineProperty(exports, "AppearanceSetProps", {
    enumerable: true,
    get: function get() {
      return _appearance_set.AppearanceSetProps;
    }
  });
  Object.defineProperty(exports, "backgroundColor", {
    enumerable: true,
    get: function get() {
      return _background_color.backgroundColor;
    }
  });
  Object.defineProperty(exports, "backgroundColorPropTypes", {
    enumerable: true,
    get: function get() {
      return _background_color.backgroundColorPropTypes;
    }
  });
  Object.defineProperty(exports, "BackgroundColorProps", {
    enumerable: true,
    get: function get() {
      return _background_color.BackgroundColorProps;
    }
  });
  Object.defineProperty(exports, "borderRadius", {
    enumerable: true,
    get: function get() {
      return _border_radius.borderRadius;
    }
  });
  Object.defineProperty(exports, "borderRadiusPropTypes", {
    enumerable: true,
    get: function get() {
      return _border_radius.borderRadiusPropTypes;
    }
  });
  Object.defineProperty(exports, "BorderRadiusProps", {
    enumerable: true,
    get: function get() {
      return _border_radius.BorderRadiusProps;
    }
  });
  Object.defineProperty(exports, "border", {
    enumerable: true,
    get: function get() {
      return _border.border;
    }
  });
  Object.defineProperty(exports, "borderPropTypes", {
    enumerable: true,
    get: function get() {
      return _border.borderPropTypes;
    }
  });
  Object.defineProperty(exports, "BorderProps", {
    enumerable: true,
    get: function get() {
      return _border.BorderProps;
    }
  });
  Object.defineProperty(exports, "boxShadow", {
    enumerable: true,
    get: function get() {
      return _box_shadow.boxShadow;
    }
  });
  Object.defineProperty(exports, "boxShadowPropTypes", {
    enumerable: true,
    get: function get() {
      return _box_shadow.boxShadowPropTypes;
    }
  });
  Object.defineProperty(exports, "BoxShadowProps", {
    enumerable: true,
    get: function get() {
      return _box_shadow.BoxShadowProps;
    }
  });
  Object.defineProperty(exports, "opacity", {
    enumerable: true,
    get: function get() {
      return _opacity.opacity;
    }
  });
  Object.defineProperty(exports, "opacityPropTypes", {
    enumerable: true,
    get: function get() {
      return _opacity.opacityPropTypes;
    }
  });
  Object.defineProperty(exports, "OpacityProps", {
    enumerable: true,
    get: function get() {
      return _opacity.OpacityProps;
    }
  });
  Object.defineProperty(exports, "dimensionsSet", {
    enumerable: true,
    get: function get() {
      return _dimensions_set.dimensionsSet;
    }
  });
  Object.defineProperty(exports, "dimensionsSetPropTypes", {
    enumerable: true,
    get: function get() {
      return _dimensions_set.dimensionsSetPropTypes;
    }
  });
  Object.defineProperty(exports, "DimensionsSetProps", {
    enumerable: true,
    get: function get() {
      return _dimensions_set.DimensionsSetProps;
    }
  });
  Object.defineProperty(exports, "height", {
    enumerable: true,
    get: function get() {
      return _height.height;
    }
  });
  Object.defineProperty(exports, "heightPropTypes", {
    enumerable: true,
    get: function get() {
      return _height.heightPropTypes;
    }
  });
  Object.defineProperty(exports, "HeightProps", {
    enumerable: true,
    get: function get() {
      return _height.HeightProps;
    }
  });
  Object.defineProperty(exports, "maxHeight", {
    enumerable: true,
    get: function get() {
      return _max_height.maxHeight;
    }
  });
  Object.defineProperty(exports, "maxHeightPropTypes", {
    enumerable: true,
    get: function get() {
      return _max_height.maxHeightPropTypes;
    }
  });
  Object.defineProperty(exports, "MaxHeightProps", {
    enumerable: true,
    get: function get() {
      return _max_height.MaxHeightProps;
    }
  });
  Object.defineProperty(exports, "maxWidth", {
    enumerable: true,
    get: function get() {
      return _max_width.maxWidth;
    }
  });
  Object.defineProperty(exports, "maxWidthPropTypes", {
    enumerable: true,
    get: function get() {
      return _max_width.maxWidthPropTypes;
    }
  });
  Object.defineProperty(exports, "MaxWidthProps", {
    enumerable: true,
    get: function get() {
      return _max_width.MaxWidthProps;
    }
  });
  Object.defineProperty(exports, "minHeight", {
    enumerable: true,
    get: function get() {
      return _min_height.minHeight;
    }
  });
  Object.defineProperty(exports, "minHeightPropTypes", {
    enumerable: true,
    get: function get() {
      return _min_height.minHeightPropTypes;
    }
  });
  Object.defineProperty(exports, "MinHeightProps", {
    enumerable: true,
    get: function get() {
      return _min_height.MinHeightProps;
    }
  });
  Object.defineProperty(exports, "minWidth", {
    enumerable: true,
    get: function get() {
      return _min_width.minWidth;
    }
  });
  Object.defineProperty(exports, "minWidthPropTypes", {
    enumerable: true,
    get: function get() {
      return _min_width.minWidthPropTypes;
    }
  });
  Object.defineProperty(exports, "MinWidthProps", {
    enumerable: true,
    get: function get() {
      return _min_width.MinWidthProps;
    }
  });
  Object.defineProperty(exports, "width", {
    enumerable: true,
    get: function get() {
      return _width.width;
    }
  });
  Object.defineProperty(exports, "widthPropTypes", {
    enumerable: true,
    get: function get() {
      return _width.widthPropTypes;
    }
  });
  Object.defineProperty(exports, "WidthProps", {
    enumerable: true,
    get: function get() {
      return _width.WidthProps;
    }
  });
  Object.defineProperty(exports, "flexContainerSet", {
    enumerable: true,
    get: function get() {
      return _flex_container_set.flexContainerSet;
    }
  });
  Object.defineProperty(exports, "flexContainerSetPropTypes", {
    enumerable: true,
    get: function get() {
      return _flex_container_set.flexContainerSetPropTypes;
    }
  });
  Object.defineProperty(exports, "FlexContainerSetProps", {
    enumerable: true,
    get: function get() {
      return _flex_container_set.FlexContainerSetProps;
    }
  });
  Object.defineProperty(exports, "alignContent", {
    enumerable: true,
    get: function get() {
      return _align_content.alignContent;
    }
  });
  Object.defineProperty(exports, "alignContentPropTypes", {
    enumerable: true,
    get: function get() {
      return _align_content.alignContentPropTypes;
    }
  });
  Object.defineProperty(exports, "AlignContentProps", {
    enumerable: true,
    get: function get() {
      return _align_content.AlignContentProps;
    }
  });
  Object.defineProperty(exports, "alignItems", {
    enumerable: true,
    get: function get() {
      return _align_items.alignItems;
    }
  });
  Object.defineProperty(exports, "alignItemsPropTypes", {
    enumerable: true,
    get: function get() {
      return _align_items.alignItemsPropTypes;
    }
  });
  Object.defineProperty(exports, "AlignItemsProps", {
    enumerable: true,
    get: function get() {
      return _align_items.AlignItemsProps;
    }
  });
  Object.defineProperty(exports, "flexDirection", {
    enumerable: true,
    get: function get() {
      return _flex_direction.flexDirection;
    }
  });
  Object.defineProperty(exports, "flexDirectionPropTypes", {
    enumerable: true,
    get: function get() {
      return _flex_direction.flexDirectionPropTypes;
    }
  });
  Object.defineProperty(exports, "FlexDirectionProps", {
    enumerable: true,
    get: function get() {
      return _flex_direction.FlexDirectionProps;
    }
  });
  Object.defineProperty(exports, "flexWrap", {
    enumerable: true,
    get: function get() {
      return _flex_wrap.flexWrap;
    }
  });
  Object.defineProperty(exports, "flexWrapPropTypes", {
    enumerable: true,
    get: function get() {
      return _flex_wrap.flexWrapPropTypes;
    }
  });
  Object.defineProperty(exports, "FlexWrapProps", {
    enumerable: true,
    get: function get() {
      return _flex_wrap.FlexWrapProps;
    }
  });
  Object.defineProperty(exports, "justifyContent", {
    enumerable: true,
    get: function get() {
      return _justify_content.justifyContent;
    }
  });
  Object.defineProperty(exports, "justifyContentPropTypes", {
    enumerable: true,
    get: function get() {
      return _justify_content.justifyContentPropTypes;
    }
  });
  Object.defineProperty(exports, "JustifyContentProps", {
    enumerable: true,
    get: function get() {
      return _justify_content.JustifyContentProps;
    }
  });
  Object.defineProperty(exports, "justifyItems", {
    enumerable: true,
    get: function get() {
      return _justify_items.justifyItems;
    }
  });
  Object.defineProperty(exports, "justifyItemsPropTypes", {
    enumerable: true,
    get: function get() {
      return _justify_items.justifyItemsPropTypes;
    }
  });
  Object.defineProperty(exports, "JustifyItemsProps", {
    enumerable: true,
    get: function get() {
      return _justify_items.JustifyItemsProps;
    }
  });
  Object.defineProperty(exports, "flexItemSet", {
    enumerable: true,
    get: function get() {
      return _flex_item_set.flexItemSet;
    }
  });
  Object.defineProperty(exports, "flexItemSetPropTypes", {
    enumerable: true,
    get: function get() {
      return _flex_item_set.flexItemSetPropTypes;
    }
  });
  Object.defineProperty(exports, "FlexItemSetProps", {
    enumerable: true,
    get: function get() {
      return _flex_item_set.FlexItemSetProps;
    }
  });
  Object.defineProperty(exports, "alignSelf", {
    enumerable: true,
    get: function get() {
      return _align_self.alignSelf;
    }
  });
  Object.defineProperty(exports, "alignSelfPropTypes", {
    enumerable: true,
    get: function get() {
      return _align_self.alignSelfPropTypes;
    }
  });
  Object.defineProperty(exports, "AlignSelfProps", {
    enumerable: true,
    get: function get() {
      return _align_self.AlignSelfProps;
    }
  });
  Object.defineProperty(exports, "flexBasis", {
    enumerable: true,
    get: function get() {
      return _flex_basis.flexBasis;
    }
  });
  Object.defineProperty(exports, "flexBasisPropTypes", {
    enumerable: true,
    get: function get() {
      return _flex_basis.flexBasisPropTypes;
    }
  });
  Object.defineProperty(exports, "FlexBasisProps", {
    enumerable: true,
    get: function get() {
      return _flex_basis.FlexBasisProps;
    }
  });
  Object.defineProperty(exports, "flexGrow", {
    enumerable: true,
    get: function get() {
      return _flex_grow.flexGrow;
    }
  });
  Object.defineProperty(exports, "flexGrowPropTypes", {
    enumerable: true,
    get: function get() {
      return _flex_grow.flexGrowPropTypes;
    }
  });
  Object.defineProperty(exports, "FlexGrowProps", {
    enumerable: true,
    get: function get() {
      return _flex_grow.FlexGrowProps;
    }
  });
  Object.defineProperty(exports, "flexShrink", {
    enumerable: true,
    get: function get() {
      return _flex_shrink.flexShrink;
    }
  });
  Object.defineProperty(exports, "flexShrinkPropTypes", {
    enumerable: true,
    get: function get() {
      return _flex_shrink.flexShrinkPropTypes;
    }
  });
  Object.defineProperty(exports, "FlexShrinkProps", {
    enumerable: true,
    get: function get() {
      return _flex_shrink.FlexShrinkProps;
    }
  });
  Object.defineProperty(exports, "flex", {
    enumerable: true,
    get: function get() {
      return _flex.flex;
    }
  });
  Object.defineProperty(exports, "flexPropTypes", {
    enumerable: true,
    get: function get() {
      return _flex.flexPropTypes;
    }
  });
  Object.defineProperty(exports, "FlexProps", {
    enumerable: true,
    get: function get() {
      return _flex.FlexProps;
    }
  });
  Object.defineProperty(exports, "justifySelf", {
    enumerable: true,
    get: function get() {
      return _justify_self.justifySelf;
    }
  });
  Object.defineProperty(exports, "justifySelfPropTypes", {
    enumerable: true,
    get: function get() {
      return _justify_self.justifySelfPropTypes;
    }
  });
  Object.defineProperty(exports, "JustifySelfProps", {
    enumerable: true,
    get: function get() {
      return _justify_self.JustifySelfProps;
    }
  });
  Object.defineProperty(exports, "order", {
    enumerable: true,
    get: function get() {
      return _order.order;
    }
  });
  Object.defineProperty(exports, "orderPropTypes", {
    enumerable: true,
    get: function get() {
      return _order.orderPropTypes;
    }
  });
  Object.defineProperty(exports, "OrderProps", {
    enumerable: true,
    get: function get() {
      return _order.OrderProps;
    }
  });
  Object.defineProperty(exports, "positionSet", {
    enumerable: true,
    get: function get() {
      return _position_set.positionSet;
    }
  });
  Object.defineProperty(exports, "positionSetPropTypes", {
    enumerable: true,
    get: function get() {
      return _position_set.positionSetPropTypes;
    }
  });
  Object.defineProperty(exports, "PositionSetProps", {
    enumerable: true,
    get: function get() {
      return _position_set.PositionSetProps;
    }
  });
  Object.defineProperty(exports, "bottom", {
    enumerable: true,
    get: function get() {
      return _bottom.bottom;
    }
  });
  Object.defineProperty(exports, "bottomPropTypes", {
    enumerable: true,
    get: function get() {
      return _bottom.bottomPropTypes;
    }
  });
  Object.defineProperty(exports, "BottomProps", {
    enumerable: true,
    get: function get() {
      return _bottom.BottomProps;
    }
  });
  Object.defineProperty(exports, "left", {
    enumerable: true,
    get: function get() {
      return _left.left;
    }
  });
  Object.defineProperty(exports, "leftPropTypes", {
    enumerable: true,
    get: function get() {
      return _left.leftPropTypes;
    }
  });
  Object.defineProperty(exports, "LeftProps", {
    enumerable: true,
    get: function get() {
      return _left.LeftProps;
    }
  });
  Object.defineProperty(exports, "position", {
    enumerable: true,
    get: function get() {
      return _position.position;
    }
  });
  Object.defineProperty(exports, "positionPropTypes", {
    enumerable: true,
    get: function get() {
      return _position.positionPropTypes;
    }
  });
  Object.defineProperty(exports, "PositionProps", {
    enumerable: true,
    get: function get() {
      return _position.PositionProps;
    }
  });
  Object.defineProperty(exports, "right", {
    enumerable: true,
    get: function get() {
      return _right.right;
    }
  });
  Object.defineProperty(exports, "rightPropTypes", {
    enumerable: true,
    get: function get() {
      return _right.rightPropTypes;
    }
  });
  Object.defineProperty(exports, "RightProps", {
    enumerable: true,
    get: function get() {
      return _right.RightProps;
    }
  });
  Object.defineProperty(exports, "top", {
    enumerable: true,
    get: function get() {
      return _top.top;
    }
  });
  Object.defineProperty(exports, "topPropTypes", {
    enumerable: true,
    get: function get() {
      return _top.topPropTypes;
    }
  });
  Object.defineProperty(exports, "TopProps", {
    enumerable: true,
    get: function get() {
      return _top.TopProps;
    }
  });
  Object.defineProperty(exports, "zIndex", {
    enumerable: true,
    get: function get() {
      return _z_index.zIndex;
    }
  });
  Object.defineProperty(exports, "zIndexPropTypes", {
    enumerable: true,
    get: function get() {
      return _z_index.zIndexPropTypes;
    }
  });
  Object.defineProperty(exports, "ZIndexProps", {
    enumerable: true,
    get: function get() {
      return _z_index.ZIndexProps;
    }
  });
  Object.defineProperty(exports, "spacingSet", {
    enumerable: true,
    get: function get() {
      return _spacing_set.spacingSet;
    }
  });
  Object.defineProperty(exports, "spacingSetPropTypes", {
    enumerable: true,
    get: function get() {
      return _spacing_set.spacingSetPropTypes;
    }
  });
  Object.defineProperty(exports, "SpacingSetProps", {
    enumerable: true,
    get: function get() {
      return _spacing_set.SpacingSetProps;
    }
  });
  Object.defineProperty(exports, "margin", {
    enumerable: true,
    get: function get() {
      return _margin.margin;
    }
  });
  Object.defineProperty(exports, "marginPropTypes", {
    enumerable: true,
    get: function get() {
      return _margin.marginPropTypes;
    }
  });
  Object.defineProperty(exports, "MarginProps", {
    enumerable: true,
    get: function get() {
      return _margin.MarginProps;
    }
  });
  Object.defineProperty(exports, "padding", {
    enumerable: true,
    get: function get() {
      return _padding.padding;
    }
  });
  Object.defineProperty(exports, "paddingPropTypes", {
    enumerable: true,
    get: function get() {
      return _padding.paddingPropTypes;
    }
  });
  Object.defineProperty(exports, "PaddingProps", {
    enumerable: true,
    get: function get() {
      return _padding.PaddingProps;
    }
  });
  Object.defineProperty(exports, "typographySet", {
    enumerable: true,
    get: function get() {
      return _typography_set.typographySet;
    }
  });
  Object.defineProperty(exports, "typographySetPropTypes", {
    enumerable: true,
    get: function get() {
      return _typography_set.typographySetPropTypes;
    }
  });
  Object.defineProperty(exports, "TypographySetProps", {
    enumerable: true,
    get: function get() {
      return _typography_set.TypographySetProps;
    }
  });
  Object.defineProperty(exports, "fontFamily", {
    enumerable: true,
    get: function get() {
      return _font_family.fontFamily;
    }
  });
  Object.defineProperty(exports, "fontFamilyPropTypes", {
    enumerable: true,
    get: function get() {
      return _font_family.fontFamilyPropTypes;
    }
  });
  Object.defineProperty(exports, "FontFamilyProps", {
    enumerable: true,
    get: function get() {
      return _font_family.FontFamilyProps;
    }
  });
  Object.defineProperty(exports, "fontSize", {
    enumerable: true,
    get: function get() {
      return _font_size.fontSize;
    }
  });
  Object.defineProperty(exports, "fontSizePropTypes", {
    enumerable: true,
    get: function get() {
      return _font_size.fontSizePropTypes;
    }
  });
  Object.defineProperty(exports, "FontSizeProps", {
    enumerable: true,
    get: function get() {
      return _font_size.FontSizeProps;
    }
  });
  Object.defineProperty(exports, "fontStyle", {
    enumerable: true,
    get: function get() {
      return _font_style.fontStyle;
    }
  });
  Object.defineProperty(exports, "fontStylePropTypes", {
    enumerable: true,
    get: function get() {
      return _font_style.fontStylePropTypes;
    }
  });
  Object.defineProperty(exports, "FontStyleProps", {
    enumerable: true,
    get: function get() {
      return _font_style.FontStyleProps;
    }
  });
  Object.defineProperty(exports, "fontWeight", {
    enumerable: true,
    get: function get() {
      return _font_weight.fontWeight;
    }
  });
  Object.defineProperty(exports, "fontWeightPropTypes", {
    enumerable: true,
    get: function get() {
      return _font_weight.fontWeightPropTypes;
    }
  });
  Object.defineProperty(exports, "FontWeightProps", {
    enumerable: true,
    get: function get() {
      return _font_weight.FontWeightProps;
    }
  });
  Object.defineProperty(exports, "letterSpacing", {
    enumerable: true,
    get: function get() {
      return _letter_spacing.letterSpacing;
    }
  });
  Object.defineProperty(exports, "letterSpacingPropTypes", {
    enumerable: true,
    get: function get() {
      return _letter_spacing.letterSpacingPropTypes;
    }
  });
  Object.defineProperty(exports, "LetterSpacingProps", {
    enumerable: true,
    get: function get() {
      return _letter_spacing.LetterSpacingProps;
    }
  });
  Object.defineProperty(exports, "lineHeight", {
    enumerable: true,
    get: function get() {
      return _line_height.lineHeight;
    }
  });
  Object.defineProperty(exports, "lineHeightPropTypes", {
    enumerable: true,
    get: function get() {
      return _line_height.lineHeightPropTypes;
    }
  });
  Object.defineProperty(exports, "LineHeightProps", {
    enumerable: true,
    get: function get() {
      return _line_height.LineHeightProps;
    }
  });
  Object.defineProperty(exports, "textAlign", {
    enumerable: true,
    get: function get() {
      return _text_align.textAlign;
    }
  });
  Object.defineProperty(exports, "textAlignPropTypes", {
    enumerable: true,
    get: function get() {
      return _text_align.textAlignPropTypes;
    }
  });
  Object.defineProperty(exports, "TextAlignProps", {
    enumerable: true,
    get: function get() {
      return _text_align.TextAlignProps;
    }
  });
  Object.defineProperty(exports, "textColor", {
    enumerable: true,
    get: function get() {
      return _text_color.textColor;
    }
  });
  Object.defineProperty(exports, "textColorPropTypes", {
    enumerable: true,
    get: function get() {
      return _text_color.textColorPropTypes;
    }
  });
  Object.defineProperty(exports, "TextColorProps", {
    enumerable: true,
    get: function get() {
      return _text_color.TextColorProps;
    }
  });
  Object.defineProperty(exports, "textDecoration", {
    enumerable: true,
    get: function get() {
      return _text_decoration.textDecoration;
    }
  });
  Object.defineProperty(exports, "textDecorationPropTypes", {
    enumerable: true,
    get: function get() {
      return _text_decoration.textDecorationPropTypes;
    }
  });
  Object.defineProperty(exports, "TextDecorationProps", {
    enumerable: true,
    get: function get() {
      return _text_decoration.TextDecorationProps;
    }
  });
  Object.defineProperty(exports, "textTransform", {
    enumerable: true,
    get: function get() {
      return _text_transform.textTransform;
    }
  });
  Object.defineProperty(exports, "textTransformPropTypes", {
    enumerable: true,
    get: function get() {
      return _text_transform.textTransformPropTypes;
    }
  });
  Object.defineProperty(exports, "TextTransformProps", {
    enumerable: true,
    get: function get() {
      return _text_transform.TextTransformProps;
    }
  });
  Object.defineProperty(exports, "display", {
    enumerable: true,
    get: function get() {
      return _display.display;
    }
  });
  Object.defineProperty(exports, "displayPropTypes", {
    enumerable: true,
    get: function get() {
      return _display.displayPropTypes;
    }
  });
  Object.defineProperty(exports, "DisplayProps", {
    enumerable: true,
    get: function get() {
      return _display.DisplayProps;
    }
  });
  Object.defineProperty(exports, "overflow", {
    enumerable: true,
    get: function get() {
      return _overflow.overflow;
    }
  });
  Object.defineProperty(exports, "overflowPropTypes", {
    enumerable: true,
    get: function get() {
      return _overflow.overflowPropTypes;
    }
  });
  Object.defineProperty(exports, "OverflowProps", {
    enumerable: true,
    get: function get() {
      return _overflow.OverflowProps;
    }
  });
  Object.defineProperty(exports, "allStylesParser", {
    enumerable: true,
    get: function get() {
      return _all_styles_set.allStylesParser;
    }
  });
  Object.defineProperty(exports, "allStylesPropTypes", {
    enumerable: true,
    get: function get() {
      return _all_styles_set.allStylesPropTypes;
    }
  });
  Object.defineProperty(exports, "AllStylesProps", {
    enumerable: true,
    get: function get() {
      return _all_styles_set.AllStylesProps;
    }
  });
  var _appearance_set = _appearance_set2Dew();
  var _background_color = _background_color2Dew();
  var _border_radius = _border_radius2Dew();
  var _border = _border2Dew();
  var _box_shadow = _box_shadow2Dew();
  var _opacity = _opacity2Dew();
  var _dimensions_set = _dimensions_set2Dew();
  var _height = _height2Dew();
  var _max_height = _max_height2Dew();
  var _max_width = _max_width2Dew();
  var _min_height = _min_height2Dew();
  var _min_width = _min_width2Dew();
  var _width = _width2Dew();
  var _flex_container_set = _flex_container_set2Dew();
  var _align_content = _align_content2Dew();
  var _align_items = _align_items2Dew();
  var _flex_direction = _flex_direction2Dew();
  var _flex_wrap = _flex_wrap2Dew();
  var _justify_content = _justify_content2Dew();
  var _justify_items = _justify_items2Dew();
  var _flex_item_set = _flex_item_set2Dew();
  var _align_self = _align_self2Dew();
  var _flex_basis = _flex_basis2Dew();
  var _flex_grow = _flex_grow2Dew();
  var _flex_shrink = _flex_shrink2Dew();
  var _flex = _flex2Dew();
  var _justify_self = _justify_self2Dew();
  var _order = _order2Dew();
  var _position_set = _position_set2Dew();
  var _bottom = _bottom2Dew();
  var _left = _left2Dew();
  var _position = _position2Dew();
  var _right = _right2Dew();
  var _top = _top2Dew();
  var _z_index = _z_index2Dew();
  var _spacing_set = _spacing_set2Dew();
  var _margin = _margin2Dew();
  var _padding = _padding2Dew();
  var _typography_set = _typography_set2Dew();
  var _font_family = _font_family2Dew();
  var _font_size = _font_size2Dew();
  var _font_style = _font_style2Dew();
  var _font_weight = _font_weight2Dew();
  var _letter_spacing = _letter_spacing2Dew();
  var _line_height = _line_height2Dew();
  var _text_align = _text_align2Dew();
  var _text_color = _text_color2Dew();
  var _text_decoration = _text_decoration2Dew();
  var _text_transform = _text_transform2Dew();
  var _display = _display2Dew();
  var _overflow = _overflow2Dew();
  var _all_styles_set = _all_styles_set2Dew();
  return exports;
}