import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.index-of";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _use_theme2Dew } from "./theme/use_theme";
import { dew as _aria_props2Dew } from "./types/aria_props";
import { dew as _data_attributes_prop2Dew } from "./types/data_attributes_prop";
import { dew as _create_responsive_prop_type2Dew } from "./system/utils/create_responsive_prop_type";
import { dew as _system2Dew } from "./system";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
import { dew as _text2Dew } from "./text";
import { dew as _icon_config2Dew } from "./icon_config";
import { dew as _icon2Dew } from "./icon";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esArray3;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.linkStylePropTypes = void 0;
  var _extends2 = _interopRequireDefault(_extends);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var React = _interopRequireWildcard(_react);
  var _emotion = _emotion2;
  var _core = _core2;
  var _private_utils = _private_utils2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _use_theme = _interopRequireDefault(_use_theme2Dew());
  var _aria_props = _aria_props2Dew();
  var _data_attributes_prop = _data_attributes_prop2Dew();
  var _create_responsive_prop_type = _interopRequireDefault(_create_responsive_prop_type2Dew());
  var _system = _system2Dew();
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  var _text = _text2Dew();
  var _icon_config = _icon_config2Dew();
  var _icon = _interopRequireDefault(_icon2Dew());
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var styleParser = (0, _core.compose)(_system.display, _system.maxWidth, _system.minWidth, _system.width, _system.flexItemSet, _system.positionSet, _system.fontWeight, _system.spacingSet);
  var linkStylePropTypes = _objectSpread({
    display: (0, _create_responsive_prop_type.default)(_propTypes.default.oneOf(["inline-flex", "flex", "none"]))
  }, _system.maxWidthPropTypes, {}, _system.minWidthPropTypes, {}, _system.widthPropTypes, {}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.fontWeightPropTypes, {}, _system.spacingSetPropTypes);
  /**
   * Variants for the {@link Link} component:
   *
   * • **default**
   *
   * Blue text.
   *
   * • **dark**
   *
   * Dark gray text.
   *
   * • **light**
   *
   * Light gray text.
   */

  exports.linkStylePropTypes = linkStylePropTypes;
  var LinkVariant = (0, _private_utils.createEnum)("default", "dark", "light");
  var linkVariantPropType = (0, _private_utils.createPropTypeFromEnum)(LinkVariant);
  /** @internal */

  function useLinkVariant() {
    var variant = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : LinkVariant.default;
    var _useTheme = (0, _use_theme.default)(),
      linkVariants = _useTheme.linkVariants;
    return linkVariants[variant];
  }
  /**
   * Props for the {@link Link} component. Also supports:
   * * {@link AriaProps}
   * * {@link LinkStyleProps}
   *
   * @docsPath UI/components/Link
   * @noInheritDoc
   */

  // A "reasonable" scheme is one which does not have any escaped characters.
  // This means if it passes this regex, we can confidently check to make sure the
  // scheme is not "javascript://" to avoid XSS. Otherwise, "javascript" may be encoded
  // as "&#106avascript://" or any other permutation of escaped characters.
  // Ref: https://tools.ietf.org/html/rfc3986#section-3.1
  var reasonableUrlSchemeRegex = /^[a-z0-9]+:\/\//i;
  /** @internal */

  function _getSanitizedHref(href) {
    if (!href) {
      return undefined;
    }
    var hasScheme = href.indexOf("://") !== -1;
    if (!hasScheme) {
      // If it's a relative URL (like '/foo'), leave it alone.
      return href;
    } else if (reasonableUrlSchemeRegex.test(href) && !/^javascript:/i.test(href) && !/^data:/i.test(href)) {
      // If it has a scheme and we can be 100% sure the scheme is
      // not javascript or data, then leave it alone.
      return href;
    } else {
      // We can't be confident that the scheme isn't javascript or data,
      // (possibly with escaped characters), so prepend http:// to avoid
      // XSS.
      return "http://" + href;
    }
  }
  /**
   * A styled link component with security benefits.
   *
   * [[ Story id="link--example" title="Link example" ]]
   *
   * ## Security benefits
   *
   * This component is a styled wrapper around the `<a>` tag that offers a few security benefits:
   *
   * - Limited XSS protection. If the `href` starts with `javascript:` or `data:`, `http://` will be prepended.
   * - There is [reverse tabnabbing prevention](https://www.owasp.org/index.php/Reverse_Tabnabbing). If `target` is set, the `rel` attribute will be set to `noopener noreferrer`.
   *
   * Developers should use `Link` instead of `a` when possible.
   *
   * @docsPath UI/components/Link
   * @component
   */

  var Link = (props, ref) => {
    var _props$size = props.size,
      size = _props$size === void 0 ? _text.TextSize.default : _props$size,
      _props$variant = props.variant,
      variant = _props$variant === void 0 ? LinkVariant.default : _props$variant,
      _props$underline = props.underline,
      underline = _props$underline === void 0 ? false : _props$underline,
      icon = props.icon,
      href = props.href,
      id = props.id,
      target = props.target,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onClick = props.onClick,
      hasOnClick = props.hasOnClick,
      tabIndex = props.tabIndex,
      className = props.className,
      style = props.style,
      children = props.children,
      dataAttributes = props.dataAttributes,
      ariaLabel = props["aria-label"],
      ariaLabelledBy = props["aria-labelledby"],
      ariaDescribedBy = props["aria-describedby"],
      ariaControls = props["aria-controls"],
      ariaExpanded = props["aria-expanded"],
      ariaHasPopup = props["aria-haspopup"],
      ariaHidden = props["aria-hidden"],
      ariaLive = props["aria-live"],
      styleProps = (0, _objectWithoutProperties2.default)(props, ["size", "variant", "underline", "icon", "href", "id", "target", "onMouseEnter", "onMouseLeave", "onClick", "hasOnClick", "tabIndex", "className", "style", "children", "dataAttributes", "aria-label", "aria-labelledby", "aria-describedby", "aria-controls", "aria-expanded", "aria-haspopup", "aria-hidden", "aria-live"]);
    var classNameForTextStyle = (0, _text.useTextStyle)(size);
    var classNameForLinkVariant = useLinkVariant(variant);
    var classNameForUnderline = (0, _use_styled_system.default)({
      textDecoration: underline ? "underline" : "none"
    });
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({
      display: "inline-flex",
      // Use a negative margin to undo the padding.
      padding: "0 0.1em",
      margin: "0 -0.1em",
      maxWidth: "100%"
    }, styleProps), styleParser); // Set rel="noopener noreferrer" to avoid reverse tabnabbing.
    // https://www.owasp.org/index.php/Reverse_Tabnabbing

    var rel = target ? "noopener noreferrer" : undefined;
    return React.createElement("a", (0, _extends2.default)({
      ref: ref,
      href: _getSanitizedHref(href),
      target: target,
      id: id,
      rel: rel // TODO (stephen): remove tooltip anchor props
      ,

      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onClick: onClick,
      tabIndex: tabIndex,
      className: (0, _emotion.cx)(classNameForTextStyle, classNameForLinkVariant, classNameForUnderline, classNameForStyleProps, className),
      style: style,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy,
      "aria-controls": ariaControls,
      "aria-expanded": ariaExpanded,
      "aria-haspopup": ariaHasPopup,
      "aria-hidden": ariaHidden,
      "aria-live": ariaLive
    }, dataAttributes), typeof icon === "string" ? React.createElement(_icon.default, {
      name: icon,
      size: "1em",
      flex: "none",
      marginRight: "0.5em"
    }) : icon, children);
  };
  var ForwardedRefLink = React.forwardRef(Link);
  ForwardedRefLink.propTypes = _objectSpread({
    size: _text.textSizePropType,
    variant: linkVariantPropType,
    icon: _propTypes.default.oneOfType([_icon_config.iconNamePropType, _propTypes.default.element]),
    href: _propTypes.default.string.isRequired,
    target: _propTypes.default.string,
    tabIndex: _propTypes.default.number,
    className: _propTypes.default.string,
    style: _propTypes.default.object,
    children: _propTypes.default.node,
    dataAttributes: _data_attributes_prop.dataAttributesPropType
  }, _tooltip_anchor_props.tooltipAnchorPropTypes, {}, linkStylePropTypes, {}, _aria_props.ariaPropTypes);
  ForwardedRefLink.displayName = "Link";
  var _default = ForwardedRefLink;
  exports.default = _default;
  return exports;
}