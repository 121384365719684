import * as _esObject2 from "core-js/modules/es.object.to-string";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.promise";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  _esObject;
  _es;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadCSSFromString = loadCSSFromString;
  exports.loadCSSFromURLAsync = loadCSSFromURLAsync;
  exports.loadScriptFromURLAsync = loadScriptFromURLAsync;
  var _error_utils = _error_utils2Dew();

  /** @module @airtable/blocks/ui: Loading external resources */

  /** */

  /**
   * Injects CSS from a string into the page. Returns the HTML style element inserted into the page.
   *
   * @param css The CSS string.
   * @example
   * ```js
   * import {loadCSSFromString} from '@airtable/blocks/ui';
   * loadCSSFromString('body { background: red; }');
   * ```
   * @docsPath UI/utils/loadCSSFromString
   */
  function loadCSSFromString(css) {
    var styleTag = document.createElement("style");
    styleTag.innerHTML = css;
    (0, _error_utils.invariant)(document.head, "no document head");
    document.head.appendChild(styleTag);
    return styleTag;
  }
  /**
   * Injects CSS from a remote URL.
   *
   * Returns a promise that resolves to the HTML style element inserted into the page.
   *
   * @param url The URL of the stylesheet.
   * @example
   * ```js
   * import {loadCSSFromURLAsync} from '@airtable/blocks/ui';
   * loadCSSFromURLAsync('https://example.com/style.css');
   * ```
   * @docsPath UI/utils/loadCSSFromURLAsync
   */

  function loadCSSFromURLAsync(url) {
    // Pre-create the error for a nicer stack trace.
    var loadError = new Error("Failed to load remote CSS: " + url);
    return new Promise((resolve, reject) => {
      var linkTag = document.createElement("link");
      linkTag.setAttribute("rel", "stylesheet");
      linkTag.setAttribute("href", url);
      linkTag.addEventListener("load", () => {
        resolve(linkTag);
      });
      linkTag.addEventListener("error", () => {
        reject(loadError);
      });
      (0, _error_utils.invariant)(document.head, "no document head");
      document.head.appendChild(linkTag);
    });
  }
  /**
   * Injects Javascript from a remote URL.
   *
   * Returns a promise that resolves to the HTML script element inserted into the page.
   *
   * @param url The URL of the script.
   * @example
   * ```js
   * import {loadScriptFromURLAsync} from '@airtable/blocks/ui';
   * loadScriptFromURLAsync('https://example.com/script.js');
   * ```
   * @docsPath UI/utils/loadScriptFromURLAsync
   */

  function loadScriptFromURLAsync(url) {
    // Pre-create the error for a nicer stack trace.
    var loadError = new Error("Failed to load remote script: " + url);
    return new Promise((resolve, reject) => {
      var scriptTag = document.createElement("script");
      scriptTag.addEventListener("load", () => {
        resolve(scriptTag);
      });
      scriptTag.addEventListener("error", () => {
        reject(loadError);
      });
      scriptTag.setAttribute("src", url);
      (0, _error_utils.invariant)(document.head, "no document head");
      document.head.appendChild(scriptTag);
    });
  }
  return exports;
}