import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _global_config_synced_component_helpers2Dew } from "./global_config_synced_component_helpers";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = useSynced;
  var _global_config_synced_component_helpers = _interopRequireDefault(_global_config_synced_component_helpers2Dew());
  var _sdk_context = _sdk_context2Dew();

  /**
   * A hook for syncing a component to {@link GlobalConfig}.
   * Useful if you are dealing with a custom input component and can‘t use one of our `Synced` components.
   *
   * @param globalConfigKey
   * @example
   * ```js
   * import {useBase, useSynced} from '@airtable/blocks/ui';
   *
   * function CustomInputSynced() {
   *    const [value, setValue, canSetValue] = useSynced('myGlobalConfigKey');
   *
   *     return (
   *         <input
   *              type="text"
   *              value={value}
   *              onChange={e => setValue(e.target.value)}
   *              disabled={!canSetValue}
   *          />
   *     );
   * }
   * ```
   * @docsPath UI/hooks/useSynced
   * @hook
   */
  function useSynced(globalConfigKey) {
    _global_config_synced_component_helpers.default.useDefaultWatchesForSyncedComponent(globalConfigKey);
    var sdk = (0, _sdk_context.useSdk)();
    var globalConfig = sdk.globalConfig;
    var value = globalConfig.get(globalConfigKey);
    var canSetValue = globalConfig.hasPermissionToSet(globalConfigKey);
    function setValue(newValue) {
      sdk.globalConfig.setAsync(globalConfigKey, newValue);
    }
    return [value, setValue, canSetValue];
  }
  return exports;
}