import * as _esArray2 from "core-js/modules/es.array.map";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  _esArray;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;

  /** @module @airtable/blocks/ui: expandRecord */

  /** */

  /**
   * Options object for expanding a record.
   */

  /**
   * Expands the given record in the Airtable UI.
   *
   * @param record The record to expand.
   * @param opts An optional options object.
   *
   * @example
   * ```js
   * import {expandRecord} from '@airtable/blocks/ui';
   * expandRecord(record1, {
   *     records: [record1, record2, record3],
   * });
   * ```
   * @docsPath UI/utils/expandRecord
   */
  function expandRecord(record, opts) {
    // TODO(kasra): this will cause the liveapp page to force a refresh if the
    // tableId and recordId are both valid, but the recordId does not
    // exist in the table.
    var recordIds = null;
    if (opts && opts.records) {
      recordIds = opts.records.map(r => r.id);
    }
    record.parentTable.parentBase.__sdk.__airtableInterface.expandRecord(record.parentTable.id, record.id, recordIds);
  }
  var _default = expandRecord;
  exports.default = _default;
  return exports;
}