import * as _esArray2 from "core-js/modules/es.array.map";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  _esArray;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _error_utils = _error_utils2Dew();

  /** @module @airtable/blocks/ui: expandRecordList */

  /** */

  /**
   * Expands a list of records in the Airtable UI.
   *
   * @param records The records to expand. Duplicate records will be removed.
   * @param opts An optional options object.
   *
   * @example
   * ```js
   * import {expandRecordList} from '@airtable/blocks/ui';
   * expandRecordList([record1, record2, record3]);
   *
   * expandRecordList([record1, record2], {
   *     fields: [field1, field2],
   * });
   * ```
   * @docsPath UI/utils/expandRecordList
   */
  function expandRecordList(records, opts) {
    if (records.length === 0) {
      return;
    }
    var tableId = records[0].parentTable.id;
    var recordIds = records.map(record => {
      (0, _error_utils.invariant)(record.parentTable.id === tableId, "all records must belong to the same table");
      return record.id;
    });
    var fieldIds = opts && opts.fields ? opts.fields.map(field => {
      (0, _error_utils.invariant)(field.parentTable.id === tableId, "all fields must belong to the same table");
      return field.id;
    }) : null;
    records[0].parentTable.parentBase.__sdk.__airtableInterface.expandRecordList(tableId, recordIds, fieldIds);
  }
  var _default = expandRecordList;
  exports.default = _default;
  return exports;
}