import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import { dew as _tokens2Dew } from "./tokens";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _emotion = _emotion2;
  var _tokens = _tokens2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var styleForChevron = {
    // https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
    // You can use https://www.npmjs.com/package/mini-svg-data-uri to generate the `background-image` from a svg.
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='7' height='6' viewBox='0 0 7 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M.601.8h4.8a.6.6 0 01.48.96l-2.4 3.2a.6.6 0 01-.96 0l-2.4-3.2A.6.6 0 01.601.8z' fill='rgba(0%2c 0%2c 0%2c 0.5)'/%3e%3c/svg%3e\")",
    backgroundRepeat: "no-repeat"
  };
  var baseStyles = (0, _emotion.css)(_objectSpread({
    fontFamily: _tokens.fontFamilies.default,
    borderRadius: _tokens.radii.default,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 400,
    outline: "none",
    appearance: "none",
    border: "none"
  }, styleForChevron, {
    "&:not(:disabled)": {
      cursor: "pointer",
      "&:hover": {
        opacity: _tokens.opacities.quiet
      },
      "&:active": {
        opacity: 1
      },
      "&:focus": {
        boxShadow: "inset 0 0 0 2px ".concat(_tokens.colors.darken3)
      }
    },
    "&:disabled": {
      cursor: "default",
      opacity: _tokens.opacities.quieter
    }
  }));
  var selectVariants = {
    default: (0, _emotion.cx)(baseStyles, (0, _emotion.css)({
      color: _tokens.colors.dark,
      backgroundColor: _tokens.colors.lightGray2
    }))
  };
  var _default = selectVariants;
  exports.default = _default;
  return exports;
}