import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tooltipAnchorPropTypes = void 0;
  var _propTypes = _interopRequireDefault(_propTypes2);
  var tooltipAnchorPropTypes = {
    onMouseEnter: _propTypes.default.func,
    onMouseLeave: _propTypes.default.func,
    onClick: _propTypes.default.func,
    hasOnClick: _propTypes.default.bool
  };
  exports.tooltipAnchorPropTypes = tooltipAnchorPropTypes;
  return exports;
}