import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _slicedToArray3 from "@babel/runtime/helpers/slicedToArray";
var _slicedToArray = _slicedToArray3;
try {
  if ("default" in _slicedToArray3) _slicedToArray = _slicedToArray3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _react3 from "react";
var _react2 = _react3;
try {
  if ("default" in _react3) _react2 = _react3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _box2Dew } from "./box";
import { dew as _text2Dew } from "./text";
import { dew as _label2Dew } from "./label";
import { dew as _use_form_field2Dew } from "./use_form_field";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _system2Dew } from "./system";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esSymbol;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.formFieldStylePropTypes = void 0;
  var _extends2 = _interopRequireDefault(_extends);
  var _slicedToArray2 = _interopRequireDefault(_slicedToArray);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _react = _interopRequireWildcard(_react2);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var _core = _core2;
  var _private_utils = _private_utils2Dew();
  var _box = _interopRequireDefault(_box2Dew());
  var _text = _interopRequireWildcard(_text2Dew());
  var _label = _interopRequireDefault(_label2Dew());
  var _use_form_field = _use_form_field2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _system = _system2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var styleParser = (0, _core.compose)(_system.maxWidth, _system.minWidth, _system.width, _system.flexItemSet, _system.positionSet, _system.spacingSet);
  var formFieldStylePropTypes = _objectSpread({}, _system.maxWidthPropTypes, {}, _system.minWidthPropTypes, {}, _system.widthPropTypes, {}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.spacingSetPropTypes);
  /**
   * Props for the {@link FormField} component. Also accepts:
   * * {@link FormFieldStyleProps}
   *
   * @noInheritDoc
   * @docsPath UI/components/FormField
   */

  exports.formFieldStylePropTypes = formFieldStylePropTypes;

  /**
   * A form field component that wraps any control field, supplying a provided label and optional
   * description.
   *
   * [[ Story id="formfield--example" title="Form field example" ]]
   *
   * This component will automatically set up the `for` attribute on the outputted label with the `id` attribute
   * on the wrapped control field for the following UI components: Label, Select, FieldPicker,
   * ModelPicker, and ViewPicker. If you'd like to manually override this behavior, you can provide an
   * `htmlFor` prop to this component and manually set the `id` attribute on your wrapped control to
   * the same value.
   *
   * @docsPath UI/components/FormField
   * @component
   */
  var FormField = (props, ref) => {
    var id = props.id,
      className = props.className,
      style = props.style,
      _props$label = props.label,
      label = _props$label === void 0 ? _text.TextSize.default : _props$label,
      htmlFor = props.htmlFor,
      description = props.description,
      children = props.children,
      styleProps = (0, _objectWithoutProperties2.default)(props, ["id", "className", "style", "label", "htmlFor", "description", "children"]);
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({
      width: "100%"
    }, styleProps), styleParser);
    var _useState = (0, _react.useState)((0, _private_utils.getLocallyUniqueId)("form-field-")),
      _useState2 = (0, _slicedToArray2.default)(_useState, 1),
      generatedId = _useState2[0];
    var controlId = htmlFor || generatedId;
    var _useState3 = (0, _react.useState)((0, _private_utils.getLocallyUniqueId)("input-description-")),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 1),
      generatedDescriptionId = _useState4[0];
    var descriptionId = description ? generatedDescriptionId : "";
    var optionalLabelProps;
    if (description) {
      optionalLabelProps = {
        marginBottom: 1
      };
    }
    return _react.default.createElement(_box.default, {
      ref: ref,
      id: id,
      className: (0, _emotion.cx)(classNameForStyleProps, className),
      style: style,
      display: "flex",
      flexDirection: "column",
      marginBottom: 3
    }, _react.default.createElement(_label.default, (0, _extends2.default)({
      htmlFor: controlId,
      size: "default"
    }, optionalLabelProps), label), description && _react.default.createElement(_text.default, {
      variant: "paragraph",
      id: descriptionId,
      size: "default",
      textColor: "light",
      marginBottom: "6px"
    }, description), _react.default.createElement(_use_form_field.FormFieldContext.Provider, {
      value: {
        controlId,
        descriptionId
      }
    }, children));
  };
  var ForwardedRefFormField = _react.default.forwardRef(FormField);
  ForwardedRefFormField.propTypes = _objectSpread({
    id: _propTypes.default.string,
    className: _propTypes.default.string,
    style: _propTypes.default.object,
    label: _propTypes.default.node,
    htmlFor: _propTypes.default.string,
    description: _propTypes.default.node,
    children: _propTypes.default.node
  }, formFieldStylePropTypes);
  ForwardedRefFormField.displayName = "FormField";
  var _default = ForwardedRefFormField;
  exports.default = _default;
  return exports;
}