import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var React = _interopRequireWildcard(_react);
  var _sdk_context = _sdk_context2Dew();

  /**
   * A React Provider which allows Components to be rendered outside of an App's
   * React tree.
   *
   * @example
   * ```js
   * import React from 'react';
   * import ReactDOM from 'react-dom';
   * import {BaseProvider} from '@airtable/blocks/ui';
   *
   * function getHtmlStringForRecordCard(base, record) {
   *     return ReactDOM.renderToStaticMarkup(
   *         <BaseProvider value={base}>
   *             <RecordCard record={record} />
   *         </BaseProvider>
   *     );
   * }
   * ```
   * @docsPath UI/components/BaseProvider
   * @component
   */
  function BaseProvider(props) {
    var value = props.value,
      children = props.children;
    var sdk = value.__sdk;
    return React.createElement(_sdk_context.SdkContext.Provider, {
      value: sdk
    }, children);
  }
  var _default = BaseProvider;
  exports.default = _default;
  return exports;
}