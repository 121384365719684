import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.iterator";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esArray6 from "core-js/modules/es.array.map";
var _esArray5 = _esArray6;
try {
  if ("default" in _esArray6) _esArray5 = _esArray6.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _webDomCollections4 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections3 = _webDomCollections4;
try {
  if ("default" in _webDomCollections4) _webDomCollections3 = _webDomCollections4.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
import { dew as _table2Dew } from "../models/table";
import { dew as _view2Dew } from "../models/view";
import { dew as _field2Dew } from "../models/field";
import { dew as _select2Dew } from "./select";
import { dew as _use_watchable2Dew } from "./use_watchable";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esArray3;
  _esArray5;
  _esObject;
  _webDomCollections;
  _webDomCollections3;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _extends2 = _interopRequireDefault(_extends);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var React = _interopRequireWildcard(_react);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _error_utils = _error_utils2Dew();
  var _table = _interopRequireDefault(_table2Dew());
  var _view = _interopRequireDefault(_view2Dew());
  var _field = _interopRequireDefault(_field2Dew());
  var _select = _interopRequireWildcard(_select2Dew());
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  function ModelPickerSelect(props, ref) {
    var models = props.models,
      modelKeysToWatch = props.modelKeysToWatch,
      selectedModelId = props.selectedModelId,
      shouldAllowPickingNone = props.shouldAllowPickingNone,
      shouldAllowPickingModelFn = props.shouldAllowPickingModelFn,
      placeholder = props.placeholder,
      onChange = props.onChange,
      restOfProps = (0, _objectWithoutProperties2.default)(props, ["models", "modelKeysToWatch", "selectedModelId", "shouldAllowPickingNone", "shouldAllowPickingModelFn", "placeholder", "onChange"]); // useWatchable has stricter typing than createDataContainer which it replaced, so we can't
    // know that model and modelKeysToWatch are exactly compatible here:

    (0, _use_watchable.default)(models, modelKeysToWatch);
    function _onChange(value) {
      (0, _error_utils.invariant)(value === null || typeof value === "string", "value must be null or model id");
      onChange(value);
    }
    var options = [{
      value: null,
      label: placeholder,
      disabled: !shouldAllowPickingNone
    }, ...models.map(model => {
      return {
        value: model.id,
        label: model.name,
        disabled: shouldAllowPickingModelFn && !shouldAllowPickingModelFn(model)
      };
    })];
    return React.createElement(_select.default, (0, _extends2.default)({}, restOfProps, {
      ref: ref,
      value: selectedModelId,
      onChange: _onChange,
      options: options
    }));
  }
  var ForwardedRefModelPickerSelect = React.forwardRef(ModelPickerSelect);
  ForwardedRefModelPickerSelect.displayName = "ModelPickerSelect";
  ForwardedRefModelPickerSelect.propTypes = _objectSpread({
    models: _propTypes.default.arrayOf(_propTypes.default.oneOfType([_propTypes.default.instanceOf(_table.default).isRequired, _propTypes.default.instanceOf(_view.default).isRequired, _propTypes.default.instanceOf(_field.default).isRequired]).isRequired).isRequired,
    selectedModelId: _propTypes.default.string,
    modelKeysToWatch: _propTypes.default.arrayOf(_propTypes.default.string.isRequired).isRequired,
    shouldAllowPickingNone: _propTypes.default.bool,
    shouldAllowPickingModelFn: _propTypes.default.func,
    onChange: _propTypes.default.func.isRequired,
    placeholder: _propTypes.default.string.isRequired
  }, _select.sharedSelectBasePropTypes);
  var _default = ForwardedRefModelPickerSelect;
  exports.default = _default;
  return exports;
}