import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _react3 from "react";
var _react2 = _react3;
try {
  if ("default" in _react3) _react2 = _react3.default;
} catch (e) {}
import { dew as _theme_context2Dew } from "./theme_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _react = _react2;
  var _theme_context = _interopRequireDefault(_theme_context2Dew());
  var useTheme = () => (0, _react.useContext)(_theme_context.default);
  var _default = useTheme;
  exports.default = _default;
  return exports;
}