import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _view2Dew } from "../models/view";
import { dew as _use_loadable2Dew } from "./use_loadable";
import { dew as _use_watchable2Dew } from "./use_watchable";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _view = _interopRequireDefault(_view2Dew());
  var _use_loadable = _interopRequireDefault(_use_loadable2Dew());
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());

  /** @module @airtable/blocks/ui: useViewMetadata */

  /** */

  /**
   * Returns a {@link ViewMetadataQueryResult} for the specified view and re-renders whenever the
   * view metadata changes. Suspends if the view is not already loaded.
   *
   * @param viewOrViewMetadataQueryResult The {@link View} or {@link ViewMetadataQueryResult} to watch and use metadata from.
   * @example
   * ```js
   * import {useBase, useViewMetadata} from '@airtable/blocks/ui';
   *
   * function ViewFields({view}) {
   *     const viewMetadata = useViewMetadata(view);
   *
   *     return (
   *         <ul>
   *             {viewMetadata.visibleFields.map(field => (
   *                 <li key={field.id}>{field.name}</li>
   *             ))}
   *         </ul>
   *     );
   * }
   * ```
   * @docsPath UI/hooks/useViewMetadata
   * @hook
   */
  function useViewMetadata(viewOrViewMetadataQueryResult) {
    var queryResult = viewOrViewMetadataQueryResult instanceof _view.default ? viewOrViewMetadataQueryResult.selectMetadata() : viewOrViewMetadataQueryResult;
    (0, _use_loadable.default)(queryResult !== null && queryResult !== void 0 ? queryResult : null);
    (0, _use_watchable.default)(queryResult, ["allFields", "visibleFields", "groupLevels"]);
    return queryResult !== null && queryResult !== void 0 ? queryResult : null;
  }
  var _default = useViewMetadata;
  exports.default = _default;
  return exports;
}