import * as _react3 from "react";
var _react2 = _react3;
try {
  if ("default" in _react3) _react2 = _react3.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = useArrayIdentity;
  var _react = _react2;

  /**
   * As long as the array passed to this hook shallow-equals its previous value, this hook will
   * return the same array instance. This is useful when writing hooks that accept arrays - it might
   * be convenient for the consumer of the hook to write their array inline, but that won't have
   * have referential equality which might make using the array with `useEffect` or similar tricky.
   * `useArrayIdentity` can wrap that array, making it easier to use with hooks like `useEffect`.
   *
   * @internal
   */
  function useArrayIdentity(array) {
    var arrayRef = (0, _react.useRef)(array);
    if (arrayRef.current !== array) {
      if (arrayRef.current.length !== array.length) {
        arrayRef.current = array;
      } else {
        for (var i = 0; i < array.length; i++) {
          if (arrayRef.current[i] !== array[i]) {
            arrayRef.current = array;
            break;
          }
        }
      }
    }
    return arrayRef.current;
  }
  return exports;
}