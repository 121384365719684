import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.concat";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.filter";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esArray6 from "core-js/modules/es.array.iterator";
var _esArray5 = _esArray6;
try {
  if ("default" in _esArray6) _esArray5 = _esArray6.default;
} catch (e) {}
import * as _esArray8 from "core-js/modules/es.array.map";
var _esArray7 = _esArray8;
try {
  if ("default" in _esArray8) _esArray7 = _esArray8.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _esObject4 from "core-js/modules/es.object.to-string";
var _esObject3 = _esObject4;
try {
  if ("default" in _esObject4) _esObject3 = _esObject4.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _webDomCollections4 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections3 = _webDomCollections4;
try {
  if ("default" in _webDomCollections4) _webDomCollections3 = _webDomCollections4.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _error_utils2Dew } from "../error_utils";
import { dew as _use_form_field2Dew } from "./use_form_field";
import { dew as _system2Dew } from "./system";
import { dew as _use_theme2Dew } from "./theme/use_theme";
import { dew as _tooltip_anchor_props2Dew } from "./types/tooltip_anchor_props";
import { dew as _select_and_select_buttons_helpers2Dew } from "./select_and_select_buttons_helpers";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _control_sizes2Dew } from "./control_sizes";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esSymbol;
  _esArray;
  _esArray3;
  _esArray5;
  _esArray7;
  _esObject;
  _esObject3;
  _webDomCollections;
  _webDomCollections3;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.sharedSelectPropTypes = exports.sharedSelectBasePropTypes = exports.selectStylePropTypes = void 0;
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _emotion = _emotion2;
  var React = _interopRequireWildcard(_react);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _core = _core2;
  var _private_utils = _private_utils2Dew();
  var _error_utils = _error_utils2Dew();
  var _use_form_field = _interopRequireDefault(_use_form_field2Dew());
  var _system = _system2Dew();
  var _use_theme = _interopRequireDefault(_use_theme2Dew());
  var _tooltip_anchor_props = _tooltip_anchor_props2Dew();
  var _select_and_select_buttons_helpers = _select_and_select_buttons_helpers2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _control_sizes = _control_sizes2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var SelectVariant = (0, _private_utils.createEnum)("default");
  /** @internal */

  function useSelectVariant() {
    var variant = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : SelectVariant.default;
    var _useTheme = (0, _use_theme.default)(),
      selectVariants = _useTheme.selectVariants;
    return selectVariants[variant];
  } // This component isn't great right now. It's just a styled <select> with a really hacky
  // way of getting the chevron arrow to show up. It also behaves weirdly when you give it
  // a margin (I think this is a limitation of <select>). We should probably replace it with
  // something like react-select, which would give us nice features like rendering custom
  // elements for options (e.g. for field type icons) and typeahead search.

  /**
   * Style props shared between the following components.
   * * {@link Select}, {@link SelectSynced}
   * * {@link TablePicker}, {@link TablePickerSynced}
   * * {@link ViewPicker}, {@link ViewPickerSynced}
   * * {@link FieldPicker}, {@link FieldPickerSynced}
   *
   * Also accepts:
   * * {@link FlexItemSetProps}
   * * {@link MarginProps}
   * * {@link MaxWidthProps}
   * * {@link MinWidthProps}
   * * {@link PositionSetProps}
   * * {@link MaxWidthProps}
   *
   * @noInheritDoc
   */

  var selectStylePropTypes = _objectSpread({}, _system.maxWidthPropTypes, {}, _system.minWidthPropTypes, {}, _system.widthPropTypes, {}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.marginPropTypes); // Shared with `Select`, `SelectSynced`, `ModelPickerSelect` and `(Table/View/Field)Picker(Synced)`

  exports.selectStylePropTypes = selectStylePropTypes;
  var sharedSelectBasePropTypes = _objectSpread({
    size: _control_sizes.controlSizePropType,
    autoFocus: _propTypes.default.bool,
    disabled: _propTypes.default.bool,
    id: _propTypes.default.string,
    name: _propTypes.default.string,
    tabIndex: _propTypes.default.number,
    className: _propTypes.default.string,
    style: _propTypes.default.object,
    "aria-label": _propTypes.default.string,
    "aria-labelledby": _propTypes.default.string,
    "aria-describedby": _propTypes.default.string
  }, _tooltip_anchor_props.tooltipAnchorPropTypes, {}, selectStylePropTypes);
  /**
   * Props shared between the {@link Select} and {@link SelectSynced} components.
   */

  exports.sharedSelectBasePropTypes = sharedSelectBasePropTypes;

  // Shared with `Select` and `SelectSynced`.
  var sharedSelectPropTypes = _objectSpread({
    // We do more strict checks in render.
    options: _propTypes.default.arrayOf(_propTypes.default.shape({
      value: _select_and_select_buttons_helpers.selectOptionValuePropType,
      label: _propTypes.default.node.isRequired,
      disabled: _propTypes.default.bool
    }).isRequired).isRequired,
    onChange: _propTypes.default.func
  }, sharedSelectBasePropTypes);
  /**
   * Props for the {@link Select} component. Also accepts:
   * * {@link SelectStyleProps}
   *
   * @docsPath UI/components/Select
   */

  exports.sharedSelectPropTypes = sharedSelectPropTypes;
  var styleParser = (0, _core.compose)(_system.maxWidth, _system.minWidth, _system.width, _system.flexItemSet, _system.positionSet, _system.margin);
  /**
   * Dropdown menu component. A wrapper around `<select>` that fits in with Airtable's user interface.
   *
   * [[ Story id="select--example" title="Select example" ]]
   *
   * @component
   * @docsPath UI/components/Select
   */

  var Select = (props, ref) => {
    var _props$size = props.size,
      size = _props$size === void 0 ? _control_sizes.ControlSize.default : _props$size,
      value = props.value,
      _props$options = props.options,
      originalOptions = _props$options === void 0 ? [] : _props$options,
      autoFocus = props.autoFocus,
      disabled = props.disabled,
      id = props.id,
      name = props.name,
      tabIndex = props.tabIndex,
      onChange = props.onChange,
      onMouseEnter = props.onMouseEnter,
      onMouseLeave = props.onMouseLeave,
      onClick = props.onClick,
      hasOnClick = props.hasOnClick,
      className = props.className,
      style = props.style,
      ariaLabel = props["aria-label"],
      ariaDescribedByProp = props["aria-describedby"],
      ariaLabelledBy = props["aria-labelledby"],
      styleProps = (0, _objectWithoutProperties2.default)(props, ["size", "value", "options", "autoFocus", "disabled", "id", "name", "tabIndex", "onChange", "onMouseEnter", "onMouseLeave", "onClick", "hasOnClick", "className", "style", "aria-label", "aria-describedby", "aria-labelledby"]);
    var formFieldContextValue = (0, _use_form_field.default)();
    var controlId = formFieldContextValue ? formFieldContextValue.controlId : undefined;
    var descriptionId = formFieldContextValue ? formFieldContextValue.descriptionId : undefined;
    var ariaDescribedBy = [ariaDescribedByProp, descriptionId].filter(Boolean).join(" ") || undefined; // There is only a single default variant.

    var classNameForSelectVariant = useSelectVariant();
    var classNameForSelectSize = (0, _control_sizes.useSelectSize)(size);
    var classNameForStyleProps = (0, _use_styled_system.default)(_objectSpread({
      width: "100%"
    }, styleProps), styleParser);
    function _onChange(e) {
      if (onChange) {
        var newValue = (0, _select_and_select_buttons_helpers.stringToOptionValue)(e.currentTarget.value);
        onChange(newValue);
      }
    } // Check options here for a cleaner stack trace.
    // Also, even though options are required, still check if it's set because
    // the error is really ugly and covers up the prop type check.

    var validationResult = (0, _select_and_select_buttons_helpers.validateOptions)(originalOptions);
    if (!validationResult.isValid) {
      throw (0, _error_utils.spawnError)("<Select> %s", validationResult.reason);
    }
    var didFindOptionMatchingValue = false;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;
    try {
      for (var _iterator = originalOptions[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var option = _step.value;
        if (option.value === value) {
          didFindOptionMatchingValue = true;
          break;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
    var options = [];
    if (!didFindOptionMatchingValue) {
      // Since there's no option that matches the given value, let's add an
      // empty option at the top and log a warning.
      options.push({
        label: "",
        value,
        disabled: true
      }); // eslint-disable-next-line no-console

      console.warn("No option for selected value in <Select>: ".concat(String(value)).substr(0, 100));
    }
    options.push(...originalOptions);
    return React.createElement("select", {
      ref: ref,
      value: (0, _select_and_select_buttons_helpers.optionValueToString)(value),
      onChange: _onChange,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onClick: onClick,
      autoFocus: autoFocus,
      disabled: disabled,
      id: id || controlId,
      name: name,
      tabIndex: tabIndex,
      className: (0, _emotion.cx)(classNameForSelectVariant, classNameForSelectSize, classNameForStyleProps, className),
      style: style,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy
    }, options.map((option, index) => {
      var valueJson = (0, _select_and_select_buttons_helpers.optionValueToString)(option.value);
      return React.createElement("option", {
        key: "".concat(valueJson, "-").concat(option.label, "-").concat(index),
        value: valueJson,
        disabled: option.disabled
      }, option.label);
    }));
  };
  var ForwardedRefSelect = React.forwardRef(Select);
  ForwardedRefSelect.displayName = "Select";
  ForwardedRefSelect.propTypes = _objectSpread({
    value: _select_and_select_buttons_helpers.selectOptionValuePropType
  }, sharedSelectPropTypes);
  var _default = ForwardedRefSelect;
  exports.default = _default;
  return exports;
}