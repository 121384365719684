import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _sdk_context = _sdk_context2Dew();

  /** @module @airtable/blocks/ui: useSession */

  /** */

  /**
   * A hook for connecting a React component to the current session. This returns a {@link Session}
   * instance and will re-render your component whenever the session changes (e.g. when the current user's
   * permissions change or when the current user's name changes).
   *
   * `useSession` should meet most of your needs for working with {@link Session}. If you need more granular
   * control of when your component updates or want to do anything other than re-render, the lower
   * level {@link useWatchable} hook might help.
   *
   * @example
   * ```js
   * import {CollaboratorToken, useSession} from '@airtable/blocks/ui';
   *
   * // Says hello to the current user and updates in realtime if the current user's
   * // name or profile pic changes.
   * function CurrentUserGreeter() {
   *     const session = useSession();
   *     return (
   *         <React.Fragment>
   *             Hello,
   *             <CollaboratorToken collaborator={session.currentUser} />!
   *         </React.Fragment>
   *     );
   * }
   * ```
   * @docsPath UI/hooks/useSession
   * @hook
   */
  var useSession = () => {
    var _useSdk = (0, _sdk_context.useSdk)(),
      session = _useSdk.session,
      base = _useSdk.base;
    (0, _use_watchable.default)(session, ["permissionLevel", "currentUser"]); // permission checks depend on the base schema, so we need to track that too:

    (0, _use_watchable.default)(base, ["schema"]);
    return session;
  };
  var _default = useSession;
  exports.default = _default;
  return exports;
}