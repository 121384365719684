import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _rect2Dew } from "./rect";
import { dew as _size2Dew } from "./size";
import { dew as _point2Dew } from "./point";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "Rect", {
    enumerable: true,
    get: function get() {
      return _rect.default;
    }
  });
  Object.defineProperty(exports, "Size", {
    enumerable: true,
    get: function get() {
      return _size.default;
    }
  });
  Object.defineProperty(exports, "Point", {
    enumerable: true,
    get: function get() {
      return _point.default;
    }
  });
  var _rect = _interopRequireDefault(_rect2Dew());
  var _size = _interopRequireDefault(_size2Dew());
  var _point = _interopRequireDefault(_point2Dew());
  return exports;
}