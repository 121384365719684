import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _classCallCheck3 from "@babel/runtime/helpers/classCallCheck";
var _classCallCheck = _classCallCheck3;
try {
  if ("default" in _classCallCheck3) _classCallCheck = _classCallCheck3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _classCallCheck2 = _interopRequireDefault(_classCallCheck);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);

  /** @internal */
  var Size = /** @internal */

  /** @internal */

  /** @internal */
  function Size(width, height) {
    (0, _classCallCheck2.default)(this, Size);
    (0, _defineProperty2.default)(this, "width", void 0);
    (0, _defineProperty2.default)(this, "height", void 0);
    this.width = width;
    this.height = height;
  };
  exports.default = Size;
  return exports;
}