import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.iterator";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esArray6 from "core-js/modules/es.array.sort";
var _esArray5 = _esArray6;
try {
  if ("default" in _esArray6) _esArray5 = _esArray6.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _esObject4 from "core-js/modules/es.object.to-string";
var _esObject3 = _esObject4;
try {
  if ("default" in _esObject4) _esObject3 = _esObject4.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _webDomCollections4 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections3 = _webDomCollections4;
try {
  if ("default" in _webDomCollections4) _webDomCollections3 = _webDomCollections4.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _field3Dew } from "../models/field";
import { dew as _table2Dew } from "../models/table";
import { dew as _field4Dew } from "../types/field";
import { dew as _select2Dew } from "./select";
import { dew as _model_picker_select2Dew } from "./model_picker_select";
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esSymbol;
  _esArray;
  _esArray3;
  _esArray5;
  _esObject;
  _esObject3;
  _webDomCollections;
  _webDomCollections3;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.sharedFieldPickerPropTypes = void 0;
  var _extends2 = _interopRequireDefault(_extends);
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var React = _interopRequireWildcard(_react);
  var _private_utils = _private_utils2Dew();
  var _field = _interopRequireDefault(_field3Dew());
  var _table = _interopRequireDefault(_table2Dew());
  var _field2 = _field4Dew();
  var _select = _select2Dew();
  var _model_picker_select = _interopRequireDefault(_model_picker_select2Dew());
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _sdk_context = _sdk_context2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }

  // Shared with `FieldPicker` and `FieldPickerSynced`.
  var sharedFieldPickerPropTypes = _objectSpread({
    table: _propTypes.default.instanceOf(_table.default),
    allowedTypes: _propTypes.default.arrayOf(_propTypes.default.oneOf((0, _private_utils.values)(_field2.FieldType)).isRequired),
    shouldAllowPickingNone: _propTypes.default.bool,
    placeholder: _propTypes.default.string,
    onChange: _propTypes.default.func
  }, _select.sharedSelectBasePropTypes);
  /**
   * Props for the {@link FieldPicker} component. Also accepts:
   * * {@link SelectStyleProps}
   *
   * @docsPath UI/components/FieldPicker
   */

  exports.sharedFieldPickerPropTypes = sharedFieldPickerPropTypes;

  /**
   * Dropdown menu component for selecting fields.
   *
   * [[ Story id="modelpickers--fieldpicker-example" title="Field picker example" ]]
   *
   * @docsPath UI/components/FieldPicker
   * @component
   */
  var FieldPicker = (props, ref) => {
    var table = props.table,
      selectedField = props.field,
      shouldAllowPickingNone = props.shouldAllowPickingNone,
      allowedTypes = props.allowedTypes,
      placeholder = props.placeholder,
      onChange = props.onChange,
      restOfProps = (0, _objectWithoutProperties2.default)(props, ["table", "field", "shouldAllowPickingNone", "allowedTypes", "placeholder", "onChange"]);
    var sdk = (0, _sdk_context.useSdk)();
    (0, _use_watchable.default)(sdk.base, ["tables"]);
    (0, _use_watchable.default)(table, ["fields"]);
    if (!table || table.isDeleted) {
      return null;
    }
    function _onChange(fieldId) {
      if (onChange) {
        var field = table && !table.isDeleted && fieldId ? table.getFieldByIdIfExists(fieldId) : null;
        onChange(field);
      }
    }
    var placeholderToUse;
    if (placeholder === undefined) {
      // Let's set a good default value for the placeholder, depending
      // on the shouldAllowPickingNone flag.
      placeholderToUse = shouldAllowPickingNone ? "None" : "Pick a field...";
    } else {
      placeholderToUse = placeholder;
    }
    var allowedTypesSet = {};
    if (allowedTypes) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;
      try {
        for (var _iterator = allowedTypes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var allowedType = _step.value;
          allowedTypesSet[allowedType] = true;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }
    var shouldAllowPickingFieldFn = field => {
      return !allowedTypes || (0, _private_utils.has)(allowedTypesSet, field.type);
    }; // Fields are only ordered within a view, and views' column orders aren't
    // loaded by default. So we'll always list the primary field first, followed
    // by the rest of the fields in alphabetical order.

    var models = table.fields.filter(field => field !== table.primaryField).sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });
    models.unshift(table.primaryField);
    return React.createElement(_model_picker_select.default, (0, _extends2.default)({}, restOfProps, {
      ref: ref,
      models: models // `shouldAllowPickingModelFn` is typed as `AnyModel`. Cast to any since we only expect `Field`.
      ,

      shouldAllowPickingModelFn: shouldAllowPickingFieldFn,
      selectedModelId: selectedField && !selectedField.isDeleted ? selectedField.id : null,
      modelKeysToWatch: ["name", "type", "options"],
      shouldAllowPickingNone: shouldAllowPickingNone,
      placeholder: placeholderToUse,
      onChange: _onChange
    }));
  };
  var ForwardedRefFieldPicker = React.forwardRef(FieldPicker);
  ForwardedRefFieldPicker.displayName = "FieldPicker";
  ForwardedRefFieldPicker.propTypes = _objectSpread({
    field: _propTypes.default.instanceOf(_field.default)
  }, sharedFieldPickerPropTypes);
  var _default = ForwardedRefFieldPicker;
  exports.default = _default;
  return exports;
}