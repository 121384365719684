import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.iterator";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.map";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.to-string";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _es4 from "core-js/modules/es.promise";
var _es3 = _es4;
try {
  if ("default" in _es4) _es3 = _es4.default;
} catch (e) {}
import * as _esRegexp2 from "core-js/modules/es.regexp.to-string";
var _esRegexp = _esRegexp2;
try {
  if ("default" in _esRegexp2) _esRegexp = _esRegexp2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _react3 from "react";
var _react2 = _react3;
try {
  if ("default" in _react3) _react2 = _react3.default;
} catch (e) {}
import * as _useSubscription3 from "use-subscription";
var _useSubscription2 = _useSubscription3;
try {
  if ("default" in _useSubscription3) _useSubscription2 = _useSubscription3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _error_utils2Dew } from "../error_utils";
import { dew as _use_array_identity2Dew } from "./use_array_identity";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esSymbol;
  _esArray;
  _esArray3;
  _esObject;
  _es3;
  _esRegexp;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = useLoadable;
  var _react = _react2;
  var _useSubscription = _useSubscription2;
  var _private_utils = _private_utils2Dew();
  var _error_utils = _error_utils2Dew();
  var _use_array_identity = _interopRequireDefault(_use_array_identity2Dew());

  /** @module @airtable/blocks/ui: useLoadable */

  /** */
  var SUSPENSE_CLEAN_UP_MS = 60000;
  /**
   * Options object for the {@link useLoadable} hook.
   */

  /**
   * When you're writing an extension, not all of the data in your base is available to work with straight
   * away. We need to load it from Airtable first. This hook is a low-level tool for managing that.
   * You might not need to use it directly though - if you're working with a {@link RecordQueryResult}, try
   * {@link useRecords}, {@link useRecordIds}, or {@link useRecordById} first.
   *
   * When you need to use a loadable model, `useLoadable(theModel)` will make sure that the model is
   * loaded when your component mounts, and unloaded when your component unmounts. By default, you
   * don't need to worry about waiting for the data to load - the hook uses React Suspense to make
   * sure the rest of your component doesn't run until the data is loaded. Whilst the data is
   * loading, the entire extension will show a loading indicator. If you want to change where that
   * indicator shows or how it looks, use [`<React.Suspense />`](https://reactjs.org/docs/react-api.html#reactsuspense|)
   * around the component that uses the hook.
   *
   * You can pass several models to `useLoadable` in an array - it will load all of them simultaneously.
   * We'll memoize this array using shallow equality, so there's no need to use `useMemo`.
   *
   * If you need more control, you can pass `{shouldSuspend: false}` as a second argument to
   * the hook. In that case though, `useLoadable` will cause your component to re-render whenever the
   * load-state of any model you passed in changes, and you should check each model's `.isDataLoaded`
   *  property before trying to use the data you loaded.
   *
   * @param models The models to load.
   * @param opts Optional options to control how the hook works.
   *
   * @example
   * ```js
   * import {useCursor, useLoadable, useWatchable} from '@airtable/blocks/ui';
   *
   *  function SelectedRecordIds() {
   *      const cursor = useCursor();
   *      // load selected records
   *      useLoadable(cursor);
   *
   *      // re-render whenever the list of selected records changes
   *      useWatchable(cursor, ['selectedRecordIds']);
   *
   *      // render the list of selected record ids
   *      return <div>Selected records: {cursor.selectedRecordIds.join(', ')}</div>;
   *  }
   * ```
   *
   * @example
   * ```js
   *  import {useLoadable} from '@airtable/blocks/ui';
   *
   *  function LoadTwoQueryResults({queryResultA, queryResultB}) {
   *      // load the queryResults:
   *      useLoadable([queryResultA, queryResultB]);
   *
   *      // now, we can use the data
   *      return <SomeFancyComponent />;
   *  }
   * ```
   *
   * @example
   * ```js
   *  import {useLoadable, useBase} from '@airtable/blocks/ui';
   *
   *  function LoadAllRecords() {
   *      const base = useBase();
   *
   *      // get a query result for every table in the base:
   *      const queryResults = base.tables.map(table => table.selectRecords());
   *
   *      // load them all:
   *      useLoadable(queryResults);
   *
   *      // use the data:
   *      return <SomeFancyComponent queryResults={queryResults} />;
   *  }
   * ```
   * @docsPath UI/hooks/useLoadable
   * @hook
   */
  function useLoadable(models) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      shouldSuspend: true
    };
    var shouldSuspend = opts && (0, _private_utils.has)(opts, "shouldSuspend") ? opts.shouldSuspend : true;
    var constModels = (0, _use_array_identity.default)(Array.isArray(models) ? models : [models]);
    var compactModels = (0, _react.useMemo)(() => {
      var compacted = (0, _private_utils.compact)(constModels);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;
      try {
        for (var _iterator = compacted[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var model = _step.value;
          if (typeof model.loadDataAsync !== "function") {
            throw (0, _error_utils.spawnError)("useLoadable called with %s, which is not a loadable", typeof model === "object" ? model.toString() : typeof model);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
      return compacted;
    }, [constModels]); // .every returns `true` in the case of an empty array

    var areAllModelsLoaded = compactModels.every(model => model.isDataLoaded);
    if (shouldSuspend && !areAllModelsLoaded) {
      var suspensePromise = Promise.all(compactModels.map(model => model.loadDataAsync())).then(() => {
        // if data isn't loaded and we're in suspense mode, we need to start the data loading and
        // throw the load promise. when we throw though, the render tree gets thrown away and none
        // of out hooks will be retained - so we can't attach this QueryResult to a component
        // lifecycle and use that to unload it. Instead, we load it and keep it loaded for a long
        // enough time that it can resolve and then be rendered successfully. After the timeout has
        // passed, we unload it, allowing the data to be released as long as it's not used anywhere
        // else in the extension.
        setTimeout(() => {
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;
          try {
            for (var _iterator2 = compactModels[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var model = _step2.value;
              model.unloadData();
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }, SUSPENSE_CLEAN_UP_MS);
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      });
      throw suspensePromise;
    } // re-render when loaded state changes. technically, we could use `useWatchable` here, but as
    // our LoadableModel isn't a Watchable, we can't. There's no way to preserve flow errors when
    // watching something that doesn't have a 'isDataLoaded' watch key and use `Watchable`.

    var modelIsLoadedSubscription = (0, _react.useMemo)(() => ({
      getCurrentValue: () => compactModels.map(model => model.isDataLoaded).join(","),
      subscribe: onChange => {
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;
        try {
          for (var _iterator3 = compactModels[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var model = _step3.value;
            model.watch("isDataLoaded", onChange);
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
        return () => {
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;
          try {
            for (var _iterator4 = compactModels[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var model = _step4.value;
              model.unwatch("isDataLoaded", onChange);
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }
        };
      }
    }), [compactModels]);
    (0, _useSubscription.useSubscription)(modelIsLoadedSubscription); // the main part of this hook comes down to managing the query result data loading in sync with
    // the component lifecycle. That means loading the data when the component mounts, and
    // unloading it when the component unmounts.

    (0, _react.useEffect)(() => {
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;
      try {
        for (var _iterator5 = compactModels[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var model = _step5.value;
          model.loadDataAsync();
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }
      return () => {
        var _iteratorNormalCompletion6 = true;
        var _didIteratorError6 = false;
        var _iteratorError6 = undefined;
        try {
          for (var _iterator6 = compactModels[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
            var model = _step6.value;
            model.unloadData();
          }
        } catch (err) {
          _didIteratorError6 = true;
          _iteratorError6 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
              _iterator6.return();
            }
          } finally {
            if (_didIteratorError6) {
              throw _iteratorError6;
            }
          }
        }
      };
    }, [compactModels]);
  }
  return exports;
}