import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import { dew as _tokens2Dew } from "./tokens";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _emotion = _emotion2;
  var _tokens = _tokens2Dew();
  var baseStyles = (0, _emotion.css)({
    alignItems: "center",
    justifyContent: "center",
    borderRadius: _tokens.radii.default,
    outline: "none",
    "&:hover": {
      opacity: _tokens.opacities.quiet
    },
    "&:active": {
      opacity: 1
    },
    "&:focus": {
      boxShadow: "0 0 0 2px ".concat(_tokens.colors.darken3)
    }
  });
  var linkVariants = {
    default: (0, _emotion.cx)(baseStyles, (0, _emotion.css)({
      color: _tokens.colors.blueBright
    })),
    dark: (0, _emotion.cx)(baseStyles, (0, _emotion.css)({
      color: _tokens.colors.dark
    })),
    light: (0, _emotion.cx)(baseStyles, (0, _emotion.css)({
      color: _tokens.colors.light
    }))
  };
  var _default = linkVariants;
  exports.default = _default;
  return exports;
}