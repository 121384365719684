import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _react3 from "react";
var _react2 = _react3;
try {
  if ("default" in _react3) _react2 = _react3.default;
} catch (e) {}
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = useSettingsButton;
  var _react = _react2;
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _sdk_context = _sdk_context2Dew();

  /** @module @airtable/blocks/ui: useSettingsButton */

  /** */

  /**
   * A hook for using the settings button that lives outside the extension's viewport. It will show
   * the settings button (hidden by default) and call the provided callback whenever the settings
   * button is clicked. It will also re-render your component when the settings button is clicked.
   *
   * @param onClickCallback A callback to call when the button is clicked.
   *
   * @example
   * ```js
   * import {useSettingsButton} from '@airtable/blocks/ui';
   * import {useState} from 'react';
   *
   * function ComponentWithSettings() {
   *      const [isShowingSettings, setIsShowingSettings] = useState(false);
   *      useSettingsButton(function() {
   *          setIsShowingSettings(!isShowingSettings);
   *      });
   *
   *      if (isShowingSettings) {
   *          return <SettingsComponent />
   *      }
   *      return <MainComponent />
   * }
   * ```
   * @docsPath UI/hooks/useSettingsButton
   * @hook
   */
  function useSettingsButton(onClickCallback) {
    var _useSdk = (0, _sdk_context.useSdk)(),
      settingsButton = _useSdk.settingsButton;
    (0, _react.useEffect)(() => {
      settingsButton.show(); // Hide the button when the component using this hook is unmounted.

      return () => {
        settingsButton.hide();
      };
    }, [settingsButton]); // Only set the button to be visible once.

    (0, _use_watchable.default)(settingsButton, ["click"], onClickCallback);
  }
  return exports;
}