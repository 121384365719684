import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import { dew as _perform_record_action2Dew } from "./perform_record_action";
import { dew as _warning2Dew } from "./warning";
import { dew as _airtable_interface2Dew } from "./injected/airtable_interface";
import { dew as _sdk3Dew } from "./sdk";
import { dew as _create_aggregators2Dew } from "./models/create_aggregators";
import { dew as _initialize_block2Dew } from "./ui/initialize_block";
import { dew as _uiDew } from "./ui/ui";
import { dew as _modelsDew } from "./models/models";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  var _interopRequireWildcard = _interopRequireWildcard2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.__reset = __reset;
  exports.session = exports.cursor = exports.unstable_fetchAsync = exports.viewport = exports.undoRedo = exports.settingsButton = exports.runInfo = exports.reload = exports.installationId = exports.globalConfig = exports.base = exports.__sdk = void 0;
  var _perform_record_action = _perform_record_action2Dew();
  var _warning = _interopRequireWildcard(_warning2Dew());
  var _airtable_interface = _interopRequireDefault(_airtable_interface2Dew());
  var _sdk2 = _interopRequireDefault(_sdk3Dew());
  var _create_aggregators = _create_aggregators2Dew();
  var _initialize_block = _initialize_block2Dew();

  /** @internal */
  var __sdk;
  exports.__sdk = __sdk;
  var base;
  exports.base = base;
  var globalConfig;
  exports.globalConfig = globalConfig;
  var installationId;
  exports.installationId = installationId;
  var reload;
  exports.reload = reload;
  var runInfo;
  exports.runInfo = runInfo;
  var settingsButton;
  exports.settingsButton = settingsButton;
  var undoRedo;
  exports.undoRedo = undoRedo;
  var viewport;
  exports.viewport = viewport;
  var unstable_fetchAsync; // The `cursor` binding is declared on the following line solely as a signal to
  // the TypeScript compiler. The exported value is actually controlled by the
  // subsequent CommonJS module property descriptor.

  exports.unstable_fetchAsync = unstable_fetchAsync;
  var cursor;
  exports.cursor = cursor;
  Object.defineProperty(exports, "cursor", {
    enumerable: true,
    get() {
      (0, _warning.default)("`import {cursor} from \"@airtable/blocks\"` is deprecated. Use `import {useCursor} from \"@airtable/blocks/ui\"` instead.");
      return __sdk.cursor;
    }
  }); // The `session` binding is declared on the following line solely as a signal
  // to the TypeScript compiler. The exported value is actually controlled by the
  // subsequent CommonJS module property descriptor.

  var session;
  exports.session = session;
  Object.defineProperty(exports, "session", {
    enumerable: true,
    get() {
      (0, _warning.default)("`import {session} from \"@airtable/blocks\"` is deprecated. Use `import {useSession} from \"@airtable/blocks/ui\"` instead.");
      return __sdk.session;
    }
  });
  Object.defineProperty(exports, "UI", {
    enumerable: true,
    get() {
      (0, _warning.default)("`import {UI} from \"@airtable/blocks\"` is deprecated. Use `import * as UI from \"@airtable/blocks/ui/ui\"` instead.");
      return _uiDew();
    }
  });
  Object.defineProperty(exports, "models", {
    enumerable: true,
    get() {
      (0, _warning.default)("`import {models} from \"@airtable/blocks\"` is deprecated. Use `import * as models from \"@airtable/blocks/models/models\"` instead.");
      return _modelsDew();
    }
  });
  /** @internal */

  function __reset() {
    exports.__sdk = __sdk = new _sdk2.default((0, _airtable_interface.default)());
    var _sdk = __sdk;
    exports.base = base = _sdk.base;
    exports.globalConfig = globalConfig = _sdk.globalConfig;
    exports.installationId = installationId = _sdk.installationId;
    exports.reload = reload = _sdk.reload;
    exports.runInfo = runInfo = _sdk.runInfo;
    exports.settingsButton = settingsButton = _sdk.settingsButton;
    exports.undoRedo = undoRedo = _sdk.undoRedo;
    exports.viewport = viewport = _sdk.viewport;
    exports.unstable_fetchAsync = unstable_fetchAsync = _sdk.unstable_fetchAsync;
    // The following injections are necessary to allow the referenced modules
    // to be imported directly by consumer code while also avoiding cycles in
    // the module dependency graph.
    (0, _create_aggregators.__injectSdkIntoCreateAggregators)(__sdk);
    (0, _perform_record_action.__injectSdkIntoPerformRecordAction)(__sdk);
    (0, _initialize_block.__injectSdkIntoInitializeBlock)(__sdk);
    (0, _warning.__injectSdkIntoWarning)(__sdk);
  }
  __reset();
  return exports;
}