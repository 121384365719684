import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _reactDom3 from "react-dom";
var _reactDom2 = _reactDom3;
try {
  if ("default" in _reactDom3) _reactDom2 = _reactDom3.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
import { dew as _airtable_interface3Dew } from "../injected/airtable_interface";
import { dew as _airtable_interface4Dew } from "../types/airtable_interface";
import { dew as _block_wrapper2Dew } from "./block_wrapper";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  var _interopRequireWildcard = _interopRequireWildcard2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initializeBlock = initializeBlock;
  exports.__injectSdkIntoInitializeBlock = __injectSdkIntoInitializeBlock;
  exports.__resetHasBeenInitialized = __resetHasBeenInitialized;
  var React = _interopRequireWildcard(_react);
  var _reactDom = _interopRequireDefault(_reactDom2);
  var _error_utils = _error_utils2Dew();
  var _airtable_interface = _interopRequireDefault(_airtable_interface3Dew());
  var _airtable_interface2 = _airtable_interface4Dew();
  var _block_wrapper = _interopRequireDefault(_block_wrapper2Dew());

  /** @module @airtable/blocks/ui: initializeBlock */

  /** */
  var hasBeenInitialized = false;
  /** */

  /**
   * `initializeBlock` takes the top-level React component in your tree and renders it. It is conceptually similar to `ReactDOM.render`, but takes care of some Extensions-specific things.
   *
   * @param getEntryElement A function that returns your React Node.
   *
   * @example
   * ```js
   * import {initializeBlock} from '@airtable/blocks/ui';
   * import React from 'react';
   *
   * function App() {
   *     return (
   *         <div>Hello world 🚀</div>
   *     );
   * }
   *
   * initializeBlock(() => <App />);
   * ```
   * @docsPath UI/utils/initializeBlock
   */
  function initializeBlock(getEntryElement) {
    var _airtableInterface$sd;
    var entryPoints = typeof getEntryElement === "function" ? {
      dashboard: getEntryElement
    } : getEntryElement;
    var body = typeof document !== "undefined" ? document.body : null;
    if (!body) {
      throw (0, _error_utils.spawnError)("initializeBlock should only be called from browser environments");
    }
    if (hasBeenInitialized) {
      throw (0, _error_utils.spawnError)("initializeBlock should only be called once");
    }
    hasBeenInitialized = true;
    var airtableInterface = (0, _airtable_interface.default)();
    var entryElement; // runContext can be undefined if running from an old version client version (before 01/2021)
    // TODO (SeanKeenan): Remove nullish coelescing once old clients are no longer a concern

    var runContext = (_airtableInterface$sd = airtableInterface.sdkInitData.runContext) !== null && _airtableInterface$sd !== void 0 ? _airtableInterface$sd : {
      type: _airtable_interface2.BlockRunContextType.DASHBOARD_APP
    };
    switch (runContext.type) {
      case _airtable_interface2.BlockRunContextType.DASHBOARD_APP:
        {
          if (entryPoints.dashboard === undefined) {
            throw (0, _error_utils.spawnError)("If running an extension within the dashboard, it must have a dashboard initialization function");
          }
          if (typeof entryPoints.dashboard !== "function") {
            throw (0, _error_utils.spawnError)("initializeBlock must contain a dashboard function that returns a React element");
          }
          entryElement = entryPoints.dashboard();
          break;
        }
      case _airtable_interface2.BlockRunContextType.VIEW:
        {
          if (entryPoints.view === undefined) {
            throw (0, _error_utils.spawnError)("If running an extension within a view, it must have a view initialization function");
          }
          if (typeof entryPoints.view !== "function") {
            throw (0, _error_utils.spawnError)("initializeBlock must contain a view function that returns a React element");
          }
          var table = sdk.base.getTableById(runContext.tableId);
          var view = table.getViewById(runContext.viewId);
          entryElement = entryPoints.view({
            table,
            view
          });
          break;
        }
      default:
        throw (0, _error_utils.spawnError)("Invalid context to run ");
    }
    if (!React.isValidElement(entryElement)) {
      throw (0, _error_utils.spawnError)("The first argument to initializeBlock didn't return a valid React element");
    }
    sdk.__setBatchedUpdatesFn(_reactDom.default.unstable_batchedUpdates);
    var container = document.createElement("div");
    body.appendChild(container);
    _reactDom.default.render(React.createElement(_block_wrapper.default, {
      sdk: sdk
    }, entryElement), container);
  }
  var sdk; // The application-level Sdk instance must be injected dynamically to avoid
  // circular dependencies at the time of module resolution.

  function __injectSdkIntoInitializeBlock(_sdk) {
    sdk = _sdk;
  } // Exclusively for tests

  function __resetHasBeenInitialized() {
    hasBeenInitialized = false;
  }
  return exports;
}