import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import { dew as _css_helpers2Dew } from "../../css_helpers";
import { dew as _tokens2Dew } from "./tokens";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _emotion = _emotion2;
  var _css_helpers = _interopRequireDefault(_css_helpers2Dew());
  var _tokens = _tokens2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var SWITCH_WIDTH = 20;
  var SWITCH_HEIGHT = 12;
  var SWITCH_PADDING = 2;
  var CIRCLE_SIZE = SWITCH_HEIGHT - 2 * SWITCH_PADDING;
  var switchClassName = (0, _emotion.css)({
    width: CIRCLE_SIZE,
    height: CIRCLE_SIZE,
    transition: "0.085s all ease-in",
    borderRadius: _tokens.radii.circle,
    backgroundColor: _tokens.colors.white
  });
  var switchContainerClassName = (0, _emotion.css)({
    flex: "none",
    width: SWITCH_WIDTH,
    height: SWITCH_HEIGHT,
    padding: SWITCH_PADDING,
    transition: "0.085s all ease-in",
    boxSizing: "border-box",
    borderRadius: _tokens.radii.circle,
    backgroundColor: _tokens.colors.darken2
  });
  var switchLabelClassName = (0, _emotion.css)({
    cursor: "inherit",
    userSelect: "none",
    marginLeft: _tokens.space[2],
    color: _tokens.colors.dark,
    flex: "auto"
  });
  var baseStyles = (0, _emotion.css)({
    alignItems: "center",
    borderRadius: _tokens.radii.default,
    boxSizing: "border-box",
    outline: "none",
    fontFamily: _tokens.fontFamilies.default,
    backgroundColor: _tokens.colors.lightGray2,
    "&[aria-disabled=\"true\"]": {
      opacity: _tokens.opacities.quieter
    },
    "&:not([aria-disabled=\"true\"])": {
      cursor: "pointer",
      "&:hover": {
        opacity: _tokens.opacities.quiet
      },
      "&:focus": {
        boxShadow: "inset 0 0 0 2px ".concat(_tokens.colors.darken3)
      }
    },
    ["&[aria-checked=\"true\"] .".concat(switchClassName)]: {
      transform: "translateX(100%)"
    }
  });
  var subcomponentClassNames = {
    switchClassName,
    switchContainerClassName,
    switchLabelClassName: (0, _emotion.cx)(switchLabelClassName, _css_helpers.default.TRUNCATE)
  };
  var switchVariants = {
    default: _objectSpread({
      baseClassName: (0, _emotion.cx)(baseStyles, (0, _emotion.css)({
        ["&[aria-checked=\"true\"] > .".concat(switchContainerClassName)]: {
          backgroundColor: _tokens.colors.greenBright
        }
      }))
    }, subcomponentClassNames),
    danger: _objectSpread({
      baseClassName: (0, _emotion.cx)(baseStyles, (0, _emotion.css)({
        ["&[aria-checked=\"true\"] > .".concat(switchContainerClassName)]: {
          backgroundColor: _tokens.colors.redBright
        }
      }))
    }, subcomponentClassNames)
  };
  var _default = switchVariants;
  exports.default = _default;
  return exports;
}