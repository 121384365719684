import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import { dew as _create_responsive_prop_type2Dew } from "./create_responsive_prop_type";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = createStylePropTypes;
  exports.stylePropType = exports.stylePropValue = void 0;
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _create_responsive_prop_type = _interopRequireDefault(_create_responsive_prop_type2Dew());
  var stylePropValue = _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string]);
  exports.stylePropValue = stylePropValue;
  var stylePropType = (0, _create_responsive_prop_type.default)(stylePropValue);
  /** @internal */

  exports.stylePropType = stylePropType;
  function createStylePropTypes() {
    var propNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var propTypes = {};
    propNames.forEach(propName => {
      propTypes[propName] = stylePropType;
    });
    return propTypes;
  }
  return exports;
}