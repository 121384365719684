import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.iterator";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _esObject4 from "core-js/modules/es.object.to-string";
var _esObject3 = _esObject4;
try {
  if ("default" in _esObject4) _esObject3 = _esObject4.default;
} catch (e) {}
import * as _es4 from "core-js/modules/es.set";
var _es3 = _es4;
try {
  if ("default" in _es4) _es3 = _es4.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _webDomCollections4 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections3 = _webDomCollections4;
try {
  if ("default" in _webDomCollections4) _webDomCollections3 = _webDomCollections4.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _extends3 from "@babel/runtime/helpers/extends";
var _extends = _extends3;
try {
  if ("default" in _extends3) _extends = _extends3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _hoistNonReactStatics3 from "hoist-non-react-statics";
var _hoistNonReactStatics2 = _hoistNonReactStatics3;
try {
  if ("default" in _hoistNonReactStatics3) _hoistNonReactStatics2 = _hoistNonReactStatics3.default;
} catch (e) {}
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esSymbol;
  _esArray;
  _esArray3;
  _esObject;
  _esObject3;
  _es3;
  _webDomCollections;
  _webDomCollections3;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = withStyledSystem;
  exports.splitStyleProps = splitStyleProps;
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _extends2 = _interopRequireDefault(_extends);
  var React = _interopRequireWildcard(_react);
  var _emotion = _emotion2;
  var _hoistNonReactStatics = _interopRequireDefault(_hoistNonReactStatics2);
  var _private_utils = _private_utils2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }

  /**
   * @internal
   * A higher-order component for working with the `useStyledSystem` hook in class-based components.
   * It takes a React component and converts style props into a single className prop.
   *
   * Generate boilerplate for using this hook with our scaffolding tool: https://o80pm.csb.app/
   *
   * Returns The transformed React component.
   *
   * @param Component The React component you want to use with styled system.
   * @param styleParser The style parser, constructed with `compose`.
   * @example
   * ```js
   * import * as React from 'react';
   * import {compose} from '@styled-system/core';
   * import withStyledSystem from './with_styled_system';
   * import {
   *     flexContainerSet,
   *     flexContainerSetPropTypes,
   *     FlexContainerSetProps,
   *     flexItemSet,
   *     FlexItemSetProps,
   *     flexItemSetPropTypes,
   *     margin,
   *     marginPropTypes,
   *     MarginProps,
   * } from './system';
   *
   * interface Props {
   *     className?: string;
   *     onClick: () => void;
   *     children: React.ReactNode;
   * };
   *
   * interface MyComponentStyleProps extends FlexContainerSetProps, FlexItemSetProps, MarginProps {}
   *
   * const styleParser = compose(
   *     flexContainerSet,
   *     flexItemSet,
   *     margin,
   * );
   *
   * const myComponentStylePropTypes = {
   *     ...flexContainerSetPropTypes,
   *     ...flexItemSetPropTypes,
   *     ...marginPropTypes,
   * };
   *
   * class MyComponent extends React.Component<Props, void> {
   *     static staticProp = 'STATIC';
   *     render() {
   *         const {className, onClick, children} = this.props;
   *         return (
   *             <div className={className} onClick={onClick}>
   *                 {children}
   *             </div>
   *         );
   *     }
   * }
   *
   * export default withStyledSystem<Props, MyComponentStyleProps, MyComponent, { staticProp: string }>(
   *     MyComponent,
   *     styleParser,
   *     myComponentStylePropTypes,
   *     {
   *         // Optional default style props.
   *         margin: 3
   *     }
   * );
   * ```
   */
  function withStyledSystem(Component, styleParser, stylePropTypes, defaultStyleProps) {
    var WithStyledSystem = React.forwardRef((props, ref) => {
      var _splitStyleProps = splitStyleProps(props, styleParser.propNames, defaultStyleProps),
        styleProps = _splitStyleProps.styleProps,
        nonStyleProps = _splitStyleProps.nonStyleProps;
      var classNameForStyleProps = (0, _use_styled_system.default)((0, _private_utils.cast)(styleProps), styleParser);
      return React.createElement(Component, (0, _extends2.default)({
        ref: ref
      }, nonStyleProps, {
        className: (0, _emotion.cx)(classNameForStyleProps, nonStyleProps.className)
      }));
    });
    WithStyledSystem.propTypes = _objectSpread({}, Component.propTypes, {}, stylePropTypes);
    var componentName = Component.displayName || Component.name || "Component";
    WithStyledSystem.displayName = "WithStyledSystem(".concat(componentName, ")");
    (0, _hoistNonReactStatics.default)(WithStyledSystem, Component);
    return WithStyledSystem;
  }
  /**
   * @internal
   * A helper method to split props into style props (for use with styled system) and
   * non-style props (to be passed into the wrapped component).
   *
   * Returns A result object with two keys: `styleProps` and `nonStyleProps`, which contain the
   * respective split props.
   *
   * @param props Props to be split into style and non-style props.
   * @param stylePropNames The list of allowed style prop names.
   * @param defaultStyleProps Default values for style props.
   */

  function splitStyleProps(props) {
    var stylePropNames = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var defaultStyleProps = arguments.length > 2 ? arguments[2] : undefined;
    var stylePropNamesSet = new Set(stylePropNames);
    var styleProps = _objectSpread({}, defaultStyleProps) || {};
    var nonStyleProps = {};
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;
    try {
      for (var _iterator = (0, _private_utils.keys)(props)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var propName = _step.value;
        if (stylePropNamesSet.has(propName)) {
          styleProps[propName] = props[propName];
        } else {
          nonStyleProps[propName] = props[propName];
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
    return {
      styleProps,
      nonStyleProps
    };
  }
  return exports;
}