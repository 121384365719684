import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.filter";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.get-own-property-descriptors";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.for-each";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _objectWithoutProperties3 from "@babel/runtime/helpers/objectWithoutProperties";
var _objectWithoutProperties = _objectWithoutProperties3;
try {
  if ("default" in _objectWithoutProperties3) _objectWithoutProperties = _objectWithoutProperties3.default;
} catch (e) {}
import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";
var _defineProperty = _defineProperty3;
try {
  if ("default" in _defineProperty3) _defineProperty = _defineProperty3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
import * as _emotion3 from "emotion";
var _emotion2 = _emotion3;
try {
  if ("default" in _emotion3) _emotion2 = _emotion3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _core3 from "@styled-system/core";
var _core2 = _core3;
try {
  if ("default" in _core3) _core2 = _core3.default;
} catch (e) {}
import { dew as _baymax_utils2Dew } from "./baymax_utils";
import { dew as _use_styled_system2Dew } from "./use_styled_system";
import { dew as _system2Dew } from "./system";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esArray;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.loaderStylePropTypes = void 0;
  var _objectWithoutProperties2 = _interopRequireDefault(_objectWithoutProperties);
  var _defineProperty2 = _interopRequireDefault(_defineProperty);
  var _propTypes = _interopRequireDefault(_propTypes2);
  var _emotion = _emotion2;
  var React = _interopRequireWildcard(_react);
  var _core = _core2;
  var _baymax_utils = _baymax_utils2Dew();
  var _use_styled_system = _interopRequireDefault(_use_styled_system2Dew());
  var _system = _system2Dew();
  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }
    return keys;
  }
  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          (0, _defineProperty2.default)(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }
    return target;
  }
  var ORIGINAL_SIZE = 54;
  /**
   * Style props for the {@link Loader} component. Accepts:
   * * {@link FlexItemSetProps}
   * * {@link MarginProps}
   * * {@link PositionSetProps}
   */

  var styleParser = (0, _core.compose)(_system.flexItemSet, _system.positionSet, _system.margin);
  var loaderStylePropTypes = _objectSpread({}, _system.flexItemSetPropTypes, {}, _system.positionSetPropTypes, {}, _system.marginPropTypes);
  /**
   * Props for the {@link Loader} component. Also accepts:
   * * {@link LoaderStyleProps}
   *
   * @docsPath UI/components/Loader
   * @noInheritDoc
   */

  exports.loaderStylePropTypes = loaderStylePropTypes;

  // Override the default props and then just proxy through to our loader.

  /**
   * A loading spinner component.
   *
   * [[ Story id="loader--example" title="Loader example" ]]
   *
   * @docsPath UI/components/Loader
   * @component
   */
  var Loader = props => {
    var fillColor = props.fillColor,
      scale = props.scale,
      className = props.className,
      style = props.style,
      styleProps = (0, _objectWithoutProperties2.default)(props, ["fillColor", "scale", "className", "style"]);
    var classNameForStyleProps = (0, _use_styled_system.default)(styleProps, styleParser);
    return React.createElement("svg", {
      width: ORIGINAL_SIZE * scale,
      height: ORIGINAL_SIZE * scale,
      viewBox: "0 0 ".concat(ORIGINAL_SIZE, " ").concat(ORIGINAL_SIZE),
      className: (0, _emotion.cx)((0, _baymax_utils.baymax)("animate-spin-scale animate-infinite"), classNameForStyleProps, className),
      style: _objectSpread({
        shapeRendering: "geometricPrecision"
      }, style)
    }, React.createElement("g", null, React.createElement("path", {
      d: "M10.9,48.6c-1.6-1.3-2-3.6-0.7-5.3c1.3-1.6,3.6-2.1,5.3-0.8c0.8,0.5,1.5,1.1,2.4,1.5c7.5,4.1,16.8,2.7,22.8-3.4c1.5-1.5,3.8-1.5,5.3,0c1.4,1.5,1.4,3.9,0,5.3c-8.4,8.5-21.4,10.6-31.8,4.8C13,50.1,11.9,49.3,10.9,48.6z",
      fill: fillColor
    }), React.createElement("path", {
      d: "M53.6,31.4c-0.3,2.1-2.3,3.5-4.4,3.2c-2.1-0.3-3.4-2.3-3.1-4.4c0.2-1.1,0.2-2.2,0.2-3.3c0-8.7-5.7-16.2-13.7-18.5c-2-0.5-3.2-2.7-2.6-4.7s2.6-3.2,4.7-2.6C46,4.4,53.9,14.9,53.9,27C53.9,28.5,53.8,30,53.6,31.4z",
      fill: fillColor
    }), React.createElement("path", {
      d: "M16.7,1.9c1.9-0.8,4.1,0.2,4.8,2.2s-0.2,4.2-2.1,5c-7.2,2.9-12,10-12,18.1c0,1.6,0.2,3.2,0.6,4.7c0.5,2-0.7,4.1-2.7,4.6c-2,0.5-4-0.7-4.5-2.8C0.3,31.5,0,29.3,0,27.1C0,15.8,6.7,5.9,16.7,1.9z",
      fill: fillColor
    })));
  };
  Loader.propTypes = _objectSpread({
    fillColor: _propTypes.default.string.isRequired,
    scale: _propTypes.default.number.isRequired,
    className: _propTypes.default.string,
    style: _propTypes.default.object
  }, loaderStylePropTypes);
  Loader.defaultProps = {
    fillColor: "#888",
    scale: 0.3
  };
  var _default = Loader;
  exports.default = _default;
  return exports;
}