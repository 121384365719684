import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ariaPropTypes = void 0;
  var _propTypes = _interopRequireDefault(_propTypes2);

  /** @module @airtable/blocks/ui/types: Aria props */

  /** */

  /** @internal */
  var ariaPropTypes = {
    "aria-label": _propTypes.default.string,
    "aria-labelledby": _propTypes.default.string,
    "aria-describedby": _propTypes.default.string,
    "aria-controls": _propTypes.default.string,
    "aria-expanded": _propTypes.default.bool,
    "aria-haspopup": _propTypes.default.bool,
    "aria-hidden": _propTypes.default.bool,
    "aria-live": _propTypes.default.oneOf(["off", "assertive", "polite"])
  };
  exports.ariaPropTypes = ariaPropTypes;
  return exports;
}