import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";
var _interopRequireWildcard2 = _interopRequireWildcard3;
try {
  if ("default" in _interopRequireWildcard3) _interopRequireWildcard2 = _interopRequireWildcard3.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireWildcard = _interopRequireWildcard2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.useSdk = exports.SdkContext = void 0;
  var React = _interopRequireWildcard(_react);
  var _error_utils = _error_utils2Dew();
  var SdkContext = React.createContext(null);
  exports.SdkContext = SdkContext;
  var useSdk = () => {
    var sdk = React.useContext(SdkContext);
    (0, _error_utils.invariant)(sdk, "This component can only be used in a block. Make sure to use `initializeBlock` at the root of this component tree.");
    return sdk;
  };
  exports.useSdk = useSdk;
  return exports;
}