import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import * as _es2 from "core-js/modules/es.symbol";
var _es = _es2;
try {
  if ("default" in _es2) _es = _es2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.description";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esArray2 from "core-js/modules/es.array.iterator";
var _esArray = _esArray2;
try {
  if ("default" in _esArray2) _esArray = _esArray2.default;
} catch (e) {}
import * as _esArray4 from "core-js/modules/es.array.map";
var _esArray3 = _esArray4;
try {
  if ("default" in _esArray4) _esArray3 = _esArray4.default;
} catch (e) {}
import * as _esObject2 from "core-js/modules/es.object.to-string";
var _esObject = _esObject2;
try {
  if ("default" in _esObject2) _esObject = _esObject2.default;
} catch (e) {}
import * as _webDomCollections2 from "core-js/modules/web.dom-collections.iterator";
var _webDomCollections = _webDomCollections2;
try {
  if ("default" in _webDomCollections2) _webDomCollections = _webDomCollections2.default;
} catch (e) {}
import * as _react3 from "react";
var _react2 = _react3;
try {
  if ("default" in _react3) _react2 = _react3.default;
} catch (e) {}
import * as _useSubscription3 from "use-subscription";
var _useSubscription2 = _useSubscription3;
try {
  if ("default" in _useSubscription3) _useSubscription2 = _useSubscription3.default;
} catch (e) {}
import { dew as _error_utils2Dew } from "../error_utils";
import { dew as _private_utils2Dew } from "../private_utils";
import { dew as _use_array_identity2Dew } from "./use_array_identity";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  _es;
  _esSymbol;
  _esArray;
  _esArray3;
  _esObject;
  _webDomCollections;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = useWatchable;
  var _react = _react2;
  var _useSubscription = _useSubscription2;
  var _error_utils = _error_utils2Dew();
  var _private_utils = _private_utils2Dew();
  var _use_array_identity = _interopRequireDefault(_use_array_identity2Dew());

  /** @module @airtable/blocks/ui: useWatchable */

  /** */

  /**
   * A React hook for watching data in Airtable models like {@link Table} and {@link Record}. Each
   * model has several watchable keys that can be used with this hook to have your component
   * automatically re-render when data in the models changes. You can also provide an optional
   * callback if you need to do anything other than re-render when the data changes.
   *
   * This is a low-level tool that you should only use when you specifically need it. There are more
   * convenient model-specific hooks available:
   *
   * * For {@link Base}, {@link Table}, {@link View}, or {@link Field}, use {@link useBase}.
   * * For {@link RecordQueryResult} or {@link Record}, use {@link useRecords}, {@link useRecordIds}, or {@link useRecordById}.
   * * For {@link Viewport}, use {@link useViewport}.
   * * For {@link SettingsButton}, use {@link useSettingsButton}.
   *
   * If you're writing a class component and still want to be able to use hooks, try {@link withHooks}.
   *
   * @param models The model or models to watch.
   * @param keys The key or keys to watch. Non-optional, but may be null.
   * @param callback An optional callback to call when any of the watch keys change.
   *
   * @example
   * ```js
   * import {useWatchable} from '@airtable/blocks/ui';
   *
   * function TableName({table}) {
   *     useWatchable(table, 'name');
   *     return <span>The table name is {table.name}</span>;
   * }
   *
   * function ViewNameAndType({view}) {
   *     useWatchable(view, ['name', 'type']);
   *     return <span>The view name is {view.name} and the type is {view.type}</span>;
   * }
   *
   * function RecordValuesAndColorInViewIfExists({record, field, view}) {
   *     useWatchable(record, ['cellValues', view ? `colorInView:${view.id}` : null]);
   *     return <span>
   *         The record has cell value {record.getCellValue(field)} in {field.name}.
   *         {view ? `The record has color ${record.getColorInView(view)} in ${view.name}.` : null}
   *     </span>
   * }
   * ```
   *
   * @example
   * ```js
   * import {useWatchable} from '@airtable/blocks/ui';
   *
   * function ActiveView({cursor}) {
   *     useWatchable(cursor, 'activeViewId', () => {
   *          alert('active view changed!!!')
   *     });
   *
   *     return <span>Active view id: {cursor.activeViewId}</span>;
   * }
   * ```
   * @docsPath UI/hooks/useWatchable
   * @hook
   */
  function useWatchable(models, keys, callback) {
    if (keys === undefined) {
      throw (0, _error_utils.spawnError)("Invalid call to useWatchable: keys cannot be undefined. " + "Pass a key or array of keys corresponding to the model being watched as the second " + "argument.");
    }
    var compactModels = (0, _use_array_identity.default)((0, _private_utils.compact)(Array.isArray(models) ? models : [models]));
    var compactKeys = (0, _use_array_identity.default)((0, _private_utils.compact)(Array.isArray(keys) ? keys : [keys])); // use a ref to the callback so consumers don't have to provide their own memoization to avoid
    // unwatching and rewatching every render

    var callbackRef = (0, _react.useRef)(callback);
    callbackRef.current = callback; // we use a subscription to model.__getWatchableKey() to track changes. This is because
    // __getWatchableKey will return a value that is:
    //   1. identical by === if nothing watchable in the model has changed. That means we won't
    //      trigger unnecessary re-renders if nothing has changed. Without this, every initial
    //      mount will be double-rendered as useSubscription will think the model changed in the
    //      async gap
    //   2. will be !== if anything watchable in the model has changed. Without this, we might not
    //      re-render when information that we care about has changed.
    //   3. is unique to that model. This means that if we change models, we're guaranteed to get
    //      re-rendered.

    var watchSubscription = (0, _react.useMemo)(() => {
      return {
        getCurrentValue: () => compactModels.map(model => model.__getWatchableKey()).join(","),
        subscribe: notifyChange => {
          // sometimes, watching and unwatching a key has side effects - typically, these
          // only happen when watching or unwatching something for the first or last time, as
          // we use ref-counting to avoid unnecessary side effects. When the keys or models
          // for this subscription change, we teardown the old subscription and create a new
          // one. Often though, underlying model-key pairs will be the same - we'll remove
          // the old subscription, but instantly re-add it. In this case, we don't want to
          // trigger any side effects. To work around this, we defer unwatching the previous
          // models and keys until _after_ we've watched the new ones. That way, the ref-
          // count never reaches 0. We don't want the old watches to actually trigger in that
          // time though - so `isDisabled` prevents that.
          var isDisabled = false;
          var onChange = function onChange(model, key) {
            if (isDisabled) {
              return;
            }
            notifyChange();
            if (callbackRef.current) {
              for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
                args[_key - 2] = arguments[_key];
              }
              callbackRef.current(model, key, ...args);
            }
          };
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;
          try {
            for (var _iterator = compactModels[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var _model2 = _step.value;
              _model2.watch(compactKeys, onChange);
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
          return () => {
            isDisabled = true;
            setTimeout(() => {
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;
              try {
                for (var _iterator2 = compactModels[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var _model = _step2.value;
                  _model.unwatch(compactKeys, onChange);
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }
            }, 0);
          };
        }
      };
    }, [compactModels, compactKeys]); // we don't care about the return value - we just want useSubscription to correctly handle
    // re-rendering the component for us

    (0, _useSubscription.useSubscription)(watchSubscription);
  }
  return exports;
}