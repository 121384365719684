import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = _interopRequireDefault3;
try {
  if ("default" in _interopRequireDefault3) _interopRequireDefault2 = _interopRequireDefault3.default;
} catch (e) {}
import { dew as _use_watchable2Dew } from "./use_watchable";
import { dew as _sdk_context2Dew } from "./sdk_context";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _use_watchable = _interopRequireDefault(_use_watchable2Dew());
  var _sdk_context = _sdk_context2Dew();

  /** @module @airtable/blocks/ui: useCursor */

  /** */

  /**
   * A hook for connecting a React component to your base's cursor. This returns a {@link Cursor}
   * instance and will re-render your component whenever the active Table or active View changes.
   * It excludes any change to the selected Records or selected Fields.
   *
   * `useCursor` should meet most of your needs for working with base cursors. If you need more granular
   * control of when your component updates or want to do anything other than re-render, the lower
   * level {@link useWatchable} hook might help.
   *
   * Returns the current cursor.
   *
   * @example
   * ```js
   * import {useBase, useCursor} from '@airtable/blocks/ui';
   *
   * // renders a list of tables and automatically updates
   * function TableList() {
   *      const base = useBase();
   *      const cursor = useCursor();
   *
   *      const table = base.getTableById(cursor.activeTableId);
   *
   *      return <p>The table named "{table.name}" is active!</p>;
   * }
   * ```
   * @docsPath UI/hooks/useCursor
   * @hook
   */
  var useCursor = () => {
    var _useSdk = (0, _sdk_context.useSdk)(),
      cursor = _useSdk.cursor;
    (0, _use_watchable.default)(cursor, ["activeTableId", "activeViewId"]);
    return cursor;
  };
  var _default = useCursor;
  exports.default = _default;
  return exports;
}